/* stylelint-disable scss/selector-nest-combinators -- selectors cannot be nested */
/* stylelint-disable rule-empty-line-before -- unsure why there's a linting error (at const = navIcon) */

import type { Drop } from '@drop-party/sanity';
import BlockContent from '@sanity/block-content-to-react';
import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { IoMdArrowBack, IoMdClose } from 'react-icons/io';
import styled, { css } from 'styled-components';
import type { Swiper as SwiperType } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { horizontalRuleContainer, horizontalRule, textInHorizontalRule } from 'components/styles';
import { getSocialMediaData } from 'utils/socialMedia';

const desktopBreakpoint = '36rem';
const maxWidthDesktop = '34rem';

const navIcon = css`
	transform: scale(1.5);
	transition: transform 0.2s ease-in-out;
	
	&:hover {
		cursor: pointer;
		transform: scale(1.8);
	}
`;

const socialMediaIcon = css`
	${navIcon}
	margin: 0 0.875rem 0.2rem 0.875rem;
`;

const NavigationStyled = styled.nav<{open: boolean}>`
	width: 100vw;
	transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
	opacity: ${({ open }) => (open ? '1' : '0')};
	z-index: 4;
	background-color: #000000;
	color: #ffffff;
	text-align: left;
	position: absolute;
	display: flex;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	transition: opacity 0.8s cubic-bezier(0.85, 0, 0.15, 1), transform 0.8s cubic-bezier(0.85, 0, 0.15, 1);
	height: min-content;
	min-height: 100%;
	overflow-y: auto;

	&.home {
		height: 100%;
	}

	.swiper-wrapper {
		transition-timing-function: cubic-bezier(0.85, 0, 0.15, 1);
		transition-duration: 0.3s;
	}

	.swiper-container {
		/* stylelint-disable-next-line declaration-no-important -- safari */
		overflow: hidden !important;
	}
`;

const HomeContainer = styled.div`
	height: 100%;
	width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const Header = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 2.125rem;
	margin: 1.25rem 1.5rem 2.75rem 1.5rem;
`;

const ControlsWrapper = styled.div`
	margin-top: 0.5rem;
	padding: 0.5rem;
	${navIcon}
`;

const HomeContent = styled.div`
	@media (max-width: ${desktopBreakpoint}) {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
`;

const Close = styled(IoMdClose)`
	/* stylelint-disable-next-line scss/no-global-function-names -- color.invert does not work on svg filter */
	filter: brightness(0) saturate(100%) invert(100%);
`;

const MenuTitle = styled.h1`
	color: #ffffff;
	font-size: 1.25rem;
	line-height: 1.5rem;
	margin: 0 0 0 1rem;
`;

const Selected = styled.div`
	background-color: #ffffff;
	border-radius: 100%;
	margin-left: -0.5rem;
	margin-right: 0.5rem;
	width: 0.438rem;
	height: 0.438rem;
	transition: all 0.2s ease-in-out;
	transform: scale(0.1);
	opacity: 0%;
	box-shadow: 0 0 1px rgba(0, 0, 0, 5%);
`;

const LinkElements = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
	margin-bottom: 1.5rem;

	@media (min-width: ${desktopBreakpoint}) {
		width: 100%;
		display: inline-block;
		margin-bottom: 0;
	}
`;

const LinkContent = styled.a`
	font-size: 1.5rem;
	line-height: 2.25rem;
	font-weight: 900;
	color: #ffffff;
	text-decoration: none;

	/* box shadow fixes a rendering issue on Safari */
	box-shadow: 0 0 1px rgba(0, 0, 0, 5%);
`;

const ArrowRight = styled.object`
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	display: none;

	@media (min-width: ${desktopBreakpoint}) {
		display: initial;
	}
`;

const animatedArrowStyles = 'width: 100%; padding: 1.5rem 0 0 92%; overflow: clip; overflow: hidden; cursor: pointer';

const Underline = styled.div`
	position: absolute;
	height: 1px;
	width: 100%;
	background-color: #505050;
	z-index: -1;
	display: none;

	@media (min-width: ${desktopBreakpoint}) {
		display: block;
	}
`;

const NavigationLink = styled.div`
	position: relative;
	display: inline-block;
	overflow: clip;
	font-size: 0;

	&.fade {
		opacity: 50%;
	}

	&.focused {
		opacity: 100%;
	}

	&:hover {
		cursor: pointer;

		${/* sc-selector */ Selected} {
			margin-left: 0.5rem;
			transform: scale(1);
			opacity: 100%;
		}
	}

	@media (min-width: ${desktopBreakpoint}) {
		font-size: 3rem;
		height: min-content;

		${/* sc-selector */ Selected} {
			display: none;
		}

		${/* sc-selector */ LinkContent} {
			font-size: 3rem;
			line-height: 4.5rem;
		}
	}
`;

const NavigationContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: left;
	margin: 0 1.5rem 1.5rem 1.5rem;

	@media (min-width: ${desktopBreakpoint}) {
		max-width: ${maxWidthDesktop};
		margin: auto;
		height: min-content;
		margin-bottom: 5rem;

		${/* sc-selector */ NavigationLink}::after {
			content: '';
			display: block;
			width: 0;
			height: 1px;
			background: #ffffff;
			transition: width 0.6s cubic-bezier(0.85, 0, 0.15, 1), transform 0.8s cubic-bezier(0.85, 0, 0.15, 1);
		}

		${/* sc-selector */ NavigationLink}:hover::after {
			width: 100%;
		}
	}
`;

const Connect = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	margin: 0 1.5rem 1.5rem 1.5rem;

	&.fade {
		opacity: 50%;
	}

	@media (min-width: ${desktopBreakpoint}) {
		position: static;
		max-width: ${maxWidthDesktop};
		margin: auto;
	}
`;

const JoinPrompt = styled.p`
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 400;
	margin: 0 0 0.75rem 0;
`;

const ButtonToConectLink = styled.a`
	all: unset;
`;

const ButtonToConnect = styled.button`
	border-style: none;
	background-color: #ffffff;
	color: #000000;
	font-weight: 900;
	font-size: 1rem;
	padding: 0.75rem;
	line-height: 1.5rem;
	border-radius: 48px;
	transition: transform 0.2s ease-in-out;
	width: 100%;

	&:hover {
		cursor: pointer;
		transform: scale(1.05);
	}
`;

const HorizontalRuleContainer = styled.div`
	${horizontalRuleContainer}
`;

const HorizontalRule = styled.div`
	${horizontalRule}
	background-color: #ffffff;
`;

const TextInHorizontalRule = styled.p`
	${textInHorizontalRule}
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
`;

const SocialMediaContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
`;

const SocialMediaIcon = styled.a`
	all: unset;
`;

const Footer = styled.footer`
	display: flex;
	flex-wrap: wrap;
	height: min-content;
	justify-content: space-between;
	margin: 0 1.5rem 1.5rem 2rem;
`;

const FooterElement = styled.div`
	color: #a0a0a0;
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 1.25rem;
`;

const FooterLink = styled.a`
	all: unset;
	
	&:hover {
		cursor: pointer;
	}
`;

const BackArrow = styled(IoMdArrowBack)`
	/* stylelint-disable-next-line scss/no-global-function-names -- color.invert does not work on svg filter */
	filter: brightness(0) saturate(100%) invert(100%);
`;

const NavigationSlide = styled.div`
	width: 100%;
	height: 100%;
`;

const NavigationTextContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: left;
	height: 100%;
	max-width: 35rem;
	margin: 0 2rem;

	@media (min-width: ${desktopBreakpoint}) {
		margin: 0 13%;
	}
`;

const TermsConditionsLink = styled.a`
	color: #ffffff;
	text-decoration: none;
	margin-top: 1.5rem;
	font-size: 1.2rem;
	line-height: 2.25rem;
	font-weight: 600;
	width: max-content;
	max-width: 100%;

	&::after {
		content: '';
		display: block;
		width: 0;
		height: 1px;
		background: #ffffff;
		transition: width 0.3s cubic-bezier(0.85, 0, 0.15, 1), transform 0.8s cubic-bezier(0.85, 0, 0.15, 1);
	}

	&:hover::after {
		width: 100%;
	}

	@media (min-width: ${desktopBreakpoint}) {
		font-size: 1.6rem;
	}
`;

interface Props {
	about: Drop['about'];
	socialMedia: Drop['socialMedia'];
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	termsConditions: Drop['termsConditions'];
}

const Navigation: FunctionComponent<Props> = ({
	about,
	socialMedia,
	open,
	setOpen,
	termsConditions,
}) => {
	const [swiper, setSwiper] = useState<SwiperType>();
	const [onHome, setOnHome] = useState<boolean>(true);

	// TODO: use a lookup object for this rather than a ternary
	const [pageTwo, setPageTwo] = useState<string>('');

	const [hovered, setHovered] = useState<{
		about?: boolean;
		terms?: boolean;
	}>({
		about: false,
		terms: false,
	});

	const handleSvgLoaded = (obj: HTMLObjectElement) => {
		const arrow = obj.contentDocument?.documentElement as unknown as SVGElement;
		arrow.setAttribute('style', animatedArrowStyles);
		arrow.addEventListener('click', () => obj.click());
	};

	const { icons, links } = getSocialMediaData(socialMedia, socialMediaIcon);

	const togglePageTwo = (moveToPageTwo: boolean, content?: string): void => {
		if (!swiper) {
			return;
		}
		if (moveToPageTwo) {
			swiper.slideNext();
			swiper.allowTouchMove = true;
			setOnHome(false);
			setPageTwo(content ?? pageTwo);
		} else {
			swiper.allowTouchMove = false;
			swiper.slidePrev();
			setOnHome(true);
			setPageTwo(content ?? pageTwo);
		}
	};

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment -- for rendering only on client side
		<>
			{process.browser && createPortal(
				<NavigationStyled open={open} className={classnames({ home: onHome })}>
					<Swiper
						allowTouchMove={false}
						onSlideChange={() => {
							if (swiper) {
								swiper.allowTouchMove = false;
							}
						}}
						onSwiper={(swiperObj) => setSwiper(swiperObj)}
					>
						<SwiperSlide>
							<HomeContainer>
								<Header>
									<ControlsWrapper onClick={() => setOpen(false)}>
										<Close />
									</ControlsWrapper>
									<MenuTitle>
										Close
									</MenuTitle>
								</Header>
								<HomeContent>
									<NavigationContainer>
										{about && (
											<NavigationLink
												onClick={() => togglePageTwo(true, 'about')}
												onMouseEnter={() => setHovered({ about: true })}
												onMouseLeave={() => setHovered({ about: false })}
												className={classnames({
													focused: hovered.about,
													fade:    hovered.terms,
												})}
											>
												<LinkElements>
													<Selected />
													<LinkContent>
														{about.title}
													</LinkContent>
													<ArrowRight
														type="image/svg+xml"
														data="/animation/right-arrow.svg"
														onLoad={({ target }) => handleSvgLoaded(target as HTMLObjectElement)}
													/>
												</LinkElements>
												<Underline />
											</NavigationLink>
										)}
										{termsConditions && (
											<NavigationLink
												onClick={() => togglePageTwo(true, 'termsConditions')}
												onMouseEnter={() => setHovered({ terms: true })}
												onMouseLeave={() => setHovered({ terms: false })}
												className={classnames({
													focused: hovered.terms,
													fade:    hovered.about,
												})}
											>
												<LinkElements>
													<Selected />
													<LinkContent>
														Terms & Conditions
													</LinkContent>
													<ArrowRight
														type="image/svg+xml"
														data="/animation/right-arrow.svg"
														onLoad={({ target }) => handleSvgLoaded(target as HTMLObjectElement)}
													/>
												</LinkElements>
												<Underline />
											</NavigationLink>
										)}
									</NavigationContainer>
									<Connect className={classnames({ fade: hovered.about ?? hovered.terms })}>
										{about?.buttonTitle?.trim().length
											&& (
												<JoinPrompt>
													{about.buttonTitle}
												</JoinPrompt>
											)}
										{(about?.buttonText?.trim().length && about.buttonHref?.trim().length)
											&& (
												<ButtonToConectLink href={about.buttonHref} target="_blank">
													<ButtonToConnect>
														{about.buttonText}
													</ButtonToConnect>
												</ButtonToConectLink>
											)}
										{socialMedia?.length
											&& (
												<>
													<HorizontalRuleContainer>
														<HorizontalRule />
														<TextInHorizontalRule>
															Join us on
														</TextInHorizontalRule>
														<HorizontalRule />
													</HorizontalRuleContainer>
													<SocialMediaContainer>
														{icons.map((Icon, index) => (
															<SocialMediaIcon key={index} href={links[index]} target="_blank">
																<Icon />
															</SocialMediaIcon>
														))}
													</SocialMediaContainer>
												</>
											)}
									</Connect>
								</HomeContent>
								<Footer>
									<FooterElement>
										©
										{' '}
										{new Date().getFullYear()}
										{' '}
										Drop Party. All rights reserved.
									</FooterElement>
									<FooterElement>
										<FooterLink href="https://drop.party/terms.html" target="_blank">
											Terms of Use
										</FooterLink>
										{' / '}
										<FooterLink href="https://drop.party/privacy.html" target="_blank">
											Privacy Policy
										</FooterLink>
									</FooterElement>
								</Footer>
							</HomeContainer>
						</SwiperSlide>
						<SwiperSlide>
							<NavigationSlide>
								<Header>
									<ControlsWrapper onClick={() => togglePageTwo(false)}>
										<BackArrow />
									</ControlsWrapper>
									<MenuTitle>
										{pageTwo === 'about' ? 'About' : 'Terms & Conditions'}
									</MenuTitle>
								</Header>
								<NavigationTextContent>
									{pageTwo === 'about'
										? <BlockContent blocks={about!.text!} />
										: termsConditions?.map(({ text, href }, index) => (
											<TermsConditionsLink key={index} href={href} target="_blank">
												{text}
											</TermsConditionsLink>
										))}
								</NavigationTextContent>
							</NavigationSlide>
						</SwiperSlide>
					</Swiper>
				</NavigationStyled>
				, document.querySelector('#__next')! || document.querySelector('#root') // eslint-disable-line @typescript-eslint/no-unnecessary-condition -- NEXT does not use #root
			)}
		</>
	);
};

export default Navigation;
