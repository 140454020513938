import type { ImageMeta, ImageMetaWithTheme, SanityKeyed, VideoMeta, VideoMetaWithTheme } from '@drop-party/sanity';
import classnames from 'classnames';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { CSSProperties, FunctionComponent, SyntheticEvent, ComponentProps } from 'react';
import { useEvent } from 'react-use';
import styled, { css, keyframes } from 'styled-components';

import { hide, positionAll0, reset } from 'components/styles';
import { getFileUrl, imageBuilder } from 'vendors/sanity/utils';

const Container = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;

	&.takeover {
		cursor: none;
		user-select: none;
	}
`;

const slide = css`
	${positionAll0}
	position: absolute;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	pointer-events: none;
	user-select: none;
	object-fit: cover;
	z-index: -1;
	display: none;

	&.selected {
		display: block;
		z-index: 0;
	}
`;

const ImageSlide = styled.img`
	${slide}
`;

const VideoSlideInner = styled.video`
	${slide}
`;

const Scrim = styled.div`
	${hide}
	${positionAll0}
	position: absolute;
	pointer-events: none;
	box-shadow: inset 0 40px 30px rgba(0, 0, 0, 20%);
`;

const Indicators = styled.div`
	${hide}
	position: absolute;
	top: 1rem;
	right: 0.875rem;
	left: 0.875rem;
	display: flex;
	flex-direction: row;
	padding: 0;
	margin: 0;
	pointer-events: none;
`;

const growWidth0 = keyframes`
	from {
		width: 0;
		color: '#ffffff';
	}

	to {
		width: 100%;
		color: '#ffffff';
	}
`;

const growWidth1 = keyframes`
	from {
		width: 0;
		color: '#000000';
	}

	to {
		width: 100%;
		color: '#000000';
	}
`;

const Indicator = styled.div<{ indicatorWidth?: number }>`
	position: relative;
	flex-grow: 1;
	height: 2px;
	margin: 0 2px;
	z-index: 1;

	&::before,
	&::after {
		${positionAll0}
		position: absolute;
		display: block;
		height: 100%;
		content: '';
		background-color: ${({ theme: { overlayContentColor } }) => overlayContentColor};
	}

	&::before {
		width: 100%;
		opacity: 40%;
	}

	&::after {
		width: 0;
	}

	&.video {
		&::after {
			width: ${({ indicatorWidth }) => `${(indicatorWidth ?? 0) * 100}%`};
		}
	}

	&.previous {
		&::after {
			width: 100%;
		}
	}

	&.image {
		&.playing0 {
			&::after {
				animation: ${growWidth0} linear 4000ms both;
			}
		}

		&.playing1 {
			&::after {
				animation: ${growWidth1} linear 4000ms both;
			}
		}

		&.paused {
			&::after {
				animation-play-state: paused;
			}
		}
	}
`;

const arrow = css`
	${reset}
	${hide}
	position: absolute;
	top: 0;
	bottom: 0;
	height: auto;
	cursor: pointer;
	user-select: none;
	background-color: transparent;
	background-image: url('/media-story-arrow.svg');
	background-repeat: no-repeat;
	background-size: 1.5rem;
	background-position: right 1rem center;
	mix-blend-mode: difference;
	opacity: 0%;
	transition-property: opacity;
	z-index: 1;

	@media (hover: hover) and (pointer: fine) {
		&:not(.hide) {
			/* stylelint-disable selector-max-type,selector-type-no-unknown -- Styled-Components interpolation */

			${Container}:hover & {
				opacity: 50%;

				&:hover {
					opacity: 100%;
				}
			}
			/* stylelint-enable selector-max-type,selector-type-no-unknown -- Styled-Components interpolation */
		}
	}

	&:focus-visible {
		opacity: 100%;
	}
`;

const PrevArrow = styled.button`
	${arrow}
	left: 0;
	width: 34%;
	transform: rotate(180deg);
`;

const NextArrow = styled.button`
	${arrow}
	right: 0;
	width: 66%;
`;

const VideoPlayButton = styled.div`
	${positionAll0}
	position: absolute;
	z-index: 10000;
	background: no-repeat center / 5rem url('/play-button.svg');
`;

type VideoSlideProps = ComponentProps<typeof VideoSlideInner> & {
	playing?: boolean;
	selected: boolean;
};

const VideoSlide = ({ playing, selected, autoplay, ...props }: VideoSlideProps) => {
	const ref = useRef<HTMLVideoElement | null>(null);

	useEffect(() => {
		try {
			void ref.current![!playing ? 'pause' : 'play']();
		} catch {
			// Can require user interaction, which is where the useEvent will attempt to solve this.
		}
	}, [playing]);

	useEvent('click', () => {
		if (!playing || !ref.current!.paused) {
			return;
		}

		try {
			void ref.current!.play();
		} catch {
			// Can require user interaction, which is where the useEvent will attempt to solve this.
		}
	});

	useEffect(() => {
		if (selected) {
			return;
		}

		try {
			ref.current!.currentTime = 0;
		} catch {
			// Some browsers can cause issues with this https://stackoverflow.com/questions/53039068/aborterror-the-operation-was-aborted-error-when-adjusting-html-5-video-cur
		}
	}, [selected]);

	return (
		<>
			<VideoSlideInner ref={ref} {...props} />
			{(playing && ref.current?.paused && !autoplay) && <VideoPlayButton />}
		</>
	);
};

export interface Props {
	buttons?: boolean;
	className?: string;
	dragThreshold?: number;
	dragTimeout?: number;
	loop?: boolean;
	media: SanityKeyed<ImageMeta | ImageMetaWithTheme | VideoMeta | VideoMetaWithTheme>[];
	onBack?: () => void;
	onChangeSlide: (slide: number) => void;
	onChangeTakeover?: (takeover: boolean) => void;
	onForward?: () => void;
	pause?: boolean;
	play?: boolean;
	preload?: boolean;
	scrim?: boolean;
	slide: number;
	style?: CSSProperties;
	takeover?: boolean;
	imageSize?: {
		heightPx: number;
		widthPx: number;
	};
}

export const MediaStoryPure: FunctionComponent<Props> = ({
	buttons,
	className,
	imageSize,
	loop,
	media,
	onBack,
	onChangeSlide,
	onChangeTakeover,
	onForward,
	pause,
	play,
	preload,
	slide,
	style,
	takeover,
	dragThreshold = 10,
	dragTimeout = 250,
	scrim = true,
}) => {
	const [playingAnimation, setPlayingAnimation] = useState(0);
	const imageRef = useRef<HTMLImageElement | null>(null);
	const selectedMedia = media[slide];

	const [videoPosition, setVideoPosition] = useState<{ [key: string]: number | undefined }>({});
	useEffect(() => setVideoPosition({ [selectedMedia._key]: undefined }), [selectedMedia._key]);

	const [mediaLoaded, setMediaLoaded] = useState<{ [key: string]: boolean | undefined }>({});
	useEffect(() => setMediaLoaded((mediaLoaded) => (
		mediaLoaded[selectedMedia._key] || !imageRef.current?.complete
			? mediaLoaded
			: {
				...mediaLoaded,
				[selectedMedia._key]: true,
			}
	)), [media, selectedMedia._key]);

	const [visibilityState, setVisibilityState] = useState<VisibilityState>();
	useEvent('visibilitychange', () => setVisibilityState(document.visibilityState), process.browser ? document : null);

	const [holdStarted, setHoldStarted] = useState(false);

	const [startX, setStartX] = useState<number>(0);
	const [endX, setEndX] = useState<number>(0);
	const beyondDragThreshold = useMemo(() => Math.abs(startX - endX) > dragThreshold, [dragThreshold, startX, endX]);

	const dragTimeoutRef = useRef<NodeJS.Timeout | undefined>();
	const [dragTimedOut, setDragTimedOut] = useState<boolean>(false);

	const hideIndicatorsForVideo = media.length === 1 && ('autoplay' in media[0] && media[0].autoplay);

	useEffect(() => {
		if (!dragTimedOut) {
			return;
		}

		setDragTimedOut(false);

		if (beyondDragThreshold) {
			return;
		}

		onChangeTakeover?.(true);
	}, [beyondDragThreshold, dragTimedOut, onChangeTakeover]);

	const onHoldStart = useCallback((e: SyntheticEvent<HTMLDivElement, MouseEvent | TouchEvent>): void => {
		const position = (
			e.nativeEvent instanceof MouseEvent
				? e.nativeEvent
				: e.nativeEvent.changedTouches[0]
		).clientX;
		setStartX(position);
		setEndX(position);
		setHoldStarted(true);

		dragTimeoutRef.current = setTimeout((): void => setDragTimedOut(true), dragTimeout);
	}, [dragTimeout]);

	const onHoldMove = useCallback((e: SyntheticEvent<HTMLDivElement, MouseEvent | TouchEvent>): void => {
		if (takeover) {
			e.stopPropagation();

			return;
		}

		if (!holdStarted) {
			return;
		}

		setEndX(
			(
				e.nativeEvent instanceof MouseEvent
					? e.nativeEvent
					: e.nativeEvent.changedTouches[0]
			).clientX
		);
	}, [holdStarted, takeover]);

	const onHoldEnd = useCallback((e: SyntheticEvent<HTMLDivElement, MouseEvent | TouchEvent>): void => {
		setHoldStarted(false);

		if (takeover) {
			e.stopPropagation();
			onChangeTakeover?.(false);
		}

		if (dragTimeoutRef.current) {
			clearTimeout(dragTimeoutRef.current);
		}
	}, [onChangeTakeover, takeover]);

	return (
		<Container
			className={classnames({ takeover }, className)}
			onClickCapture={onHoldEnd}
			onContextMenu={onHoldEnd}
			onMouseDown={onHoldStart}
			onMouseMove={onHoldMove}
			onTouchCancel={onHoldEnd}
			onTouchEnd={onHoldEnd}
			onTouchMove={onHoldMove}
			onTouchStart={onHoldStart}
			style={style}
		>
			{Boolean(media.length) && (
				<>
					{(preload ? media : [media[slide]]).map((currentMedia) => {
						const {
							alt,
							_type,
							_key: key,
						} = currentMedia;
						const selected = currentMedia === selectedMedia;
						const autoplay = 'autoplay' in currentMedia && currentMedia.autoplay;
						const loopVideo = 'loop' in currentMedia && currentMedia.loop;

						const builder = imageBuilder.image(currentMedia);
						const src = _type.startsWith('image')
							? (imageSize !== undefined ? builder.size(imageSize.widthPx, imageSize.heightPx) : builder).url()!
							: getFileUrl(currentMedia);

						return _type.startsWith('image')
							? (
								<ImageSlide
									key={key}
									ref={!selected ? undefined : imageRef}
									alt={alt}
									className={classnames({ selected })}
									src={src}
									onLoad={() => setMediaLoaded((mediaLoaded) => ({
										...mediaLoaded,
										[key]: true,
									}))}
									loading="eager"
								/>
							)
							: (
								<VideoSlide
									disablePictureInPicture
									disableRemotePlayback
									playsInline
									autoplay={autoplay}
									muted={autoplay}
									loop={loopVideo}
									key={key}
									className={classnames({ selected })}
									controls={false}
									height={imageSize?.heightPx}
									playing={play && !takeover && selected && visibilityState !== 'hidden'}
									preload="auto"
									selected={selected}
									src={src}
									width={imageSize?.widthPx}
									onEnded={() => {
										onChangeSlide(
											loop
												? (slide + 1) % media.length
												: Math.min(slide + 1, media.length - 1)
										);

										if (!loop && slide === media.length - 1) {
											onForward?.();
										}
									}}
									onLoadedData={() => setMediaLoaded((mediaLoaded) => ({
										...mediaLoaded,
										[key]: true,
									}))}
									onTimeUpdate={({ currentTarget: { currentTime, duration } }: SyntheticEvent<HTMLVideoElement>): void => setVideoPosition({
										...videoPosition,
										[key]: currentTime / duration,
									})}
								/>
							);
					})}
					{scrim && (
						<Scrim
							className={classnames({
								hide:       !play || takeover,
								hideSlowly: takeover,
							})}
						/>
					)}
				</>
			)}

			<Indicators
				className={classnames({
					hide:       !play || takeover || (media.length === 1 && loop && (media[0]._type.startsWith('image') || hideIndicatorsForVideo)),
					hideSlowly: takeover,
				})}
			>
				{media.map(({ _type, _key: key }, i) => (
					<Indicator
						key={key}
						indicatorWidth={videoPosition[key]}
						className={classnames({
							[`playing${playingAnimation}`]: play && i === slide && visibilityState !== 'hidden',
							image:                          _type.startsWith('image'),
							paused:                         !mediaLoaded[key] || holdStarted || Boolean(pause),
							previous:                       i < slide,
							video:                          !_type.startsWith('image'),
						})}
						onAnimationEnd={() => {
							onChangeSlide(
								loop
									? (slide + 1) % media.length
									: Math.min(slide + 1, media.length - 1)
							);

							if (!loop && slide === media.length - 1) {
								onForward?.();
							}
						}}
					/>
				))}
			</Indicators>

			{(selectedMedia._type.startsWith('image') || videoPosition[selectedMedia._key] !== undefined) && (
				<>
					<PrevArrow
						onContextMenu={(e): void => e.preventDefault()}
						type="button"
						className={classnames({
							hide:       !buttons || takeover,
							hideSlowly: takeover,
						})}
						onClick={(event): void => {
							event.currentTarget.blur(); // When focus is on these buttons, we can't drag to the next slide
							if (!buttons || takeover || beyondDragThreshold) {
								return;
							}

							onChangeSlide(Math.max(0, slide - 1));
							setPlayingAnimation((playingAnimation) => (playingAnimation + 1) % 2);

							if (slide === 0) {
								onBack?.();
							}
						}}
					/>

					<NextArrow
						onContextMenu={(e): void => e.preventDefault()}
						type="button"
						className={classnames({
							hide:       !buttons || takeover,
							hideSlowly: takeover,
						})}
						onClick={(event): void => {
							event.currentTarget.blur(); // When focus is on these buttons, we can't drag to the next slide
							if (!buttons || takeover || beyondDragThreshold) {
								return;
							}

							onChangeSlide(
								loop && !onForward
									? (slide + 1) % media.length
									: Math.min(slide + 1, media.length - 1)
							);

							if (slide === media.length - 1) {
								onForward?.();
							}
						}}
					/>
				</>
			)}
		</Container>
	);
};

const MediaStory = memo(MediaStoryPure);

export default MediaStory;
