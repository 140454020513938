import React, { memo } from 'react';
import type { FunctionComponent, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import type { Order } from 'models/orders';
import { formatCurrency } from 'utils';
import { useOrderSummaryData } from 'utils/hooks';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	margin-bottom: 1rem;
	border: 1px solid ${({ theme: { dividerColor } }) => dividerColor};
	border-radius: 4px;

	> * { /* stylelint-disable-line selector-max-universal -- Prevent Safari squishing elements */
		flex-shrink: 0;
		position: relative;
		display: flex;
		padding: 1rem;
		flex-direction: column;
		align-items: stretch;
	}
`;

const cost = css`
	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: space-between;
	margin-bottom: 0.5rem;

	&:last-child {
		margin-bottom: 0;
	}
`;

const Cost = styled.div`
	${cost}
`;

const Discount = styled.div`
	${cost}
	font-weight: 700;
`;

const Total = styled.div`
	${cost}
	font-weight: 700;
`;

export interface Props {
	children?: ReactNode;
	className?: string;
	order: Pick<Order, 'transactions'>;
}

export const OrderSummaryPure: FunctionComponent<Props> = ({
	children,
	className,
	order: { transactions },
}) => {
	const {
		duty,
		shipping,
		subTotal,
		tax,
		total,
		discounts = [],
	} = useOrderSummaryData(transactions);

	return (
		<Container className={className}>
			<div>
				<Cost>
					<span>
						Subtotal
					</span>
					<span>
						{formatCurrency(subTotal / 100)}
					</span>
				</Cost>
				{discounts.map(({ amount, code }) => (
					<Discount key={`${code}---${amount}`}>
						<span>
							{code}
						</span>
						<span>
							-
							{formatCurrency(amount / 100)}
						</span>
					</Discount>
				))}
				<Cost>
					<span>
						Shipping
					</span>
					<span>
						{formatCurrency(shipping / 100)}
					</span>
				</Cost>
				<Cost>
					<span>
						Tax
					</span>
					<span>
						{formatCurrency(tax / 100)}
					</span>
				</Cost>
				{duty !== undefined && (
					<Cost>
						<span>
							Duties
						</span>
						<span>
							{formatCurrency(duty / 100)}
						</span>
					</Cost>
				)}
				<Total>
					<span>
						Total
					</span>
					<span>
						{formatCurrency(total / 100)}
					</span>
				</Total>
			</div>
			{children}
		</Container>
	);
};

const OrderSummary = memo(OrderSummaryPure);

export default OrderSummary;
