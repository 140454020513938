import type { FunctionComponent } from 'react';
import React from 'react';
import Marquee from 'react-fast-marquee';
import styled from 'styled-components';

const MarqueeBackground = styled.div`
	width: 100%;
	height: 1.5rem;
	background-color: #000000;
	font-weight: 900;
	font-size: 0.7rem;
	display: flex;
	align-items: center;
`;

const MarqueeText = styled.h1`
	color: #ffffff;
	font-size: 0.7rem;
	margin: 0;
`;

const safetyDuplicates = 30;

interface Props {
	text: string;
}

const InfiniteMarquee: FunctionComponent<Props> = ({ text }) => {
	const inputArr = new Array(safetyDuplicates).fill(text);

	return (
		<MarqueeBackground>
			<Marquee
				gradient={false}
			>
				{inputArr.map((textDuplicate, index) => (
					<MarqueeText key={index}>
						{textDuplicate}
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					</MarqueeText>
				))}
			</Marquee>
		</MarqueeBackground>
	);
};

export default InfiniteMarquee;
