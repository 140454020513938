import classnames from 'classnames';
import type { Decorator, SubmissionErrors } from 'final-form';
import createCalculateDecorator from 'final-form-calculate';
import createFocusDecorator from 'final-form-focus';
import { find, fromPairs, isString } from 'lodash/fp';
import postalCodes from 'postal-codes-js';
import React, { memo, useCallback, useContext, useMemo } from 'react';
import type { FunctionComponent, ReactNode } from 'react';
import type { FieldRenderProps } from 'react-final-form';
import { Form } from 'react-final-form';
import { Field } from 'react-final-form-html5-validation';
import { useAsync } from 'react-use';
import styled, { ThemeContext, css } from 'styled-components';

import { button } from 'components/Buttons';
import type { CardTypeConfig } from 'components/Cards';
import { back, cardInner, content, header, headerContext, headerText, sectionHeader } from 'components/Cards/styles';
import { input, label, select, withLabel, withLabelContainer } from 'components/Fields';
import type { CreateOrderBody } from 'pages/api/v1/drops/[drop]/orders';
import type { CreatePotentialOrderResponse } from 'pages/api/v1/drops/[drop]/potential-orders';
import type { ShippingAddress } from 'utils';
import { contrast } from 'utils';
import { reportValidity, reportValiditySubscription } from 'utils/forms';
import { useSentry } from 'vendors/sentry/hooks';

const Container = styled.form`
	${cardInner}

	&.submitting {
		cursor: wait;
	}
`;

const Header = styled.div`
	${header}
`;

const HeaderText = styled.span`
	${headerText}
`;

const HeaderIndex = styled.span`
	${headerContext}
`;

const BackButton = styled.button`
	${back}
`;

const Content = styled.fieldset`
	${content}
`;

const SectionHeader = styled.div`
	${sectionHeader}
`;

const FieldContainer = styled.div`
	${withLabelContainer}
`;

const Label = styled.span`
	${label}
`;

const element = css`
	width: 100%;
	margin-bottom: 1rem;

	/* stylelint-disable declaration-block-semicolon-newline-after,rule-empty-line-before -- Styled-Components interpolation */
	${Container}.submitting &:disabled {
		cursor: wait;
	}
	/* stylelint-enable declaration-block-semicolon-newline-after,rule-empty-line-before */
`;

const field = css`
	${withLabel}
	${element}

	&:focus-within {
		width: calc(100% + 2px);
		margin-bottom: calc(1rem - 1px);
	}
`;

const Input = styled.input`
	${input}
	${field}
`;

const Select = styled.select`
	${select}
	${field}
`;

const SideBySide = styled.div`
	display: flex;
	flex-direction: row;
	margin-right: -1rem;

	${FieldContainer} {
		flex-grow: 1;
		width: calc(50% + 1px);
		margin-right: 1rem;
	}
`;

const Spacer = styled.div`
	flex-grow: 1;
`;

const SubmitError = styled.div`
	margin-bottom: 1rem;
	font-weight: 700;
	color: #ff0000;
`;

const SubmitButton = styled.button`
	${button}
	${element}
	margin-bottom: 0;
`;

const disabledDrawRegions: { [country: string]: undefined | { [region: string]: true | undefined } } = {
	US: {
		AS: true,
		DC: true,
		FM: true,
		GU: true,
		MH: true,
		MP: true,
		PW: true,
		PR: true,
		RI: true,
		VI: true,
		AA: true,
		AE: true,
		AP: true,
	},
};

export const cardTypeConfig: CardTypeConfig = { card: { hidden: true } };

interface Values extends Pick<CreateOrderBody, 'email'> {
	shippingAddress: ShippingAddress;
}

export interface Props {
	children?: ReactNode;
	countries?: string[];
	cta?: string;
	onBack?: () => void;
	onForward?: () => void;
	onSubmit: (values: Values) => (SubmissionErrors | Promise<SubmissionErrors | undefined> | undefined);
}

export const ShippingPure: FunctionComponent<Props> = ({
	children,
	onBack,
	onForward,
	onSubmit,
	countries: countryCodes,
	cta = 'Continue to Payment',
}) => {
	const { surfaceBackground } = useContext(ThemeContext);

	const initialCountryCode = useMemo(() => (!countryCodes || countryCodes.includes('US') ? 'US' : countryCodes[0]), [countryCodes]);

	const { value: countryRegionData } = useAsync(async () => (await import('country-region-data')).default, []);

	const allCountries = useMemo(() => countryRegionData?.map(({ countryName: label, countryShortCode: value }) => ({
		label,
		value,
	})), [countryRegionData]);

	const countries = useMemo(() => (
		!countryCodes
			? allCountries
			: allCountries?.filter(({ value }) => countryCodes.includes(value))
	), [allCountries, countryCodes]);

	const regionsByCountry: { [countryCode: string]: { label: string; value: string }[] | undefined } | undefined = useMemo(() => countryRegionData && fromPairs(countryRegionData.map(({ countryShortCode, regions }) => [
		countryShortCode,
		regions.map(({ name: label, shortCode: value }) => ({
			label,
			value,
		})),
	])), [countryRegionData]);

	const decorators = useMemo((): Decorator<Values>[] | undefined => regionsByCountry && [
		createFocusDecorator<Values>(),
		createCalculateDecorator({
			field:   'shippingAddress.country',
			updates: {
				'shippingAddress.region': (
					country?: string,
					values?: Partial<Values>
				): string | undefined => (
					!country
						? undefined
						: (find(({ value }) => value === values?.shippingAddress?.region, regionsByCountry[country]) ?? regionsByCountry[country]?.[0] ?? {}).value
				),
			},
		}) as Decorator<Values>,
	], [regionsByCountry]);

	return (
		<Form<Values>
			decorators={decorators}
			onSubmit={onSubmit}
			subscription={{
				dirtySinceLastSubmit: true,
				hasValidationErrors:  true,
				submitError:          true,
				submitFailed:         true,
				submitSucceeded:      true,
				submitting:           true,
				values:               true,
			}}
			render={({
				dirtySinceLastSubmit,
				handleSubmit,
				hasValidationErrors,
				submitError,
				submitFailed,
				submitSucceeded,
				submitting,
				values: { shippingAddress: { country } = { country: undefined } },
			}): ReactNode => (
				<Container
					className={classnames({ submitting })}
					onSubmit={handleSubmit}
				>
					<Header>
						{onBack && (
							<BackButton tabIndex={-1} type="button" onClick={() => onBack()}>
								<img alt="Back" src={`/arrow-left-${contrast(surfaceBackground)}.svg`} />
							</BackButton>
						)}
						<HeaderText>Shipping</HeaderText>
						<HeaderIndex>1 of 2</HeaderIndex>
					</Header>

					<Content disabled={submitting}>
						<SectionHeader>Contact Information</SectionHeader>

						<Field<string>
							required
							name="shippingAddress.recipient"
							subscription={{
								...reportValiditySubscription,
								touched: true,
								value:   true,
							}}
							render={({
								meta,
								input: { onFocus, ...input },
								meta: { touched },
								...props
							}: FieldRenderProps<string>): ReactNode => (
								<FieldContainer>
									<Input
										{...input}
										{...props}
										autoComplete="shipping name"
										className={classnames({ touched })}
										onFocus={reportValidity(onFocus, meta)}
										placeholder=" "
									/>
									<Label>Full Name</Label>
								</FieldContainer>
							)}
						/>

						<Field<string>
							required
							name="email"
							type="email"
							subscription={{
								...reportValiditySubscription,
								touched: true,
								value:   true,
							}}
							render={({
								meta,
								input: { onFocus, ...input },
								meta: { touched },
								...props
							}: FieldRenderProps<string>): ReactNode => (
								<FieldContainer>
									<Input
										{...input}
										{...props}
										autoComplete="shipping email"
										className={classnames({ touched })}
										onFocus={reportValidity(onFocus, meta)}
										placeholder=" "
									/>
									<Label>Email</Label>
								</FieldContainer>
							)}
						/>

						<SectionHeader>Shipping Address</SectionHeader>

						<Field<string>
							required
							initialValue={initialCountryCode}
							name="shippingAddress.country"
							subscription={{
								...reportValiditySubscription,
								touched: true,
								value:   true,
							}}
							render={({
								meta,
								input: { onFocus, ...input },
								meta: { touched },
								...props
							}: FieldRenderProps<string>): ReactNode => (
								!countries || countries.length < 2
									? null
									: (
										<FieldContainer>
											<Select
												{...input}
												{...props}
												autoComplete="shipping country"
												className={classnames({ touched })}
												onFocus={reportValidity(onFocus, meta)}
											>
												<option disabled value="" label="Country">Country</option>
												{countries.map(({ label, value, ...option }) => (
													<option
														key={value}
														label={label}
														value={value}
														{...option}
													>
														{label}
													</option>
												))}
											</Select>
											<Label>Country</Label>
										</FieldContainer>
									)
							)}
						/>

						<Field<string>
							required
							name="shippingAddress.addressLine[0]"
							subscription={{
								...reportValiditySubscription,
								touched: true,
								value:   true,
							}}
							render={({
								meta,
								input: { onFocus, ...input },
								meta: { touched },
								...props
							}: FieldRenderProps<string>): ReactNode => (
								<FieldContainer>
									<Input
										{...input}
										{...props}
										autoComplete="shipping address-line1"
										className={classnames({ touched })}
										onFocus={reportValidity(onFocus, meta)}
										placeholder=" "
									/>
									<Label>Address</Label>
								</FieldContainer>
							)}
						/>

						<SideBySide>
							<Field<string>
								name="shippingAddress.addressLine[1]"
								subscription={{
									...reportValiditySubscription,
									touched: true,
									value:   true,
								}}
								render={({
									meta,
									input: { onFocus, value, ...input },
									meta: { touched },
									...props
								}: FieldRenderProps<string>): ReactNode => (
									<FieldContainer>
										<Input
											{...input}
											{...props}
											autoComplete="shipping address-line2"
											className={classnames({ touched })}
											onFocus={reportValidity(onFocus, meta)}
											placeholder=" "
											value={value}
										/>
										<Label>Apt/Suite</Label>
									</FieldContainer>
								)}
							/>

							<Field<string>
								required
								name="shippingAddress.postalCode"
								subscription={{
									...reportValiditySubscription,
									touched: true,
									value:   true,
								}}
								validate={(value, values) => {
									if (!value) {
										return undefined;
									}

									const validation = postalCodes.validate((values as Partial<Values>).shippingAddress?.country ?? '', value);

									return isString(validation) ? validation : undefined;
								}}
								render={({
									meta,
									input: { onFocus, ...input },
									meta: { touched },
									...props
								}: FieldRenderProps<string>): ReactNode => (
									<FieldContainer>
										<Input
											{...input}
											{...props}
											autoComplete="shipping postal-code"
											className={classnames({ touched })}
											onFocus={reportValidity(onFocus, meta)}
											placeholder=" "
										/>
										<Label>Postal Code</Label>
									</FieldContainer>
								)}
							/>
						</SideBySide>

						<SideBySide>
							<Field<string>
								required
								name="shippingAddress.city"
								subscription={{
									...reportValiditySubscription,
									touched: true,
									value:   true,
								}}
								render={({
									meta,
									input: { onFocus, ...input },
									meta: { touched },
									...props
								}: FieldRenderProps<string>): ReactNode => (
									<FieldContainer>
										<Input
											{...input}
											{...props}
											autoComplete="shipping address-level2"
											className={classnames({ touched })}
											onFocus={reportValidity(onFocus, meta)}
											placeholder=" "
										/>
										<Label>City</Label>
									</FieldContainer>
								)}
							/>

							<Field<string>
								required
								name="shippingAddress.region"
								subscription={{
									...reportValiditySubscription,
									touched: true,
									value:   true,
								}}
								render={({
									meta,
									input: { onFocus, ...input },
									meta: { touched },
									...props
								}: FieldRenderProps<string>): ReactNode => (
									<FieldContainer>
										<Select
											{...input}
											{...props}
											autoComplete="shipping address-level1"
											className={classnames({ touched })}
											onFocus={reportValidity(onFocus, meta)}
										>
											<option disabled value="" label="State">State</option>
											{((!country ? undefined : regionsByCountry?.[country]) ?? []).map(({ label, value, ...option }) => (
												<option
													key={value}
													disabled={disabledDrawRegions[country!]?.[value] ?? false}
													label={!disabledDrawRegions[country!]?.[value] ? label : `${label} - Draws are not available`}
													value={value}
													{...option}
												>
													{label}
												</option>
											))}
										</Select>
										<Label>State</Label>
									</FieldContainer>
								)}
							/>
						</SideBySide>

						{children}

						<Spacer />

						{!submitting && submitError && (
							<SubmitError>
								{submitError}
							</SubmitError>
						)}

						<SubmitButton
							className={classnames({ submitting })}
							disabled={submitting || (hasValidationErrors && (!onForward || dirtySinceLastSubmit || (!submitSucceeded && !submitFailed)))}
							type="submit"
							onClick={(e): void => {
								if (!onForward || dirtySinceLastSubmit || (!submitSucceeded && !submitFailed)) {
									return;
								}

								e.preventDefault();
								onForward();
							}}
						>
							{cta}
						</SubmitButton>
					</Content>
				</Container>
			)}
		/>
	);
};

const Shipping = memo(ShippingPure);

export interface ConnectedProps extends Omit<Props, 'onSubmit'> {
	onSubmit: (values: Values) => Promise<CreatePotentialOrderResponse>;
	onSubmitSuccess: (result: Values & CreatePotentialOrderResponse) => void;
	onSubmitting: (values: Values) => void;
}

export const ConnectedShipping: FunctionComponent<ConnectedProps> = ({
	onSubmitSuccess,
	onSubmitting,
	onSubmit: onSubmitRaw,
	...props
}) => {
	const Sentry = useSentry();

	const onSubmit = useCallback(async (values) => {
		onSubmitting(values);

		let results;

		try {
			results = await onSubmitRaw(values);
		} catch (err) {
			Sentry.captureException(err, { level: Sentry.Severity.Warning });

			return !(err instanceof Error)
				? { shippingAddress: { addressLine: [err as string] } }
				: err.message.includes('to_zip')
					? { shippingAddress: { postalCode: err.message.replaceAll('to_', '') } }
					: err.message.startsWith('The address as submitted could not be found.')
						? { shippingAddress: { addressLine: [err.message] } }
						: { shippingAddress: { addressLine: [err.message] } };
		}

		onSubmitSuccess({
			...values,
			...results,
		});
	}, [
		Sentry,
		onSubmitRaw,
		onSubmitSuccess,
		onSubmitting,
	]);

	return (
		<Shipping
			{...props}
			onSubmit={onSubmit}
		/>
	);
};

export default Shipping;
