import React, { useEffect } from 'react';
import type { ReactElement, ReactNode } from 'react';
import { Field } from 'react-final-form';
import type { FieldProps, FieldRenderProps } from 'react-final-form';

const ValueInner = <T extends any>({ onChange, value }: FieldRenderProps<T>['input']): null => {
	useEffect(() => onChange(value), [onChange, value]);

	return null;
};

const Value = <T extends any>({ value, ...props }: FieldProps<T, FieldRenderProps<T>>): ReactElement => (
	<Field<T>
		{...props}
		subscription={{ value: true }}
		render={({ input }): ReactNode => <ValueInner<T> {...input} value={value as T} />}
	/>
);

export default Value;
