import type { FunctionComponent } from 'react';
import React from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';

import { button } from 'components/Buttons';

const instructionSvg = css`
	width: 5rem;
	height: 5rem;
`;

const instructionsBase = css`
	width: max-content;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
`;

const Container = styled.div`
	background-color: rgba(0, 0, 0, 80%);
	display: relative;
	position: absolute;
	pointer-events: none;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 5;
`;

const InstructionsText = styled.h1`
	color: #ffffff;
	font-size: 1rem;
	margin: 0;
	padding: 0;
`;

const SwipeContainer = styled.div`
	${instructionsBase};
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const Swipe = styled.object`
	${instructionSvg}
`;

const ClickContainer = styled.div`
	${instructionsBase};
	align-items: flex-end;
	position: absolute;
	top: 50%;
	right: 10%;
	transform: translateY(-50%);
`;

const Click = styled.object`
	${instructionSvg}
	margin-right: 1rem;
`;

const SkipInstructionWrapper = styled.div`
	width: 100%;
	padding: 1rem;
	position: absolute;
	bottom: 0;
`;

const SkipInstruction = styled.button`
	${button}
	z-index: 6;
	pointer-events: initial;
	width: 100%;
`;

export const nInstructions = 2;

const Instructions: FunctionComponent<{multiCard?: boolean; instruction: number; fullscreen: boolean}> = ({
	 instruction,
	 multiCard,
	 fullscreen,
}) => (
	// eslint-disable-next-line react/jsx-no-useless-fragment -- for rendering only on client side
	<>
		{(process.browser && (!multiCard || fullscreen)) && createPortal(
			<Container>
				{instruction === 0 && (
					<SwipeContainer>
						<Swipe
							type="image/svg+xml"
							data="/animation/instructions-swipe.svg"
						/>
						<InstructionsText>
							Swipe to easily navigate
							<br />
							between products
						</InstructionsText>
					</SwipeContainer>
				)}
				{instruction === 1 && (
					<ClickContainer>
						<Click
							type="image/svg+xml"
							data="/animation/instructions-click.svg"
						/>
						<InstructionsText>
							Tap left or right edge to
							<br />
							view more in a series
						</InstructionsText>
					</ClickContainer>
				)}
				<SkipInstructionWrapper>
					<SkipInstruction>
						GOT IT
					</SkipInstruction>
				</SkipInstructionWrapper>
			</Container>
			, document.querySelector('#__next')! || document.querySelector('#root') // eslint-disable-line @typescript-eslint/no-unnecessary-condition -- NEXT does not use #root
		)}
	</>
);

export default Instructions;
