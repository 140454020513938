import type { Blank, Product } from '@drop-party/sanity';
import type { CreatePaymentMethodCardData, PaymentRequestPaymentMethodEvent, PaymentRequestShippingAddressEvent, PaymentRequestUpdateOptions, Stripe } from '@stripe/stripe-js';
import { compact, find, flow, join, keyBy, sumBy } from 'lodash/fp';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAsync } from 'react-use';
import HttpError from 'standard-http-error';

import type { LineItem, PotentialOrder } from 'models/orders';
import type { CreateOrderBody } from 'pages/api/v1/drops/[drop]/orders';
import type { CreatePotentialOrderBody, CreatePotentialOrderResponse } from 'pages/api/v1/drops/[drop]/potential-orders';
import type { PartialShippingAddress, ShippingAddress } from 'utils';
import { useSentry } from 'vendors/sentry/hooks';

type ProductGetDisplayItems = Pick<Product, 'amount' | 'label'> & { blank?: Pick<Blank, 'variations'> };

const getDisplayItems = (
	productById: { [id: string]: ProductGetDisplayItems },
	lineItemsRaw: LineItem[],
	potentialOrder?: PotentialOrder
): PaymentRequestUpdateOptions => {
	const transaction = potentialOrder?.transactions.data[potentialOrder.transactions.ids[0]];
	const lineItems = transaction?.lineItems ?? lineItemsRaw;
	const subTotal = transaction?.subTotal ?? sumBy(({ quantity, sku: { product } }) => quantity * productById[product].amount, lineItems);

	return {
		displayItems: [
			...lineItems.map((lineItem) => {
				const {
					quantity,
					sku: {
						product,
						variations: skuVariations,
					},
				} = lineItem;

				const {
					amount,
					label,
					blank: { variations = [] } = {},
				} = productById[product];

				return {
					amount: quantity * amount,
					label:  flow(
						compact,
						join(' ')
					)([
						`${quantity}x`,
						label,
						variations
							.map((variation): string => {
								if (!('groups' in variation)) {
									return find(
										{ skuName: { current: skuVariations[variation.skuName.current] } },
										variation.variants
									)!.label;
								}

								const splitVariantName = skuVariations[variation.skuName.current].split('-', 2);

								return find(
									{ skuName: { current: splitVariantName[1] } },
									find(
										{ skuName: { current: splitVariantName[0] } },
										variation.groups
									)!.variants
								)!.label;
							})
							.join(', '),
					]),
				};
			}),
			{
				amount: subTotal,
				label:  'Subtotal',
			},
			{
				amount:  transaction?.shipping ?? 0,
				label:   'Shipping',
				pending: !transaction,
			},
			{
				amount:  transaction?.tax ?? 0,
				label:   'Tax',
				pending: !transaction,
			},
			...transaction?.duty === undefined
				? []
				: [
					{
						amount: transaction.duty,
						label:  'Duty',
					},
				],
			...(transaction?.discounts ?? []).map(({ amount, code: label }) => ({
				label,
				amount: -amount,
			})),
		],
		total: {
			amount:  transaction?.total ?? subTotal,
			label:   'Total',
			pending: !transaction,
		},
	};
};

export const usePaymentRequest = ({
	lineItems,
	onPay,
	onShippingAddress,
	products,
	stripe,
}: {
	lineItems: LineItem[];
	onPay: (args: Pick<CreateOrderBody, 'email' | 'potentialOrder' | 'shippingAddress' | 'signature'> & { paymentMethod: CreatePaymentMethodCardData | string }) => Promise<any> | any;
	onShippingAddress: (args: Pick<CreatePotentialOrderBody, 'shippingAddress'>) => Promise<CreatePotentialOrderResponse>;
	products: (Pick<Product, '_id'> & ProductGetDisplayItems)[];
	stripe: Stripe | null;
}): {
		onApplePay?: () => void;
		onGooglePay?: () => void;
	} => {
	const productById = useMemo(() => keyBy('_id', products), [products]);

	const paymentRequest = useMemo(() => stripe?.paymentRequest({
		country:           'US',
		currency:          'usd',
		displayItems:      [],
		requestPayerEmail: true,
		requestShipping:   true,
		shippingOptions:   [],
		total:             {
			amount:  0,
			label:   'Total',
			pending: true,
		},
	}), [stripe]);

	const onPaymentRequest = useCallback(() => {
		if (!stripe || !paymentRequest) {
			throw new HttpError(HttpError.INTERNAL_SERVER_ERROR, 'Stripe Misconfigured');
		}

		paymentRequest.update({
			...getDisplayItems(productById, lineItems),
			currency: 'usd',
		});
		paymentRequest.show();
	}, [
		lineItems,
		paymentRequest,
		productById,
		stripe,
	]);

	const Sentry = useSentry();

	const [potentialOrder, setPotentialOrder] = useState<PotentialOrder>();
	const [signature, setSignature] = useState<string>();

	useEffect(() => {
		// Can't use useEvent because of it's issues with returning a promise and isn't picking the right type override 😢
		const handler = async ({ shippingAddress, updateWith }: PaymentRequestShippingAddressEvent): Promise<void> => {
			try {
				setPotentialOrder(undefined);
				setSignature(undefined);

				let potentialOrder;
				let signature;

				try {
					const result = await onShippingAddress({ shippingAddress: shippingAddress as PartialShippingAddress }); // Even though paymentRequest strips details from this, it puts empty strings everywhere which technically fits what we want
					potentialOrder = result.potentialOrder;
					signature = result.signature;
				} catch (err) {
					// TODO How should we show potential order errors in paymentRequests?
					Sentry.captureException(err, { level: Sentry.Severity.Warning });

					updateWith({ status: 'invalid_shipping_address' });

					return;
				}

				setPotentialOrder(potentialOrder);
				setSignature(signature);

				updateWith({
					...getDisplayItems(productById, lineItems, potentialOrder),
					status:          'success',
					shippingOptions: [
						{
							amount: potentialOrder.transactions.data[potentialOrder.transactions.ids[0]].shipping,
							detail: 'Shipping',
							id:     'shipping',
							label:  'Shipping',
						},
					],
				});
			} catch (err) {
				Sentry.captureException(err);
				updateWith({ status: 'fail' });
			}
		};

		paymentRequest?.on('shippingaddresschange', handler);

		return () => {
			paymentRequest?.off('shippingaddresschange', handler);
		};
	}, [
		Sentry,
		lineItems,
		onShippingAddress,
		paymentRequest,
		productById,
	]);

	useEffect(() => {
		// Can't use useEvent because of it's issues with returning a promise and isn't picking the right type override 😢
		const handler = async ({
			complete,
			shippingAddress,
			payerEmail: email,
			paymentMethod: { id: paymentMethod },
		}: PaymentRequestPaymentMethodEvent): Promise<void> => {
			try {
				if (!email || !potentialOrder || !signature) {
					Sentry.captureException(new HttpError(HttpError.INTERNAL_SERVER_ERROR, 'Missing email/potentialOrder/signature', {
						email,
						potentialOrder,
						signature,
					}));

					complete('fail');

					return;
				}

				// TODO How should we show payment errors in paymentRequests?
				await onPay({
					email,
					paymentMethod,
					potentialOrder,
					signature,
					shippingAddress: shippingAddress as ShippingAddress,
				});

				complete('success');
			} catch (err) {
				Sentry.captureException(err);
				complete('fail');
			}
		};

		paymentRequest?.on('paymentmethod', handler);

		return () => {
			paymentRequest?.off('paymentmethod', handler);
		};
	}, [
		Sentry,
		onPay,
		paymentRequest,
		potentialOrder,
		signature,
	]);

	const { value: canMakePayment } = useAsync(async () => paymentRequest?.canMakePayment(), [paymentRequest]);

	return !canMakePayment
		? {}
		: 'ApplePaySession' in global
			? { onApplePay: onPaymentRequest }
			: { onGooglePay: onPaymentRequest };
};
