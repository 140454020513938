import React, { memo, useCallback, useEffect, useState } from 'react';
import type { ComponentProps, FunctionComponent, ReactNode } from 'react';
import { useEvent } from 'react-use';
import { createGlobalStyle } from 'styled-components';
import type { Swiper as SwiperType } from 'swiper';
import { Swiper } from 'swiper/react';

import type { Props as CardsProps } from 'components/Cards';

export interface SwiperSlideRenderProps {
	isActive: boolean;
	isDuplicate: boolean;
	isNext: boolean;
	isPrev: boolean;
	isVisible: boolean;
}

const SwiperGlobalStyles = createGlobalStyle`
	.swiper-slide {
		/* TODO Slides that are added become blurry, this fixes it for some reason. https://github.com/nolimits4web/swiper/issues/4166 */
		backface-visibility: hidden;
	}
	
	.swiper-container {
		overflow: visible;
	}
`;

export interface Props extends Omit<ComponentProps<typeof Swiper>, 'children' | 'initialSlide'> {
	children?: ReactNode;
	onChangeSlide: (slide: number) => void;
	slide: number;
	instruction?: CardsProps['instruction'];
	setInstructions?: CardsProps['setInstructions'];
	setFullscreen?: React.Dispatch<React.SetStateAction<boolean>>;
	fullscreenCards?: boolean[];
}

export const SwiperUncontrolledPure: FunctionComponent<Props> = ({
	children,
	onChangeSlide,
	onSlideChangeTransitionEnd,
	onSlideResetTransitionEnd,
	instruction = 0,
	setInstructions = () => {},
	slide,
	setFullscreen,
	fullscreenCards,
	onSwiper: onSwiperRaw,
	...props
}) => {
	const [swiper, setSwiper] = useState<SwiperType>();
	const onSwiper = useCallback((swiper: SwiperType) => {
		setSwiper(swiper);
		onSwiperRaw?.(swiper);
	}, [onSwiperRaw]);

	const [increment, setIncrement] = useState(0);
	useEffect(() => swiper?.slideTo(slide), [increment, slide, swiper]);

	useEvent('slideChangeTransitionEnd', (swiper: SwiperType): void => {
		onSlideChangeTransitionEnd?.(swiper);
		onChangeSlide(swiper.activeIndex);
		setIncrement((increment) => increment + 1);
		setInstructions(instruction + 1);
	}, swiper);

	useEvent('slideResetTransitionEnd', (swiper: SwiperType): void => {
		onSlideResetTransitionEnd?.(swiper);
		onChangeSlide(swiper.activeIndex);
		setIncrement((increment) => increment + 1);
		setInstructions(instruction + 1);
	}, swiper);

	useEffect(() => {
		if (setFullscreen && fullscreenCards) {
			setFullscreen(fullscreenCards[slide]);
		}
	}, [fullscreenCards, setFullscreen, slide]);

	return (
		<>
			<SwiperGlobalStyles />

			<Swiper
				{...props}
				initialSlide={slide}
				onSwiper={onSwiper}
			>
				{children}
			</Swiper>
		</>
	);
};

const SwiperUncontrolled = memo(SwiperUncontrolledPure);

export default SwiperUncontrolled;
