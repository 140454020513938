import { css } from 'styled-components';

import { boxShadow, reset, transition } from 'components/styles';
import { contrast } from 'utils';

export const button = css`
	${reset}
	${transition}
	display: inline-block;
	padding: 0.8rem;
	font-family: inherit;
	font-weight: 700;
	line-height: 1;
	cursor: pointer;
	user-select: none;
	border-width: 1px;
	border-style: solid;
	border-radius: 4px;
	transition-property: background-color, border-color, box-shadow, color;
	text-align: center;
	text-rendering: auto;
	text-transform: uppercase;
	color: ${({ theme: { buttonColor } }) => buttonColor};
	background-color: ${({ theme: { buttonBackground } }) => buttonBackground};
	border-color: ${({ theme: { buttonBorderColor } }) => buttonBorderColor};
	white-space: nowrap;

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			${boxShadow}
			color: ${({ theme: { buttonEmphasisColor } }) => buttonEmphasisColor};
			background-color: ${({ theme: { buttonEmphasisBackground } }) => buttonEmphasisBackground};
			border-color: ${({ theme: { buttonEmphasisBorderColor } }) => buttonEmphasisBorderColor};
		}
	}

	&:active {
		box-shadow: none;
		color: ${({ theme: { buttonEmphasisColor } }) => buttonEmphasisColor};
		background-color: ${({ theme: { buttonEmphasisBackground } }) => buttonEmphasisBackground};
		border-color: ${({ theme: { buttonEmphasisBorderColor } }) => buttonEmphasisBorderColor};
	}

	&:disabled {
		cursor: not-allowed;
		box-shadow: none;
		color: ${({ theme: { buttonDisabledColor } }) => buttonDisabledColor};
		background-color: ${({ theme: { buttonDisabledBackground } }) => buttonDisabledBackground};
		border-color: ${({ theme: { buttonDisabledBorderColor } }) => buttonDisabledBorderColor};

		&:hover {
			cursor: not-allowed;
			box-shadow: none;
			color: ${({ theme: { buttonDisabledColor } }) => buttonDisabledColor};
			background-color: ${({ theme: { buttonDisabledBackground } }) => buttonDisabledBackground};
			border-color: ${({ theme: { buttonDisabledBorderColor } }) => buttonDisabledBorderColor};
		}
	}

	&.submitting {
		&:disabled {
			color: transparent;
			cursor: wait;
			background-position: center;
			background-repeat: no-repeat;
			background-size: auto 25%;
			background-image: url('/submitting-${({ theme: { buttonBackground } }) => contrast(buttonBackground)}.svg');
			background-color: ${({ theme: { buttonBackground } }) => buttonBackground};
			border-color: ${({ theme: { buttonBorderColor } }) => buttonBorderColor};

			&:hover {
				color: transparent;
			}
		}
	}

	img { /* stylelint-disable-line selector-max-type -- Center images in buttons */
		display: block;
		margin: -0.1875rem auto;
		height: 1.375rem;
	}
`;
