import classnames from 'classnames';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import type { CSSProperties, FunctionComponent, MutableRefObject, ReactNode } from 'react';
import { useEvent } from 'react-use';
import styled from 'styled-components';

import { transition } from 'components/styles';

const useMutationObserver = (ref: MutableRefObject<HTMLElement | null>, callback: () => void): void => {
	useEffect(() => {
		if (!ref.current) {
			return;
		}

		// @ts-expect-error -- WebKitMutationObserver is necessary
		const Observer = (global.WebKitMutationObserver as typeof MutationObserver | undefined) ?? MutationObserver;

		const observer = new Observer(callback);

		observer.observe(ref.current, {
			attributes: true,
			childList:  true,
			subtree:    true,
		});

		return () => observer.disconnect();
	}, [callback, ref]);
};

const Container = styled.div`
	${transition}
	position: relative;
	display: block;
	height: 0;
	min-height: 0;
	overflow: hidden;
	pointer-events: none;
	transition-property: min-height;

	&.open {
		pointer-events: auto;
	}
`;

const Inner = styled.div`
	width: 100%;
	overflow: hidden;

	&.fixBottom {
		position: absolute;
		bottom: 0;
		left: 0;
	}
`;

export interface Props {
	children?: ReactNode;
	className?: string;
	duration?: number;
	fixBottom?: boolean;
	open: boolean;
	style?: CSSProperties;
}

export const CollapsePure: FunctionComponent<Props> = ({
	children,
	className,
	duration,
	fixBottom,
	open,
	style,
}) => {
	const ref = useRef<HTMLDivElement | null>(null);

	const [measure, setMeasure] = useState(() => (!ref.current ? undefined : ref.current.offsetHeight));

	const recalculate = useCallback((): void => setMeasure(!ref.current ? undefined : ref.current.offsetHeight), []);
	useEffect(() => recalculate(), [recalculate]);
	useEvent('resize', recalculate);
	useEvent('orientationchange', recalculate);
	useMutationObserver(ref, recalculate);

	return (
		<Container
			className={classnames({ open }, className)}
			style={{
				minHeight:          !open ? undefined : measure ?? 0,
				transitionDuration: !duration ? undefined : `${duration}ms`,
				...style,
			}}
		>
			<Inner ref={ref} className={classnames({ fixBottom })}>
				{children}
			</Inner>
		</Container>
	);
};

const Collapse = memo(CollapsePure);

export default Collapse;
