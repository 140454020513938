import type { Blank, Variation, VariationGrouped } from '@drop-party/sanity';
import classnames from 'classnames';
import { findIndex, flow, keyBy, mapValues } from 'lodash/fp';
import React, { Fragment, memo, useCallback, useMemo, useState } from 'react';
import type { FunctionComponent } from 'react';
import styled from 'styled-components';

import { reset, transition } from 'components/styles';
import type { SKU } from 'models/products';
import { getAllSKUs, getSkuName } from 'models/products';

const Circles = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin-right: -0.5rem;
`;

// TODO Circle should inherit styles from Buttons
const Circle = styled.button`
	${reset}
	${transition}
	display: flex;
	align-items: center;
	justify-content: center;
	width: calc(100% / 5 - 0.5rem);
	max-width: 65px;
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
	cursor: pointer;
	user-select: none;
	background-color: transparent;
	border: 1px solid ${({ theme: { dividerColor } }) => dividerColor};
	transition-property: background-color, border-color, color;
	border-radius: 50%;

	&.selected {
		color: ${({ theme: { invertedColor } }) => invertedColor};
		background-color: ${({ theme: { emphasisColor } }) => emphasisColor};
		border-color: ${({ theme: { emphasisColor } }) => emphasisColor};
	}

	&:disabled {
		color: #9e9e9e;
		cursor: not-allowed;
		background-color: #f2f2f2;
		border-color: #f2f2f2;
	}
`;

const ColorCircleContainer = styled.div<{ color: string }>`
	${transition}
	padding: 2px;
	margin-left: -4px;
	margin-top: -4px;
	margin-right: calc(0.5rem - 4px);
	margin-bottom: calc(0.5rem - 4px);
	width: calc(100% / 5 - 0.5rem + 8px);
	border: 2px solid transparent;
	border-radius: 50%;
	transition-property: border-color;

	&.selected {
		border-color: ${({ theme: { emphasisColor } }) => emphasisColor};
	}

	${Circle} {
		width: calc(100%);
		margin-bottom: 0;
		margin-right: 0;
		background-color: ${({ color }) => color};
		border-color: ${({ color }) => color};

		&.selected {
			background-color: ${({ color }) => color};
			border-color: ${({ color }) => color};
		}

		&:disabled {
			opacity: 40%;
		}
	}
`;

const Label = styled.span`
	position: relative;
	width: 100%;
	padding-top: 100%;
`;

const LabelInner = styled.span`
	position: absolute;
	top: 50%;
	left: 50%;
	line-height: 1;
	font-weight: 700;
	transform: translate(-50%, -50%);
	font-size: 0.75rem;
`;

interface CircleSelectorProps {
	disabled?: boolean;
	isDisabled?: (value: string) => boolean;
	onChange: (value: string) => void;
	value: string | undefined;
	variants: {
		_key: string;
		color?: { hex: string };
		label: string;
		labelShort?: string;
		skuName: { current: string };
	}[];
}

const CircleSelectorPure: FunctionComponent<CircleSelectorProps> = ({
	isDisabled,
	onChange,
	variants,
	value,
	disabled: disabledRaw = false,
}) => (
	<Circles>
		{variants.map(({
			label,
			labelShort,
			_key: key,
			skuName: { current: skuName },
			color: { hex: color } = {},
		}) => {
			const disabled = disabledRaw || isDisabled?.(skuName);

			return (
				<Fragment key={key}>
					{
						color
							? (
								<ColorCircleContainer
									color={color}
									className={classnames({
										disabled,
										selected: value === skuName,
									})}
								>
									<Circle
										className={classnames({ selected: value === skuName })}
										disabled={disabled}
										onClick={() => !disabled && onChange(skuName)}
										title={label}
										type="button"
									>
										<Label />
									</Circle>
								</ColorCircleContainer>
							)
							: (
								<Circle
									className={classnames({ selected: value === skuName })}
									disabled={disabled}
									onClick={() => !disabled && onChange(skuName)}
									title={label}
									type="button"
								>
									<Label>
										<LabelInner>
											{labelShort ?? label}
										</LabelInner>
									</Label>
								</Circle>
							)
					}
				</Fragment>
			);
		})}
	</Circles>
);

const CircleSelector = memo(CircleSelectorPure);

const VariationSelectorLabel = styled.span`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 1.25rem 0;
	font-size: 0.75rem;

	&:first-of-type {
		padding-top: 0;
	}
`;

const Link = styled.a`
	color: ${({ theme: { color } }) => color};
	font-weight: 700;

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			color: ${({ theme: { emphasisColor } }) => emphasisColor};
		}
	}
`;

interface VariationProps {
	disabled?: boolean;
	isDisabled?: (value: string) => boolean;
	onChange: (value: string | undefined) => void;
	value: string | undefined;
	variation: Variation | VariationGrouped;
}

const VariationSelectorPure: FunctionComponent<VariationProps> = ({
	disabled,
	isDisabled,
	onChange,
	value,
	variation,
	variation: {
		label,
		link,
	},
}) => {
	const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);
	const selectedGroup = !('groups' in variation) ? undefined : variation.groups[selectedGroupIndex];

	const onChangeGroup = useCallback((current: string): void => {
		const variationGrouped = variation as VariationGrouped;
		setSelectedGroupIndex(findIndex({ skuName: { current } }, variationGrouped.groups));

		onChange(undefined);
	}, [onChange, variation]);

	const onChangeVariationGroupVariant = useCallback((variantName: string): void => onChange(`${selectedGroup!.skuName.current}-${variantName}`), [onChange, selectedGroup]);

	const isDisabledGroupVariant = useCallback((variantName: string) => (
		!isDisabled
			? false
			: isDisabled(`${selectedGroup!.skuName.current}-${variantName}`)
	), [isDisabled, selectedGroup]);

	return (
		<>
			<VariationSelectorLabel>
				Select
				{' '}
				{label}
				{link && (
					<Link href={link.href} target="_blank" rel="noreferrer">
						{link.text}
					</Link>
				)}
			</VariationSelectorLabel>

			{
				'groups' in variation
					? (
						<>
							<CircleSelector
								disabled={disabled}
								onChange={onChangeGroup}
								value={selectedGroup!.skuName.current}
								variants={variation.groups}
							/>
							<CircleSelector
								disabled={disabled}
								isDisabled={isDisabledGroupVariant}
								onChange={onChangeVariationGroupVariant}
								value={value?.replace(`${selectedGroup!.skuName.current}-`, '')}
								variants={selectedGroup!.variants}
							/>
						</>
					)
					: (
						<CircleSelector
							disabled={disabled}
							isDisabled={isDisabled}
							onChange={onChange}
							value={value}
							variants={variation.variants}
						/>
					)
			}
		</>
	);
};

const VariationSelector = memo(VariationSelectorPure);

export interface Props {
	disabled?: boolean;
	isSKUDisabled?: (sku: SKU) => boolean;
	onChange: (sku: SKU) => void;
	product: { blank?: Pick<Blank, 'variations'> } & Parameters<typeof getAllSKUs>[0];
	value: SKU;
}

export const SKUSelectorPure: FunctionComponent<Props> = ({
	disabled,
	onChange,
	product,
	value: sku,
	isSKUDisabled = (): boolean => false,
	product: { blank: { variations = [] } = {} },
}) => {
	const skuIsDisabled = useMemo(() => flow(
		getAllSKUs,
		keyBy(getSkuName),
		mapValues(isSKUDisabled)
	)(product), [isSKUDisabled, product]);

	return (
		<>
			{variations.map(({ _key: key, ...variation }) => {
				const { skuName: { current: variationName } } = variation;
				const { [variationName]: currentValue, ...skuVariations } = sku.variations;

				return (
					<VariationSelector
						key={key}
						disabled={disabled}
						value={currentValue}
						variation={variation}
						isDisabled={(value: string): boolean => Boolean(skuIsDisabled[getSkuName({
							...sku,
							variations: {
								...skuVariations,
								[variationName]: value,
							},
						})])}
						onChange={(value: string | undefined): void => {
							onChange({
								...sku,
								variations: currentValue === value || value === undefined
									? skuVariations
									: {
										...skuVariations,
										[variationName]: value,
									},
							});
						}}
					/>
				);
			})}
		</>
	);
};

const SKUSelector = memo(SKUSelectorPure);

export default SKUSelector;
