import type { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect } from 'react';
import { useAsync } from 'react-use';

import { useSentry } from 'vendors/sentry/hooks';

export const useStripe = (): {
	error?: Error;
	loading: boolean;
	stripe?: Stripe | null;
} => {
	const { error, loading, value: stripe } = useAsync(async () => loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY!, { apiVersion: '2020-08-27' }), []);

	const Sentry = useSentry();
	useEffect(() => {
		if (!error) {
			return;
		}

		Sentry.captureException(error);
	}, [Sentry, error]);

	return {
		error,
		loading,
		stripe,
	};
};
