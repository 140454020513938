import { memo, useEffect } from 'react';
import type { FunctionComponent } from 'react';
import { useForm, useFormState } from 'react-final-form';

interface Props {
	hasSubmitSucceeded?: boolean;
	whenDirtySinceLastSubmit?: string[];
}

const AutoSubmitPure: FunctionComponent<Props> = ({ hasSubmitSucceeded, whenDirtySinceLastSubmit }) => {
	const form = useForm();

	const {
		dirtyFieldsSinceLastSubmit,
		invalid,
		submitSucceeded,
	} = useFormState({
		subscription: {
			dirtyFieldsSinceLastSubmit: Boolean(whenDirtySinceLastSubmit),
			invalid:                    true,
			submitSucceeded:            hasSubmitSucceeded,
		},
	});

	useEffect(() => {
		if (invalid) {
			return;
		}

		if (hasSubmitSucceeded && !submitSucceeded) {
			return;
		}

		if (whenDirtySinceLastSubmit?.every((field) => !dirtyFieldsSinceLastSubmit[field])) {
			return;
		}

		void form.submit();
	}, [
		dirtyFieldsSinceLastSubmit,
		form,
		hasSubmitSucceeded,
		invalid,
		submitSucceeded,
		whenDirtySinceLastSubmit,
	]);

	return null;
};

const AutoSubmit = memo(AutoSubmitPure);

export default AutoSubmit;
