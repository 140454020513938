import type { FunctionComponent } from 'react';
import React, { memo, useContext } from 'react';
import styled, { ThemeContext, css } from 'styled-components';

import { transition } from 'components/styles';
import { contrast } from 'utils';

const footerHeightColumn = 4.625;
const footerHeightRow = 2.25;
const footerLayoutBreakpointPx = 570;

export const useFooterHeightPx = (widthPx: number): number => {
	const { fontSize } = useContext(ThemeContext);

	return fontSize * (
		widthPx < footerLayoutBreakpointPx
			? footerHeightColumn
			: footerHeightRow
	);
};

const Container = styled.footer`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	font-size: 0.75rem;
	line-height: 1;
	color: ${({ theme: { secondaryColor } }) => secondaryColor};

	@media (min-width: ${footerLayoutBreakpointPx}px) {
		flex-direction: row;
		align-items: flex-start;
	}
`;

const LogoLink = styled.a`
	${transition}
	height: 1.5rem;
	width: 2.1025rem;
	display: inline-block;
	opacity: 50%;
	transition-property: opacity;
	background-position: center;
	background-size: 2.1025rem;
	background-repeat: none;
	background-image: url('/drop-fire-${({ theme: { bodyBackground } }) => contrast(bodyBackground)}.svg');

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			opacity: 100%;
		}
	}
`;

const section = css`
	padding: 0.5rem 0 0;

	@media (min-width: ${footerLayoutBreakpointPx}px) {
		flex-basis: 0;
		flex-grow: 1;
		padding-bottom: 1rem;
	}
`;

const Links = styled.div`
	${section}
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
`;

const Link = styled.a`
	${transition}
	color: ${({ theme: { secondaryColor } }) => secondaryColor};
	transition-property: color;

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			color: ${({ theme: { emphasisColor } }) => emphasisColor};
		}
	}
`;

const Copyright = styled.div`
	${section}
	padding-bottom: 0.5rem;

	@media (min-width: ${footerLayoutBreakpointPx}px) {
		order: -1;
	}
`;

export interface Props {
	className?: string;
}

export const FooterPure: FunctionComponent<Props> = ({ className }) => (
	<Container className={className}>
		<LogoLink href={`https://${process.env.NEXT_PUBLIC_URL!}`} target="_blank" rel="noreferrer" />

		<Links>
			<Link href="/terms.html" target="_blank" rel="noreferrer">Terms of Use</Link>
			&nbsp;
			/
			&nbsp;
			<Link href="/privacy.html" target="_blank" rel="noreferrer">Privacy Policy</Link>
		</Links>

		<Copyright>
			©
			{' '}
			{new Date().getFullYear()}
			{' '}
			Drop Party. All rights reserved.
		</Copyright>
	</Container>
);

const Footer = memo(FooterPure);

export default Footer;
