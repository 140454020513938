import { isString, pickBy } from 'lodash/fp';
import HttpError from 'standard-http-error';

interface FetchJSONOptions<Body, Query> extends Omit<RequestInit, 'body'> {
	body?: Body;
	query?: Query;
}

export const fetchJSON = async <Response, Body = string, Query = { [param: string]: string | undefined }>(path: string, options?: FetchJSONOptions<Body, Query>): Promise<Response> => {
	const query = options?.query && new URLSearchParams(pickBy(isString, options.query)).toString();

	const response = await fetch(`${path}${!query ? '' : `?${query}`}`, {
		...options,
		body: options?.body && (
			isString(options.body)
				? options.body
				: JSON.stringify(options.body)
		),
		headers: {
			'Accept':       'application/json',
			'Content-Type': 'application/json',
			...options?.headers,
		},
	});

	if (!response.ok) {
		throw new HttpError(response.status, {
			headers: response.headers,
			...await response.json(),
		});
	}

	return (await response.json()) as Response;
};
