import type { EmbeddedImage } from '@drop-party/sanity';
import type { BlockRendererProps } from '@sanity/block-content-to-react';
import BlockContent from '@sanity/block-content-to-react';
import { isString } from 'lodash/fp';
import React from 'react';
import type { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

import { imageBuilder } from 'vendors/sanity/utils';

export const ReplaceTextRenderer: FunctionComponent<BlockRendererProps & {
	children?: ReactNode[];
	toReplace: string;
	replaceWith: string;
}> = ({ children, toReplace, replaceWith, ...props }) => BlockContent.defaultSerializers.types.block({
	...props,
	children: children?.map((child) => (isString(child)
		? child.replace(toReplace, replaceWith)
		: child)),
});

const ImageWrapperTable = styled.table`
	width: 100%;
	padding: 1rem 0;
`;

const ImageContainerCell = styled.td<{ textAlign: string }>`
	display: inline-block;
	width: 100%;
	text-align: ${({ textAlign }) => textAlign};
`;

const Image = styled.img<{ cssWidth: string }>`
	display: inline-block;
	width: ${({ cssWidth }) => cssWidth};
	max-width: 100%;
`;

export const InlineImageRenderer: FunctionComponent<{ node: EmbeddedImage }> = ({
	node: {
		image,
		textAlign,
		width,
	},
}) => (
	<ImageWrapperTable width="100%" role="presentation">
		<tr>
			<ImageContainerCell textAlign={textAlign}>
				<Image
					src={imageBuilder.image(image).url()!}
					alt={image.alt}
					width={width}
					cssWidth={width}
				/>
			</ImageContainerCell>
		</tr>
	</ImageWrapperTable>
);
