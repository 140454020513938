import type { FieldSubscription } from 'final-form';
import type { FocusEvent } from 'react';
import type { FieldMetaState } from 'react-final-form';

type OnFocus = (event?: FocusEvent<HTMLInputElement | HTMLSelectElement>) => void;

export const reportValiditySubscription: FieldSubscription = {
	dirtySinceLastSubmit: true,
	submitError:          true,
	touched:              true,
	valid:                true,
};

export const reportValidity = <T extends any = any>(onFocus: OnFocus, { dirtySinceLastSubmit, submitError, touched, valid }: FieldMetaState<T>): OnFocus => (
	valid
		? onFocus
		: submitError && !dirtySinceLastSubmit
			? (event) => {
				event?.target.setCustomValidity(submitError);
				event?.target.reportValidity();

				onFocus(event);
			}
			: (event) => {
				if (touched) {
					event?.target.reportValidity();
				}

				onFocus(event);
			}
);
