import classnames from 'classnames';
import type { Country } from 'country-region-data';
import parsePhoneNumber, { AsYouType, ParseError, getCountries, getCountryCallingCode, parsePhoneNumberWithError } from 'libphonenumber-js/max';
import type { CountryCode } from 'libphonenumber-js/max';
import { fill, flow, join, keyBy, last, mapValues, takeWhile, range } from 'lodash/fp';
import React, { Fragment, memo, useMemo, useState } from 'react';
import type { FunctionComponent, ReactNode } from 'react';
import type { FieldProps, FieldRenderProps } from 'react-final-form';
import { Field } from 'react-final-form-html5-validation';
import { useAsync } from 'react-use';
import styled, { css } from 'styled-components';

import { border, disabled, field, focus, hover, invalid, selectField } from 'components/Fields';
import { transition } from 'components/styles';
import { reportValidity, reportValiditySubscription } from 'utils/forms';

const Container = styled.fieldset`
	${transition}
	${border}
	${focus}
	${hover}
	${invalid}
	position: relative;
	display: flex;
	flex-direction: row;
	background-color: ${({ theme: { inputBackground } }) => inputBackground};
	transition-property: border-color, box-shadow, color;

	&:disabled {
		&:disabled {
			${disabled}
		}
	}
`;

const Select = styled.select`
	${selectField}
	padding-right: 1.5rem;
	width: calc(4rem);
	color: transparent;
	border: none;
	border-radius: 4px;
`;

const Flag = styled.img`
	position: absolute;
	top: 1.5rem;
	left: 1rem;
	pointer-events: none;
	user-select: none;
	transform: translate(0, -50%);
`;

const inputLike = css`
	${field}
	padding-left: 0;
	background-color: transparent;
	border: none;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
`;

const Input = styled.input`
	${inputLike}
	z-index: 1;
	flex-grow: 1;
	outline: none;
`;

const Placeholder = styled.div`
	${inputLike}
	position: absolute;
	top: 0;
	left: calc(4rem);
	display: flex;
	align-items: center;
	color: ${({ theme: { inputPlaceholderColor } }) => inputPlaceholderColor};
	text-rendering: auto;
	pointer-events: none;
`;

const NationalSignificantNumber = styled.div`
	position: absolute;
	left: 0;
	font-weight: 600;
	color: ${({ theme: { emphasisColor } }) => emphasisColor};
	z-index: 1;

	/* stylelint-disable selector-max-type,selector-type-no-unknown,selector-type-case -- Styled-Components interpolation */

	${Input}:-webkit-autofill + ${Placeholder} & {
		color: #000000;
	}
	/* stylelint-enable selector-max-type,selector-type-no-unknown,selector-type-case -- Styled-Components interpolation */
`;

const PlaceholderEcho = styled.span`
	color: transparent;
	visibility: hidden;
	opacity: 0%;
`;

export interface Props extends FieldProps<string, FieldRenderProps<string>> {
	className?: string;
}

export const TelephoneInputPure: FunctionComponent<Props> = ({ className, name }) => {
	const [countryCode, setCountryCode] = useState<CountryCode>('US');
	const callingCode = useMemo(() => `+${getCountryCallingCode(countryCode) as string}`, [countryCode]);

	const { value: countryRegionData } = useAsync(async () => (await import('country-region-data')).default, []);

	const countryNames: { [countryCode: string]: string | undefined } | undefined = useMemo(() => countryRegionData && flow(
		keyBy<Country>(({ countryShortCode }) => countryShortCode),
		mapValues<Country, string>(({ countryName }) => countryName)
	)(countryRegionData), [countryRegionData]);

	const countryCodeOptions: { callingCode: string; label: string; value: string }[] | undefined = useMemo(() => countryNames && getCountries().map((value) => {
		const callingCode = `+${getCountryCallingCode(value) as string}`;

		return {
			callingCode,
			value,
			label: `${countryNames[value as string] ?? value} ${callingCode}`,
		};
	}), [countryNames]);

	return (
		<Field<string>
			name={name}
			type="tel"
			subscription={{
				...reportValiditySubscription,
				dirty:   true,
				touched: true,
				value:   true,
			}}
			validate={(value): string | undefined => {
				let phoneNumber;

				try {
					phoneNumber = parsePhoneNumberWithError(value);
				} catch (err) {
					return err instanceof ParseError
						? {
							INVALID_COUNTRY: 'Invalid country',
							NOT_A_NUMBER:    'Not a phone number',
							TOO_LONG:        'Too long',
							TOO_SHORT:       'Too short',
						}[err.message] ?? err.message
						: err instanceof Error
							? err.message
							: 'Invalid';
				}

				return !phoneNumber.isPossible()
					? 'Incorrect Length'
					: ![
						'',
						'FIXED_LINE_OR_MOBILE',
						'MOBILE',
						'VOIP',
					].includes(phoneNumber.getType() ?? '')
						? `${phoneNumber.getType() ?? ''} numbers are not accepted`
						: undefined;
			}}
			render={({
				meta,
				input: {
					onChange,
					onFocus,
					value,
					...input
				},
				meta: {
					dirty,
					touched,
				},
			}: FieldRenderProps<string>): ReactNode => (
				<Container
					className={classnames({
						dirty,
						touched,
					}, className)}
				>
					<Select
						// TODO Why did I put this id again?
						id="telephone"
						value={countryCode}
						onChange={(e): void => {
							const countryCode = e.target.value as CountryCode;
							setCountryCode(countryCode);

							const callingCode = `+${getCountryCallingCode(countryCode) as string}`;

							if (value.startsWith(callingCode)) {
								return;
							}

							onChange('');
						}}
					>
						{countryCodeOptions?.map(({ label, value }) => (
							<option key={value} value={value}>
								{label}
							</option>
						))}
					</Select>

					<Flag
						alt={countryNames?.[countryCode] ?? countryCode}
						src={`https://cdn.jsdelivr.net/gh/madebybowtie/FlagKit@2.2/Assets/SVG/${countryCode}.svg`}
					/>

					<Input
						{...input}
						autoComplete="mobile tel"
						onFocus={reportValidity(onFocus, meta)}
						value={value}
						onChange={({ target: { value: rawValue } }): void => {
							const valueSplit = rawValue.replace(/\s/gu, '').split('+');
							const lastValue = valueSplit[valueSplit.length - 1];

							const value = valueSplit.length !== 1 || lastValue.startsWith(callingCode.charAt(1))
								? `+${lastValue}`
								: lastValue.length
									? callingCode + lastValue
									: lastValue;

							const asYouType = new AsYouType(countryCode);

							onChange(asYouType.input(value));

							const countryCodeNew = asYouType.getCountry();

							if (!countryCodeNew || countryCode === countryCodeNew) {
								return;
							}
							setCountryCode(countryCodeNew);
						}}
					/>

					<Placeholder>
						<NationalSignificantNumber>
							{(value || callingCode).slice(0, callingCode.length)}
						</NationalSignificantNumber>
						<PlaceholderEcho>
							{value}
						</PlaceholderEcho>
						<span>
							{
								parsePhoneNumber(
									(value + callingCode.slice(value.length, callingCode.length)) + flow(
										range(0),
										takeWhile(
											(numberOfFives) => !parsePhoneNumber(
												(value + callingCode.slice(value.length, callingCode.length)) + flow(
													range(0),
													fill(0, 100, '5'),
													join('')
												)(numberOfFives),
												countryCode
											)?.isPossible()
										),
										last,
										(numberOfFivesMinusOne) => (numberOfFivesMinusOne === undefined ? 0 : numberOfFivesMinusOne + 1),
										range(0),
										fill(0, 100, '5'),
										join('')
									)(20),
									countryCode
								)
									?.formatInternational()
									.slice(value.length)
									.split(' ')
									.map((part, i) => (
										<Fragment key={i}>
											{part}
											&nbsp;
										</Fragment>
									))

							}
						</span>
					</Placeholder>
				</Container>
			)}
		/>
	);
};

const TelephoneInput = memo(TelephoneInputPure);

export default TelephoneInput;
