import { css } from 'styled-components';

import { reset } from 'components/styles';

import { Card } from '.';

export const cardInner = css`
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
`;

export const header = css`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 1rem 1.5rem;
	background: ${({ theme: { surfaceBackground } }) => surfaceBackground};
	border-bottom: 1px solid ${({ theme: { dividerColor } }) => dividerColor};
`;

export const headerText = css`
	flex-grow: 1;
	font-size: 1.125rem;
	font-weight: 700;
`;

export const headerContext = css`
	font-size: 0.875rem;
`;

export const back = css`
	${reset}
	padding: 0.5rem;
	margin: -0.5rem 0 -0.5rem -0.5rem;
	height: calc(1rem + 20px);
	cursor: pointer;
	user-select: none;
	line-height: 1;

	/* stylelint-disable declaration-empty-line-before,declaration-block-semicolon-newline-after,rule-empty-line-before -- Styled-Components interpolation */

	${Card}.multiCard & {
		display: none;
	}
	/* stylelint-enable declaration-empty-line-before,declaration-block-semicolon-newline-after,rule-empty-line-before */
`;

export const content = css`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding: 0 1.5rem 1.5rem;
	overflow-y: auto;

	> * { /* stylelint-disable-line selector-max-universal -- Safari needs this to not squish layouts */
		flex-shrink: 0;
	}
`;

export const sectionHeader = css`
	margin: 0.5rem 0;
	font-weight: 800;

	&:first-child {
		margin-top: 1rem;
	}
`;
