import type { ImageMeta, Theme } from '@drop-party/sanity';
import classnames from 'classnames';
import { findIndex, isBoolean, isString } from 'lodash/fp';
import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import type { ReactElement, ReactNode } from 'react';
import { useEvent } from 'react-use';
import styled, { ThemeContext, keyframes } from 'styled-components';
import SwiperCore, { Mousewheel } from 'swiper';
import type { Swiper } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import { useFooterHeightPx } from 'components/Footer';
import type { Props as SwiperUncontrolledProps, SwiperSlideRenderProps } from 'components/SwiperUncontrolled';
import SwiperUncontrolled from 'components/SwiperUncontrolled';
import { boxShadow, hide, transition } from 'components/styles';
import { SanityThemeProvider, basic } from 'components/themes';
import { useWindowInnerSize } from 'utils/hooks';
import { imageBuilder } from 'vendors/sanity/utils';

import { idealRatio } from './utils';

SwiperCore.use([Mousewheel]);

const minDesktopCardWidthPx = 304;
const spaceForDropShadowsPx = 24;
const mousewheel = { forceToAxis: true };

export const useCardSizes = (): {
	cardsBreakpointPx: number;
	spaceAroundCardDesktopHorizontalPx: number;
	spaceAroundCardDesktopVerticalPx: number;
} => {
	const { cardBleedPx, cardMarginHorizontalPx } = useContext(ThemeContext);
	const spaceAroundCardDesktopHorizontalPx = useMemo(() => 2 * Math.max(spaceForDropShadowsPx / idealRatio, cardBleedPx + cardMarginHorizontalPx), [cardBleedPx, cardMarginHorizontalPx]);
	const cardsBreakpointPx = useMemo(() => Math.max(430, minDesktopCardWidthPx + spaceAroundCardDesktopHorizontalPx), [spaceAroundCardDesktopHorizontalPx]);

	return {
		cardsBreakpointPx,
		spaceAroundCardDesktopHorizontalPx,
		spaceAroundCardDesktopVerticalPx: 2 * spaceForDropShadowsPx,
	};
};

const Container = styled.div`
	width: 100%;
	height: 100%;
`;

const Slide = styled.div`
	${basic}
	${transition}
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 100%;
	transition-property: opacity;

	@media (hover: hover) and (pointer: fine) {
		&.unfocused {
			cursor: pointer;
			opacity: 60%;

			&:hover {
				opacity: 100%;
			}
		}
	}
`;

const growOpacity = keyframes`
	from {
		opacity: 0%;
	}

	to {
		opacity: 100%;
	}
`;

export const Card = styled.div<{ centerCardDiffPx: number; multiCard: boolean }>`
	${hide}
	background-color: ${({ theme: { surfaceBackground } }) => surfaceBackground};
	overflow: hidden;
	border: ${({ theme: { cardBorder } }) => cardBorder};
	overflow-y: auto;
	margin-top: ${({ centerCardDiffPx }) => centerCardDiffPx}px;
	border-radius: ${({ multiCard, theme: { cardBorderRadiusPx } }) => (multiCard ? cardBorderRadiusPx : 0)}px;
	transform: translateZ(0);

	&.fullscreen {
		border-radius: 0;
	}

	&.smooth {
		transition: height 0.5s cubic-bezier(0.65, 0, 0.35, 1), margin 0.5s cubic-bezier(0.65, 0, 0.35, 1), border-radius 0.5s cubic-bezier(0.65, 0, 0.35, 1);
	}

	/* stylelint-disable declaration-block-semicolon-newline-after,rule-empty-line-before, order/order -- Styled-Components interpolation */
	${Slide}.unfocused & {
		pointer-events: none;
		user-select: none;
	}
	/* stylelint-enable declaration-block-semicolon-newline-after,rule-empty-line-before, order/order */

	&.multiCard {
		${boxShadow}
	}

	&.revealed {
		animation: ${growOpacity} linear 200ms both;
	}
`;

const PaginationContainer = styled.div<{documentHeight: number}>`
	position: absolute;
	top: 0;
	height: ${({ documentHeight }) => documentHeight}px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-end;
`;

const Pagination = styled.div<{multiCard: boolean; footerHeight: number}>`
	${hide}
	z-index: 3;
	margin-bottom: ${({ multiCard, footerHeight }) => `calc(${footerHeight}px + ${multiCard ? '4.5rem' : '5rem'} + 2rem)`};
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 1.5rem;
	min-width: 1.5rem;
	width: min-content;
	padding: 0.25rem;
	border-radius: 0.75rem;
	backdrop-filter: blur(4px);
	background: #0000003f;
`;

const PaginationIndicator = styled.div`
	${transition}
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 100%;
	transition-property: opacity;

	&.unfocused {
		opacity: 40%;
	}
`;

const ImageIndicator = styled.img`
	min-width: 1rem;
	height: 1rem;
	margin: 0 0.125rem;
`;

const DotIndicator = styled.div`
	width: 0.5rem;
	height: 0.5rem;
	margin: 0 0.25rem;
	background: #ffffff;
	border-radius: 50%;
`;

export interface CardRenderProps {
	active: boolean;
	baseCardHeightPx: number;
	baseCardWidthPx: number;
	cardHeightPx: number;
	cardWidthPx: number;
	multiCard: boolean;
	onBack?: () => void;
	onChangeCard: (card: string) => void;
	onChangeCardIndex: (index: number) => void;
	onForward?: () => void;
	onHideCard: (card: string) => void;
	pagination?: ReactNode;
	takeover: boolean;
}

export interface CardTypeConfig {
	theme?: Partial<Theme>;
	card?: {
		fullscreen?: boolean;
		hidden?: boolean;
		pagination?: boolean;
		paginationIcon?: boolean | string | ImageMeta;
		size?: number;
	};
}

type CardConfig<CardConfigRenderProps extends any = any> = {
	[Type in keyof CardConfigRenderProps]: CardConfigRenderProps[Type] & {
		_type: Type;
	}
}[keyof CardConfigRenderProps] & CardTypeConfig & {
	id: { current: string };
	hash?: { current: string };
};

export type CardComponentRenders<CardConfigRenderProps extends any = any> = { [Type in keyof CardConfigRenderProps]: (props: CardConfigRenderProps[Type] & CardRenderProps) => ReactElement | null };

export interface Props<CardConfigRenderProps extends any = any> extends Omit<SwiperUncontrolledProps, 'children' | 'slide' | 'onChangeSlide'> {
	card?: string;
	components: CardComponentRenders<CardConfigRenderProps>;
	cards: CardConfig<CardConfigRenderProps>[];
	heightPx: number;
	multiCard?: boolean;
	onChangeCard: (card: string) => void;
	takeover?: boolean;
	typeConfig?: { [Type in keyof CardConfigRenderProps]?: CardTypeConfig };
	widthPx: number;
	instruction?: number;
	setInstructions?: React.Dispatch<React.SetStateAction<number>>;
	setFullscreen?: React.Dispatch<React.SetStateAction<boolean>>;
	screenWidthPx: number;
	screenHeightPx: number;
}

const Cards = <CardConfigRenderProps extends any>({
	card,
	components,
	multiCard = true,
	takeover = false,
	typeConfig = {},
	cards: cardsInitial,
	heightPx: heightPxRaw,
	onChangeCard: onChangeCardRaw,
	onSwiper: onSwiperRaw,
	widthPx: widthPxRaw,
	instruction,
	setInstructions,
	setFullscreen,
	screenWidthPx,
	screenHeightPx,
	...props
}: Props<CardConfigRenderProps>): ReactElement => {
	const [centerDiff, setCenterDiff] = useState(0);
	const [transitions, setTransitions] = useState(true);

	const { cardMarginHorizontalPx } = useContext(ThemeContext);

	const {
		spaceAroundCardDesktopHorizontalPx,
		spaceAroundCardDesktopVerticalPx,
	} = useCardSizes();

	const footerHeight = useFooterHeightPx(useWindowInnerSize().widthPx) + (spaceAroundCardDesktopHorizontalPx / 2);

	const [swiper, setSwiper] = useState<Swiper>();
	const onSwiper = useCallback((swiper: Swiper) => {
		setSwiper(swiper);
		onSwiperRaw?.(swiper);
	}, [onSwiperRaw]);

	const {
		baseCardHeightPx,
		baseCardWidthPx,
		heightPx,
		widthPx,
		baseCardHeightPx: cardHeightPx,
	} = useMemo(() => {
		if (!multiCard) {
			return {
				baseCardHeightPx: heightPxRaw,
				baseCardWidthPx:  widthPxRaw,
				heightPx:         heightPxRaw,
				widthPx:          widthPxRaw,
			};
		}

		const baseCardWidthPx = Math.max(
			minDesktopCardWidthPx - spaceAroundCardDesktopHorizontalPx,
			Math.min(
				(heightPxRaw - spaceAroundCardDesktopVerticalPx) / idealRatio,
				widthPxRaw - spaceAroundCardDesktopHorizontalPx
			)
		);

		return {
			baseCardWidthPx,
			baseCardHeightPx: baseCardWidthPx * idealRatio,
			heightPx:         (baseCardWidthPx * idealRatio) + spaceAroundCardDesktopVerticalPx,
			widthPx:          widthPxRaw,
		};
	}, [
		heightPxRaw,
		multiCard,
		spaceAroundCardDesktopHorizontalPx,
		spaceAroundCardDesktopVerticalPx,
		widthPxRaw,
	]);

	useEffect(() => swiper?.update(), [heightPx, swiper, widthPx]);

	const [revealedIds, setRevealedIds] = useState<{ [card: string]: boolean | undefined }>({});

	const onChangeCard = useCallback((card: string) => {
		setRevealedIds((revealedIds) => ({
			...revealedIds,
			[card]: true,
		}));

		onChangeCardRaw(card);
	}, [onChangeCardRaw]);

	const onHideCard = useCallback((card: string) => setRevealedIds((revealedIds) => ({
		...revealedIds,
		[card]: false,
	})), [setRevealedIds]);

	const cards: CardConfig<CardConfigRenderProps>[] = useMemo(() => cardsInitial.filter(({
		_type,
		card: { hidden } = {},
		id: { current: id },
	}) => {
		const { [_type]: { card: { hidden: hiddenType = false } = {} } = {} } = typeConfig;

		return !(hidden ?? hiddenType) || revealedIds[id];
	}), [cardsInitial, revealedIds, typeConfig]);

	const cardIndex = useMemo(() => {
		if (!card) {
			return 0;
		}

		const indexWithId = findIndex(({ id: { current } }: CardConfig<CardConfigRenderProps>) => current === card, cards);

		return indexWithId === -1 ? 0 : indexWithId;
	}, [card, cards]);
	const onChangeCardIndex = useCallback((index: number) => (
		index < 0 || index >= cards.length
			? undefined
			: onChangeCardRaw(cards[index].id.current)
	), [cards, onChangeCardRaw]);

	const cardWidths = useMemo(() => cards.map(({
		_type,
		card: {
			size,
			fullscreen = false,
		} = {},
	}) => {
		const {
			[_type]: {
				card: {
					size: sizeType = 1,
					fullscreen: fullscreenType = false,
				} = {},
			} = {},
		} = typeConfig;

		return (
			fullscreen || fullscreenType
				? screenWidthPx
				: !multiCard
					? baseCardWidthPx
					: Math.min((size ?? sizeType) * baseCardWidthPx, widthPx - spaceAroundCardDesktopHorizontalPx)
		);
	}), [
		baseCardWidthPx,
		cards,
		multiCard,
		spaceAroundCardDesktopHorizontalPx,
		typeConfig,
		widthPx,
		screenWidthPx,
	]);

	const fullscreenCards = useMemo(() => cards.map(({
		_type,
		card: { fullscreen } = {},
	}) => {
		const { [_type]: { card: { fullscreen: fullscreenType = false } = {} } = {} } = typeConfig;

		return Boolean(fullscreen ?? fullscreenType);
	}), [cards, typeConfig]);

	const cardsContainer = useRef<HTMLDivElement | null>(null);

	const updateCenterDiff = useCallback(() => {
		if (!cardsContainer.current) {
			return;
		}

		const boundingRect = cardsContainer.current.getBoundingClientRect();
		const fromTop = boundingRect.top;
		const fromBottom = screenHeightPx - boundingRect.bottom;

		setCenterDiff(fromBottom - fromTop);
	}, [cardsContainer, screenHeightPx]);

	useEvent('resize', updateCenterDiff);
	useEvent('DOMContentLoaded', updateCenterDiff);
	useEffect(() => {
		requestAnimationFrame(updateCenterDiff);
	}, [updateCenterDiff]);

	const [resizeTimer, setResizeTimer] = useState<NodeJS.Timeout | undefined>();

	const updateResizeTimer = useCallback(() => {
		setTransitions(false);
		if (resizeTimer) {
			clearTimeout(resizeTimer);
		}
		setResizeTimer(setTimeout(() => setTransitions(true), 400));
	}, [resizeTimer]);

	useEvent('resize', updateResizeTimer);

	return (
		<Container ref={cardsContainer}>
			<SwiperUncontrolled
				allowTouchMove
				centeredSlides
				observeParents
				observeSlideChildren
				observer
				slideToClickedSlide
				updateOnWindowResize
				watchSlidesVisibility
				mousewheel={mousewheel}
				setFullscreen={setFullscreen}
				fullscreenCards={fullscreenCards}
				slidesPerView="auto"
				spaceBetween={!multiCard ? 0 : cardMarginHorizontalPx}
				speed={200}
				threshold={15}
				{...props}
				instruction={instruction}
				setInstructions={setInstructions}
				onChangeSlide={onChangeCardIndex}
				onSwiper={onSwiper}
				slide={cardIndex}
				style={{ height: `${heightPx}px` }}
			>
				{cards.map((cardConfig, i) => {
					const {
						_type,
						theme,
						id: { current: id },
						card: { pagination } = {},
					} = cardConfig;

					const {
						[_type]: {
							theme: themeType = undefined,
							card: { pagination: paginationType = false } = {},
						} = {},
					} = typeConfig;
					const { [_type]: CardComponent } = components;

					return (
						<SwiperSlide
							key={id}
							onFocus={() => onChangeCardRaw(id)}
							// TODO SwiperSlide can't be in a StyledComponent but needs a style, so using inline styles 😢
							style={{ width: 'auto' }}
						>
							{({
								isActive,
								isActive: active,
								isActive: globals,
							}: SwiperSlideRenderProps): ReactElement => (
								<SanityThemeProvider
									globals={globals}
									theme={{
										...themeType,
										...theme,
									}}
								>
									<Slide
										className={classnames({ unfocused: !active })}
										style={{ height: `${heightPx}px` }}
									>
										<Card
											className={classnames({
												multiCard,
												hideSlowly: !active && takeover,
												fullscreen: fullscreenCards[i] && isActive,
												revealed:   revealedIds[id],
												smooth:     transitions && fullscreenCards[i],
											})}
											style={{
												height: `${
													fullscreenCards[i] && isActive && multiCard && screenHeightPx >= document.documentElement.scrollHeight
														? screenHeightPx
														: baseCardHeightPx
												}px`,
												width: `${cardWidths[i]}px`,
											}}
											centerCardDiffPx={
												fullscreenCards[i] && isActive && multiCard && screenHeightPx >= document.documentElement.scrollHeight
													? centerDiff
													: 0
											}
											multiCard={multiCard}
										>
											{CardComponent({
												...cardConfig,
												active,
												baseCardHeightPx,
												baseCardWidthPx,
												cardHeightPx,
												multiCard,
												onChangeCard,
												onChangeCardIndex,
												onHideCard,
												cardWidthPx: cardWidths[i],
												takeover:    active && takeover,
												onBack:      i === 0
													? undefined
													: (): void => onChangeCardRaw(cards[i - 1].id.current),
												onForward: i === cards.length - 1
													? undefined
													: (): void => onChangeCardRaw(cards[i + 1].id.current),
												pagination: !pagination && (pagination !== undefined || !paginationType)
													? null
													: (
														<PaginationContainer documentHeight={process.browser ? document.body.scrollHeight : 0}>
															<Pagination
																className={classnames({ hide: !isActive })}
																footerHeight={multiCard ? footerHeight : 0}
																multiCard={multiCard}
															>
																{
																	cards
																		.filter(({
																			_type: paginationType,
																			card: { paginationIcon } = {},
																		}) => {
																			const { [paginationType]: { card: { paginationIcon: cardPaginationIcon = false } = {} } = {} } = typeConfig;

																			return paginationIcon || (paginationIcon === undefined && cardPaginationIcon);
																		})
																		.map(({
																			_type: paginationType,
																			id: { current: paginationId },
																			card: { paginationIcon: paginationIconRaw } = {},
																		}) => {
																			const { [paginationType]: { card: { paginationIcon: cardPaginationIcon = false } = {} } = {} } = typeConfig;
																			const paginationIcon = (paginationIconRaw || (paginationIconRaw === undefined && cardPaginationIcon))!;

																			return (
																				<PaginationIndicator key={paginationId} className={classnames({ unfocused: id !== paginationId })}>
																					{
																						isBoolean(paginationIcon)
																							? <DotIndicator />
																							: isString(paginationIcon)
																								? <ImageIndicator alt="Pagination Icon" src={paginationIcon} />
																								: <ImageIndicator alt={paginationIcon.alt} src={imageBuilder.image(paginationIcon).url()!} />
																					}
																				</PaginationIndicator>
																			);
																		})
																}
															</Pagination>
														</PaginationContainer>
													),
											})}
										</Card>
									</Slide>
								</SanityThemeProvider>
							)}
						</SwiperSlide>
					);
				})}
			</SwiperUncontrolled>
		</Container>
	);
};

export default Cards;
