import type { Account, CartCard, ContentCard, Drop, ProductCard, PurchaseCard, RegisterCard, DrawCard, SanityKeyed, ShippingCard } from '@drop-party/sanity';
import type { CreatePaymentMethodCardData } from '@stripe/stripe-js';
import classnames from 'classnames';
import { filter, find, keyBy, omit, some } from 'lodash/fp';
import { useRouter } from 'next/router';
import React, { memo, useCallback, useEffect, useState, useMemo } from 'react';
import type { FunctionComponent, ReactElement } from 'react';
import { createPortal } from 'react-dom';
import { useAsync } from 'react-use';
import HttpError from 'standard-http-error';
import styled, { css } from 'styled-components';

import AutoSubmit from 'components/AutoSubmit';
import Cards, { useCardSizes } from 'components/Cards';
import type { CardComponentRenders, CardRenderProps } from 'components/Cards';
import type { Props as CartProps } from 'components/Cart';
import { ConnectedCart as Cart, cardTypeConfig as cartCardConfig } from 'components/Cart';
import Content, { cardTypeConfig as contentCardConfig } from 'components/Content';
import type { Props as ContentProps } from 'components/Content';
import { getCountdownString, useTimeUntil } from 'components/Countdown';
import type { Props as DrawProps } from 'components/Draw';
import Draw, { cardTypeConfig as drawCardConfig } from 'components/Draw';
import Favicon from 'components/Favicon';
import FooterRaw, { useFooterHeightPx } from 'components/Footer';
import HeaderRaw, { headerContentHeight, useHeaderHeightPx } from 'components/Header';
import Instructions, { nInstructions } from 'components/Instructions';
import Order, { cardTypeConfig as orderCardConfig } from 'components/Order';
import type { Props as OrderProps } from 'components/Order';
import { ConnectedProduct as Product, cardTypeConfig as productCardConfig } from 'components/Product';
import type { Props as ProductProps } from 'components/Product';
import { ConnectedPurchase as Purchase, cardTypeConfig as purchaseCardConfig } from 'components/Purchase';
import type { Props as PurchaseProps } from 'components/Purchase';
import type { ConnectedProps as ConnectedRegisterProps, Props as RegisterProps } from 'components/Register';
import { ConnectedRegister as Register } from 'components/Register';
import { ConnectedShipping as Shipping, cardTypeConfig as shippingCardConfig } from 'components/Shipping';
import type { ConnectedProps as ShippingProps } from 'components/Shipping';
import Value from 'components/Value';
import { hide } from 'components/styles';
import { SanityThemeProvider, basic } from 'components/themes';
import { getCardset, getCountryCodes } from 'models/drops/utils';
import type { LineItem, Order as OrderType, PotentialOrder } from 'models/orders';
import { usePaymentRequest } from 'models/orders/hooks';
import type { Ticket } from 'models/tickets';
import type { CreateOrderBody, CreateOrderResponse } from 'pages/api/v1/drops/[drop]/orders';
import type { CreatePotentialOrderBody, CreatePotentialOrderResponse } from 'pages/api/v1/drops/[drop]/potential-orders';
import type { GetTicketResponse } from 'pages/api/v1/drops/[drop]/tickets/[ticket]';
import type { ShippingAddress, UnArray } from 'utils';
import { contrast } from 'utils';
import type { ProductFiltered as ProductAnalytics } from 'utils/analytics';
import { addPaymentInfoEvent, addShippingInfoEvent, purchaseEvent, useAnalytics } from 'utils/analytics';
import { fetchJSON } from 'utils/fetch';
import { useLocalStorage, useUniqueVisitorBool, useWindowInnerSize } from 'utils/hooks';
import { useStripe } from 'vendors/stripe/hooks';

export const useDropPageSizes = (exposeMenu: boolean): {
	heightPx: number;
	multiCard: boolean;
	widthPx: number;
	spaceAroundCardDesktopVerticalPx: number;
} => {
	const { widthPx, heightPx: heightPxRaw } = useWindowInnerSize();
	const { cardsBreakpointPx, spaceAroundCardDesktopVerticalPx } = useCardSizes();
	const multiCard = widthPx >= cardsBreakpointPx;
	const footerHeightPx = useFooterHeightPx(widthPx);
	const headerHeightPx = useHeaderHeightPx();
	const heightPx = heightPxRaw - (
		!multiCard
			? exposeMenu ? headerHeightPx + (spaceAroundCardDesktopVerticalPx / 6) : 0
			: headerHeightPx + footerHeightPx
	);

	return {
		heightPx,
		multiCard,
		widthPx,
		spaceAroundCardDesktopVerticalPx,
	};
};

const PortalWrapper = styled.div``;

const showOnDesktop = css`
	${hide}
	display: none;
	padding-left: ${({ theme: { cardBleedPx, cardMarginHorizontalPx } }) => cardBleedPx + cardMarginHorizontalPx}px;
	padding-right: ${({ theme: { cardBleedPx, cardMarginHorizontalPx } }) => cardBleedPx + cardMarginHorizontalPx}px;

	&.show {
		display: flex;
	}
`;

const showOnMobile = css`
	display: flex;
	padding-left: ${({ theme: { cardBleedPx, cardMarginHorizontalPx } }) => cardBleedPx + cardMarginHorizontalPx}px;
	padding-right: ${({ theme: { cardBleedPx, cardMarginHorizontalPx } }) => cardBleedPx + cardMarginHorizontalPx}px;
`;

const Container = styled.div`
	${basic}
	overflow: hidden;
`;

const Header = styled(HeaderRaw)<{exposeMenu: boolean}>`
	${({ exposeMenu }) => (exposeMenu ? showOnMobile : showOnDesktop)}
`;

const Footer = styled(FooterRaw)`
	${showOnDesktop}
`;

const details = css`
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	width: fit-content;
	height: ${headerContentHeight}rem;
	justify-content: center;
	align-items: flex-start;
	font-size: 0.875rem;
	text-transform: uppercase;
	white-space: nowrap;
`;

const Details = styled.div`
	${details}
	color: ${({ theme: { secondaryColor } }) => secondaryColor};
`;

const DropOver = styled.div`
	${details}
	padding: 0 1rem;
	font-weight: 700;
	color: ${({ theme: { invertedColor } }) => invertedColor};
	background-color: ${({ theme: { emphasisColor } }) => emphasisColor};
`;

const Tagline = styled.span`
	font-size: 0.75rem;
`;

const Countdown = styled.div`
	font-weight: 800;
	color: ${({ theme: { color } }) => color};
`;

interface ConnectedComponentRenderProps {
	cartCard: Omit<CartProps, 'onApplePay' | 'onChangeLineItems' | 'onGooglePay'>;
	contentCard: ContentProps;
	drawCard: DrawProps;
	productCard: Omit<ProductProps, 'onChangeLineItems'> & { hasCart: boolean };
	purchaseCard: Omit<PurchaseProps<Pick<CreateOrderBody, 'email' | 'shippingAddress' | 'signature'>>, 'onSubmit' | 'stripe'>;
	shippingCard: Omit<ShippingProps, 'onSubmit'>;
	registerCard: Omit<RegisterProps, 'onResendCode' | 'onSubmitCode' | 'onSubmitPhoneNumber'> & {
		subscriberList: ConnectedRegisterProps['subscriberList'];
	};
	orderCard: OrderProps;
}

interface CardConfigRenderProps {
	cartCard: CartCard;
	contentCard: ContentCard;
	purchaseCard: PurchaseCard;
	shippingCard: ShippingCard;
	productCard: Omit<ProductCard, 'product'> & {
		product: ProductProps['product'];
	};
	drawCard: DrawCard;
	registerCard: Omit<RegisterCard, 'subscriberList'> & {
		subscriberList: ConnectedRegisterProps['subscriberList'];
	};
	orderCard: OrderProps;
}

// TODO Don't pass through all CardRenderProps, so memo-izing is more effective
type ConnectedCardComponentRenders = { [Type in keyof ConnectedComponentRenderProps]: (props: CardRenderProps & ConnectedComponentRenderProps[Type]) => ReactElement | null };

const typeConfig = {
	cartCard:     cartCardConfig,
	drawCard:     drawCardConfig,
	orderCard:    orderCardConfig,
	contentCard:  contentCardConfig,
	productCard:  productCardConfig,
	purchaseCard: purchaseCardConfig,
	shippingCard: shippingCardConfig,
};

const HeaderDetailsPure: FunctionComponent<{ tagline: string; until?: number | string }> = ({ tagline, until }) => {
	const time = useTimeUntil(until, 'biggest');

	return (
		<Details>
			<Tagline>
				{tagline}
			</Tagline>
			{time && (
				<Countdown>
					{getCountdownString(time)}
				</Countdown>
			)}
		</Details>
	);
};

const HeaderDetails = memo(HeaderDetailsPure);

type ProductFiltered = UnArray<CartProps['products']>;

export interface Props {
	card?: string;
	cardset?: string;
	firstVisit?: boolean;
	components: ConnectedCardComponentRenders;
	lineItems: LineItem[];
	onChangeCard: (card: string) => void;
	orderPage?: boolean;
	products: ProductFiltered[];
	ticket?: Ticket | null;
	drop: Pick<Drop, 'about' | 'cardsets' | 'custom' | 'header' | 'inventory' | 'label' | 'notices' | 'shipping' | 'slug' | 'socialMedia' | 'termsConditions'> & {
		account: Pick<Account, 'homepage' | 'domain'>;
		cards: (
			| SanityKeyed<CartCard>
			| SanityKeyed<ContentCard>
			| SanityKeyed<PurchaseCard>
			| SanityKeyed<ShippingCard>
			| SanityKeyed<Omit<ProductCard, 'product'> & { product: ProductProps['product'] }>
			| SanityKeyed<Omit<RegisterCard, 'subscriberList'> & { subscriberList: ConnectedRegisterProps['subscriberList'] }>
			| SanityKeyed<DrawCard>
		)[];
	};
}

export const DropPagePure: FunctionComponent<Props> = ({
	card,
	firstVisit,
	lineItems,
	onChangeCard,
	orderPage,
	products,
	ticket,
	cardset: cardsetId,
	components: componentsConnected,
	drop: {
		about,
		cardsets,
		custom,
		header,
		inventory,
		notices,
		shipping,
		socialMedia,
		termsConditions,
		cards: cardsArray,
		account: { homepage },
	},
}) => {
	const exposeMenu = useMemo(() => Boolean((about?.title?.trim().length && about.text) || termsConditions), [about, termsConditions]);
	const [email, setEmail] = useState<string>();
	const [potentialOrder, setPotentialOrder] = useState<PotentialOrder>();
	const [shippingAddress, setShippingAddress] = useState<ShippingAddress>();
	const [signature, setSignature] = useState<string>();
	const [takeover, onChangeTakeover] = useState(false);
	const [fullscreen, setFullscreen] = useState(false);
	const { widthPx: screenWidthPx, heightPx: screenHeightPx } = useWindowInnerSize();
	const { heightPx, multiCard, widthPx, spaceAroundCardDesktopVerticalPx } = useDropPageSizes(exposeMenu);

	const [instruction, setInstructions] = useState<number>(0);

	const inspectCart = useCallback(() => lineItems, [lineItems]);

	const { value: countryRegionData } = useAsync(async () => (await import('country-region-data')).default, []);

	const countries = useMemo((): string[] => (
		!countryRegionData
			? []
			: getCountryCodes(countryRegionData, shipping)
	), [countryRegionData, shipping]);

	const cardset = useMemo(() => (cardsetId ? find({ id: { current: cardsetId } }, cardsets) : undefined) ?? getCardset(Boolean(ticket))(cardsets), [cardsetId, cardsets, ticket]);

	const cardById = useMemo(() => keyBy(({ id: { current: id } }) => id, cardsArray), [cardsArray]);

	const cards = useMemo(() => (cardset?.cards ?? []).map((id) => cardById[id]), [cardById, cardset]);

	useEffect(() => setInstructions(firstVisit
		? Boolean(cards[0].card?.fullscreen) && multiCard
			? 1
			: 0
		: nInstructions), [cards, multiCard, firstVisit]);

	const components = useMemo((): CardComponentRenders<CardConfigRenderProps> => ({
		registerCard: componentsConnected.registerCard,
		cartCard:     ({ onChangeCard, ...props }) => componentsConnected.cartCard({
			...props,
			countries,
			custom,
			lineItems,
			notices,
			onChangeCard,
			products,
			onCheckout:  () => onChangeCard('shipping'),
			onStartOver: () => onChangeCard(cards[0].id.current),
		}),
		drawCard: ({ ...props }) => componentsConnected.drawCard({
			...props,
			countdownStartsAt: find({ id: { current: 'after-drop' } }, cardsets)?.startsAt,
			order:             {} as unknown as OrderType,
			products,
			socialMedia,
		}),
		contentCard: ({ countdownTo, ...props }) => componentsConnected.contentCard({
			...props,
			onChangeTakeover,
			takeover,
			countdownTo: !countdownTo
				? undefined
				: find({ id: { current: countdownTo } }, cardsets)?.startsAt,
		}),
		productCard: ({
			ctaCard,
			multiCard,
			onChangeCard,
			onForward,
			...props
		}) => componentsConnected.productCard({
			...props,
			inventory,
			onChangeCard,
			onChangeTakeover,
			onForward,
			inspectCart,
			takeover,
			hasCart: some({ _type: 'cartCard' }, cards),
			multiCard,
			onCTA:   !ctaCard ? onForward : () => onChangeCard(ctaCard),
		}),
		purchaseCard: ({ onChangeCard, ...props }) => componentsConnected.purchaseCard({
			...props,
			onChangeCard,
			potentialOrder,
			onCart:     () => onChangeCard('cart'),
			onShipping: () => onChangeCard('shipping'),
			children:   (
				<>
					<Value name="email" value={email} validate={(email): string | undefined => (email ? undefined : 'Required')} />

					<Value name="shippingAddress" value={shippingAddress} validate={(shippingAddress?: ShippingAddress): string | undefined => (shippingAddress ? undefined : 'Required')} />

					<Value name="signature" value={signature} validate={(signature): string | undefined => (signature ? undefined : 'Required')} />
				</>
			),
		}),
		shippingCard: ({ active, onChangeCard, ...props }) => componentsConnected.shippingCard({
			...props,
			active,
			countries,
			onChangeCard,
			children: (
				<>
					<Value name="lineItems" value={lineItems} validate={(lineItems) => (lineItems.length ? undefined : 'Required')} />

					<AutoSubmit hasSubmitSucceeded whenDirtySinceLastSubmit={['lineItems']} />
				</>
			),
			onSubmitting() {
				 setPotentialOrder(undefined);
				 setSignature(undefined);
			},
			onSubmitSuccess({ email, shippingAddress, potentialOrder, signature }) {
				setEmail(email);
				setShippingAddress(shippingAddress);
				setPotentialOrder(potentialOrder);
				setSignature(signature);

				if (!active) {
					return;
				}

				onChangeCard('purchase');
			},
		}),
		orderCard: componentsConnected.orderCard,
	}), [
		cards,
		cardsets,
		custom,
		email,
		componentsConnected,
		countries,
		inventory,
		lineItems,
		notices,
		inspectCart,
		onChangeTakeover,
		potentialOrder,
		products,
		socialMedia,
		shippingAddress,
		signature,
		takeover,
	]);

	return (
		<Container onClick={instruction < nInstructions ? () => setInstructions(nInstructions) : undefined}>
			<PortalWrapper>
				{(instruction < nInstructions) && (
					<Instructions
						instruction={instruction}
						multiCard={multiCard}
						fullscreen={Boolean(cards[0].card?.fullscreen)}
					/>
				)}
			</PortalWrapper>
			<Header
				exposeMenu={exposeMenu}
				href={homepage}
				image={header}
				about={about}
				socialMedia={socialMedia}
				spaceAroundCardDesktopVerticalPx={spaceAroundCardDesktopVerticalPx}
				multiCard={multiCard}
				termsConditions={termsConditions}
				fullscreen={fullscreen}
				screenHeightPx={screenHeightPx}
				className={classnames({
					hideSlowly: takeover,
					show:       multiCard,
				})}
			>
				{!orderPage && (
					cardset?.countdownHeader && cardset.countdownTo
						? (
							<HeaderDetails
								tagline={cardset.countdownHeader}
								until={find({ id: { current: cardset.countdownTo } }, cardsets)?.startsAt}
							/>
						)
						: cardset?.over
							? <DropOver>Drop is over</DropOver>
							: null
				)}
			</Header>

			<Cards<CardConfigRenderProps>
				card={card}
				cards={cards}
				components={components}
				heightPx={heightPx}
				multiCard={multiCard}
				onChangeCard={onChangeCard}
				takeover={takeover}
				typeConfig={typeConfig}
				setFullscreen={setFullscreen}
				screenWidthPx={screenWidthPx}
				screenHeightPx={screenHeightPx}
				widthPx={widthPx}
				instruction={instruction < nInstructions ? instruction : undefined}
				setInstructions={instruction < nInstructions ? setInstructions : undefined}
			/>

			<Footer
				className={classnames({
					hideSlowly: takeover,
					show:       multiCard,
				})}
			/>
		</Container>
	);
};

const DropPage = memo(DropPagePure);

const LoadingPage = styled.div`
	${hide};
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: ${({ theme: { bodyBackground } }) => bodyBackground};
	position: absolute;
	z-index: 10;
	overflow: hidden;
`;

const DropFireLoading = styled.object`
	width: 18rem;
	max-width: 50%;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	${({ theme: { bodyBackground } }) => (contrast(bodyBackground) === 'light' ? 'filter: brightness(0) saturate(100%) invert(100%)' : '')};
`;

export interface ConnectedProps {
	cardset?: string;
	products: (ProductFiltered & ProductAnalytics & UnArray<Parameters<typeof usePaymentRequest>[0]['products']>)[];
	ticket?: string | null;
	drop: Props['drop'] & Pick<Drop, '_id' | 'cardsets' | 'favicon' | 'slug' | 'theme'> & NonNullable<Parameters<typeof useAnalytics>[0]> & {
		account: Pick<Account, 'domain' | 'favicon'>;
		cards: Props['drop']['cards'] & Pick<UnArray<Drop['cards']>, 'hash' | 'id'>[];
	};
}

const ConnectedDropPagePure: FunctionComponent<ConnectedProps> = ({
	cardset,
	drop,
	products,
	ticket: ticketId,
	drop: {
		cards,
		cardsets,
		favicon,
		theme,
		_id: dropId,
		slug: { current: slug },
		account: {
			domain,
			favicon: accountFavicon,
		},
	},
}) => {
	const [loadingPage, setLoadingPage] = useState<boolean>(false);
	const { asPath, push, query } = useRouter();
	const card = useMemo((): string => {
		const hash = asPath.split('#')[1] ?? '';

		return find(({ id: { current: id }, hash: { current = id } = {} }) => current === hash, cards)?.id.current ?? '';
	}, [asPath, cards]);

	const baseUrl = `${`${!domain ? '' : `https://${domain}`}/${slug}`}`;

	const firstVisit = useUniqueVisitorBool();

	const onChangeCard = useCallback((id: string) => {
		if (loadingPage) {
			return;
		}

		const card = find({ id: { current: id } }, cards);
		const hash = (card?.hash ?? card?.id)?.current;
		const search = new URLSearchParams(omit(['domain', 'drop', 'host'], query) as Record<string, string>).toString();

		void push(`${`${baseUrl}${!search ? '' : `?${search}`}${!hash ? '' : `#${hash}`}`}`);
	}, [
		baseUrl,
		cards,
		loadingPage,
		push,
		query,
	]);

	const analytics = useAnalytics(drop);

	const { value: { ticket } = { ticket: undefined } } = useAsync(async () => (
		!ticketId
			? undefined
			: fetchJSON<GetTicketResponse>(`/api/v1/drops/${dropId}/tickets/${ticketId}`)
	), [dropId, ticketId]);

	const { intent: intentId } = ticket ?? { intent: undefined };
	useEffect(() => {
		if (!intentId) {
			return;
		}

		void analytics.identify(intentId, {
			displayName: intentId,
			drop:        dropId,
		});
	}, [analytics, dropId, intentId]);

	const [lineItemsRaw, setLineItems] = useLocalStorage<LineItem[]>(`line-items-for-${dropId}`, []);
	const onChangeLineItems = setLineItems;

	const cardsetProductsById = useMemo(() => {
		const cardsetArray = (cardset ? find({ id: { current: cardset } }, cardsets) : undefined)?.cards ?? getCardset(Boolean(ticket))(cardsets)?.cards;

		const productObjects = (cards
			.filter(({ id: { current }, _type }) => _type === 'productCard' && cardsetArray?.includes(current)) as unknown as ProductCard[])
			.map(({ product }) => product);

		return keyBy('_id', productObjects);
	}, [
		cards,
		cardset,
		cardsets,
		ticket,
	]);

	const lineItems = useMemo(() => filter<LineItem>(({ sku: { product } }) => Boolean(cardsetProductsById[product]), lineItemsRaw), [lineItemsRaw, cardsetProductsById]);

	const [custom, setCustom] = useState<Record<string, string>>();
	const onShippingAddress = useCallback(async ({ shippingAddress }: Pick<CreatePotentialOrderBody, 'shippingAddress'>): Promise<CreatePotentialOrderResponse> => {
		const result = await fetchJSON<CreatePotentialOrderResponse, CreatePotentialOrderBody>(`/api/v1/drops/${dropId}/potential-orders`, {
			method: 'POST',
			body:   {
				lineItems,
				shippingAddress,
				ticket: ticketId ?? undefined,
				...custom ? { custom } : {},
				...query.code ? { code: query.code as string } : {},
			},
		});

		await addShippingInfoEvent(analytics, result.potentialOrder, products);

		return result;
	}, [
		analytics,
		custom,
		dropId,
		lineItems,
		products,
		query.code,
		ticketId,
	]);

	const { stripe = null } = useStripe();

	const onPay = useCallback(async ({
		email,
		potentialOrder,
		shippingAddress,
		signature,
		paymentMethod: payment_method,
	}: {
		email: string;
		paymentMethod: CreatePaymentMethodCardData | string;
		potentialOrder: PotentialOrder;
		shippingAddress: ShippingAddress;
		signature: string;
	}): Promise<OrderType> => {
		setLoadingPage(true);

		if (!stripe) {
			setLoadingPage(false);

			throw new HttpError(HttpError.INTERNAL_SERVER_ERROR, 'Stripe Misconfigured');
		}

		const {
			order,
			stripeClientSecrets,
			order: { id: orderId },
		} = await fetchJSON<CreateOrderResponse, CreateOrderBody>(`/api/v1/drops/${dropId}/orders`, {
			method: 'POST',
			body:   {
				email,
				potentialOrder,
				shippingAddress,
				signature,
			},
		});

		await addPaymentInfoEvent(analytics, order, products);

		/* eslint-disable fp/no-loops, no-await-in-loop, no-restricted-syntax -- Need an asynchronous loop because stripe can't confirm multiple payments together */
		for (const stripeClientSecret of stripeClientSecrets) {
			const { error } = await stripe.confirmCardPayment(stripeClientSecret, { payment_method });

			if (error) {
				setLoadingPage(false);

				throw new HttpError(HttpError.INTERNAL_SERVER_ERROR, error.message ?? 'There was an error with your payment', error);
			}
		}
		/* eslint-enable fp/no-loops, no-await-in-loop, no-restricted-syntax -- Need an asynchronous loop because stripe can't confirm multiple payments together */

		await purchaseEvent(analytics, order, products);

		setLineItems([]);

		void push(`/${slug}/order/${orderId}`);

		return order;
	}, [
		analytics,
		dropId,
		products,
		push,
		setLineItems,
		slug,
		stripe,
	]);

	const onPaymentRequests = usePaymentRequest({
		lineItems,
		onPay,
		onShippingAddress,
		products,
		stripe,
	});

	const components = useMemo((): ConnectedCardComponentRenders => ({
		// TODO Don't pass through all CardRenderProps, so memo-izing is more effective
		/* eslint-disable react/display-name -- I don't need components, but eslint is getting confused about it */
		contentCard: (props) => <Content {...props} />,
		cartCard:    ({ onCheckout, ...props }) => (
			<Cart
				{...props}
				{...onPaymentRequests}
				analytics={analytics}
				onChangeLineItems={onChangeLineItems}
				onCheckout={(args) => {
					if (args && 'custom' in args) {
						setCustom(args.custom);
					}
					onCheckout?.(args);
				}}
			/>
		),
		productCard: ({ hasCart, ...props }) => (
			<Product
				{...props}
				analytics={analytics}
				onChangeLineItems={!hasCart ? undefined : onChangeLineItems}
			/>
		),
		drawCard:     (props) => <Draw {...props} />,
		purchaseCard: (props) => (
			<Purchase<Pick<CreateOrderBody, 'email' | 'shippingAddress' | 'signature'>>
				{...props}
				onSubmit={onPay}
				stripe={stripe}
			/>
		),
		registerCard: (props) => (
			<Register
				{...props}
				analytics={analytics}
				drop={dropId}
			/>
		),
		shippingCard: (props) => (
			<Shipping
				{...props}
				onSubmit={onShippingAddress}
			/>
		),
		orderCard: (props) => <Order {...props} />,
		/* eslint-enable react/display-name -- I don't need components, but eslint is getting confused about it */
	}), [
		analytics,
		dropId,
		onChangeLineItems,
		onPay,
		onPaymentRequests,
		onShippingAddress,
		stripe,
	]);

	return (
		<SanityThemeProvider globals theme={theme}>
			<Favicon favicon={favicon ?? accountFavicon} />

			<PortalWrapper>
				{process.browser && createPortal(
					<LoadingPage className={classnames({ hide: !loadingPage })}>
						<DropFireLoading
							type="image/svg+xml"
							data="/animation/drop-fire.svg"
						/>
					</LoadingPage>
					, document.querySelector('#__next')! || document.querySelector('#root') // eslint-disable-line @typescript-eslint/no-unnecessary-condition -- NEXT does not use #root
				)}
			</PortalWrapper>

			<DropPage
				card={card}
				cardset={cardset}
				components={components}
				drop={drop}
				firstVisit={firstVisit}
				lineItems={lineItems}
				onChangeCard={onChangeCard}
				products={products}
				ticket={ticket}
			/>
		</SanityThemeProvider>
	);
};

export const ConnectedDropPage = memo(ConnectedDropPagePure);
