import type { Blank, ContentCard, Drop, Product, SanityImageAsset, SanityImageCrop, SanityImageHotspot, SanityReference } from '@drop-party/sanity';
import { isEqual, isFunction, find, keyBy, sum, sumBy, filter } from 'lodash/fp';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useEvent } from 'react-use';

import type { Props as DropPageProps } from 'components/DropPage';
import type { Order as OrderType } from 'models/orders';

interface Size {
	heightPx: number;
	widthPx: number;
}

const defaultWindowInnerSize: Size = {
	heightPx: 798,
	widthPx:  1440,
};

const visitedKey = 'platform_visited_dp';

// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#testing_for_availability
const useLocalStorageAvailable = (): boolean => {
	const [localStorageAvailable, setLocalStorageAvailable] = useState(false);

	useEffect(() => {
		let storage;

		try {
			storage = window.localStorage;

			const x = '__storage_test__';
			storage.setItem(x, x);
			storage.removeItem(x);

			setLocalStorageAvailable(true);
		} catch (err) {
			setLocalStorageAvailable(Boolean(
				err instanceof DOMException
				&& (
					err.code === 22 // everything except Firefox
					|| err.code === 1014 // Firefox
					|| err.name === 'QuotaExceededError' // test name field too, because code might not be present everything except Firefox
					|| err.name === 'NS_ERROR_DOM_QUOTA_REACHED' // Firefox
				)
				// acknowledge QuotaExceededError only if there's something already stored
				&& (storage && storage.length !== 0)
			));
		}
	}, []);

	return localStorageAvailable;
};

export const useLocalStorage = <T extends any>(key: string, initialValue: T | (() => T)): [T, (valueOrFunc: T | ((oldValue: T) => T)) => void] => {
	const localStorageAvailable = useLocalStorageAvailable();
	const [value, setValue] = useState<T>(initialValue);

	useEffect(() => {
		if (!localStorageAvailable) {
			return;
		}
		const storedValue = localStorage.getItem(key);

		if (!storedValue) {
			return;
		}

		setValue(JSON.parse(storedValue));
	}, [key, localStorageAvailable]);

	return [
		value,
		useCallback((valueOrFunc) => {
			const newValue = isFunction(valueOrFunc) ? valueOrFunc(value) : valueOrFunc;

			setValue(newValue);

			if (!localStorageAvailable) {
				return;
			}

			if (isEqual(newValue, initialValue)) {
				localStorage.removeItem(key);
			} else {
				localStorage.setItem(key, JSON.stringify(newValue));
			}
		}, [
			initialValue,
			key,
			localStorageAvailable,
			value,
		]),
	];
};

export const useWindowInnerSize = (initialSize: Size = defaultWindowInnerSize): Size => {
	const [size, setSize] = useState<Size>(initialSize);

	const recalculate = useCallback(() => {
		setSize({
			heightPx: window.innerHeight,
			widthPx:  window.innerWidth,
		});
	}, []);

	useEffect(() => recalculate(), [recalculate]);
	useEvent('resize', recalculate);
	useEvent('orientationchange', recalculate);

	return size;
};

export const useUniqueVisitorBool = (): boolean => {
	const localStorageAvailable = useLocalStorageAvailable();

	const unique = useMemo(
		() => (localStorageAvailable
			? !localStorage.getItem(visitedKey)
			: true) // defaults to unique visitor
		, [localStorageAvailable]
	);

	useEffect(() => {
		if (localStorageAvailable) {
			localStorage.setItem(visitedKey, 'true');
		}
	}, [localStorageAvailable]);

	return unique;
};

export const useImageSize = (
	cardWidthPx?: number,
	cardHeightPx?: number
): ({ widthPx: number; heightPx: number } | undefined
) => useMemo(() => (
	cardHeightPx === undefined || cardWidthPx === undefined
		? undefined
		: {
			heightPx: Math.round(1.5 * cardHeightPx),
			widthPx:  Math.round(1.5 * cardWidthPx),
		}
), [cardHeightPx, cardWidthPx]);

export interface OrderDetailsData {
	images?: {
		icon: {
			_type: 'image';
			asset: SanityReference<SanityImageAsset>;
			crop?: SanityImageCrop;
			hotspot?: SanityImageHotspot;
		};
		alt: string;
	}[];
	lineItems: {
		quantity: number;
		sku: {
			product: string;
			variations: {
				[x: string]: string;
			};
		};
	}[];
	productById: {
		[key: string]: Pick<Product, '_id' | 'amount' | 'icon' | 'label'> & {
			blank?: Pick<Blank, 'variations'> | undefined;
		};
	};
}

type UseOrderDetailsData = (
	transactions: OrderType['transactions'],
	payments: OrderType['payments'],
	products: (Pick<Product, '_id' | 'amount' | 'icon' | 'label'> & { blank?: Pick<Blank, 'variations'> })[],
) => {
	lineItems: OrderDetailsData['lineItems'];
	replaceWith: string;
	images: OrderDetailsData['images'];
	paymentStatus: string;
	productById: OrderDetailsData['productById'];
};

export const useOrderDetailsData: UseOrderDetailsData = (transactions, payments, products) => {
	const productById = useMemo(() => keyBy('_id', products), [products]);

	const transactionItems = useMemo(() => payments.ids.map((id) => ({
		paymentStatus: payments.data[id].status,
		lineItems:     transactions.data[payments.data[id].transactionId].lineItems,
	})), [transactions.data, payments]);

	const paymentStatus = useMemo(() => {
		const statuses = new Set(transactionItems.map((e) => e.paymentStatus));
		if (statuses.has('succeeded')) {
			return 'succeeded';
		}
		if (statuses.has('requires_capture') || statuses.has('requires_payment_method')) {
			return 'requires_capture';
		}
		if (statuses.has('canceled')) {
			return 'canceled';
		}

		return 'requires_capture';
	}, [transactionItems]);

	const lineItems = useMemo(() => transactionItems.filter(
		(t) => {
			if (paymentStatus === 'succeeded') {
				return t.paymentStatus === 'succeeded';
			}
			if (paymentStatus === 'requires_capture') {
				return t.paymentStatus === 'requires_capture' || t.paymentStatus === 'requires_payment_method';
			}

			return t.paymentStatus === 'canceled';
		}
	).flatMap(
		(t) => t.lineItems
	), [paymentStatus, transactionItems]);

	const replaceWith = useMemo(() => lineItems.map(({ sku: { product } }) => productById[product].label).join(', '), [productById, lineItems]);

	const images = useMemo(() => lineItems.map(({ sku: { product } }) => ({
		icon: productById[product].icon,
		alt:  productById[product].label,
	})), [lineItems, productById]);

	return {
		lineItems,
		replaceWith,
		images,
		paymentStatus,
		productById,
	};
};

type UseOrderSummaryData = (transactions: OrderType['transactions']) => {
	duty?: number;
	shipping: number;
	subTotal: number;
	tax: number;
	total: number;
	discounts?: {
		amount: number;
		code: string;
	}[];
};

export const useOrderSummaryData: UseOrderSummaryData = (transactions) => {
	const orderTransactions = useMemo(() => transactions.ids.map((id) => {
		const {
			shipping,
			subTotal,
			tax,
			total,
			duty,
			discounts = [],
		} = transactions.data[id];

		return {
			shipping,
			subTotal,
			tax,
			total,
			duty,
			discounts,
		};
	}), [transactions.data, transactions.ids]);

	const summedData: ReturnType<UseOrderSummaryData> = useMemo(() => {
		const initialSum = {
			duty: orderTransactions.every((t) => t.duty === undefined)
				? undefined
				: sum(orderTransactions.map((t) => (t.duty === undefined ? 0 : t.duty))),
			shipping: sumBy('shipping', orderTransactions),
			subTotal: sumBy('subTotal', orderTransactions),
			tax:      sumBy('tax', orderTransactions),
		};

		return {
			...initialSum,
			total: (initialSum.duty ?? 0) + initialSum.shipping + initialSum.tax + initialSum.subTotal,
		};
	}, [orderTransactions]);

	return summedData;
};

type UseOrderPage = (
	cards: DropPageProps['drop']['cards'],
	cardsets: Drop['cardsets'],
) => {
	cards: DropPageProps['drop']['cards'];
	cardsets: Drop['cardsets'];
	drawCard: boolean;
	dropSecuredCard: boolean;
};

// @ts-expect-error -- pseudocard not from sanity
export const useOrderPage: UseOrderPage = (cardsRaw, cardsets) => {
	const dropSecuredCard = useMemo(
		() => find(({ id: { current } }) => current === 'drop-secured', cardsRaw),
		[cardsRaw]
	) as ContentCard | undefined;

	const drawCard = useMemo(() => find({ _type: 'drawCard' }, cardsRaw), [cardsRaw]);

	const cards = useMemo(() => filter(
		({ id: { current } }) => current !== 'drop-secured' && current !== 'draw'
		, cardsRaw
	), [cardsRaw]);

	return useMemo(() => ({
		cards: [
			...dropSecuredCard
				? [
					...cards,
					{
						...dropSecuredCard,
						ctas: dropSecuredCard.ctas?.map((cta, index) => ({
							...cta,
							card: index === 0
								? drawCard
									? 'draw'
									: 'order'
								: index === 1
									? 'shareDrop'
									: cta.card,
						})),
					},
				]
				: cards,
			{
				card: { hidden: Boolean(dropSecuredCard) },
				id:   {
					_type:   'id',
					current: 'order',
				},
				_type: 'orderCard',
			},
			...drawCard
				? [
					{
						...drawCard,
						card: {
							...drawCard.card,
							hidden: Boolean(dropSecuredCard),
						},
					},
				]
				: [],
		],
		cardsets: [
			...cardsets.slice(0, -1),
			{
				...cardsets[cardsets.length - 1],
				cards: [
					...cardsets[cardsets.length - 1].cards,
					...drawCard
						? dropSecuredCard ? ['drop-secured', 'draw'] : ['draw']
						: dropSecuredCard ? ['drop-secured', 'order'] : ['order'],
				],
			},
		],
		drawCard,
		dropSecuredCard,
	}), [
		cards,
		cardsets,
		drawCard,
		dropSecuredCard,
	]);
};
