import type { Drop } from '@drop-party/sanity';
import { capitalize, map } from 'lodash/fp';
import type { IconType } from 'react-icons';
import { SiInstagram, SiFacebook, SiTwitter, SiYoutube, SiTiktok, SiPinterest, SiSnapchat, SiDiscord, SiReddit, SiTwitch } from 'react-icons/si';
import type { StyledComponent, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components';
import styled from 'styled-components';

const socialMediaMappings: ({[key: string]: IconType}) = {
	twitter:   SiTwitter,
	instagram: SiInstagram,
	facebook:  SiFacebook,
	youtube:   SiYoutube,
	tiktok:    SiTiktok,
	pinterest: SiPinterest,
	snapchat:  SiSnapchat,
	discord:   SiDiscord,
	reddit:    SiReddit,
	twitch:    SiTwitch,
};

export interface SocialMediaData {
	icons: StyledComponent<IconType, DefaultTheme>[];
	links: string[];
	titles: string[];
}

export const getSocialMediaData = (socialMedia: Drop['socialMedia'], css: FlattenSimpleInterpolation | undefined): SocialMediaData => {
	const platforms = map('platform', socialMedia);
	const links = map('href', socialMedia);

	const socialMediaData: SocialMediaData = {
		links,
		titles: platforms.map((platform) => capitalize(platform)),
		icons:  platforms.map((platform) => {
			const Icon = socialMediaMappings[platform];

			const StyledIcon = styled(Icon)`
				${css ?? ''}
			`;

			return StyledIcon;
		}),
	};

	return socialMediaData;
};
