import type { Account, Drop, Product } from '@drop-party/sanity';
import { compact, flow, join, keyBy, map } from 'lodash/fp';
import React, { Fragment, useMemo } from 'react';
import type { FunctionComponent } from 'react';
import { useAsync } from 'react-use';
import styled from 'styled-components';

import type { CardTypeConfig } from 'components/Cards';
import { sectionHeader } from 'components/Cards/styles';
import LineItemUnstyled from 'components/LineItem';
import type { Props as LineItemProps } from 'components/LineItem';
import styles from 'components/OrderPage/OrderPage.module.scss';
import OrderSummary from 'components/OrderSummary';
import { basic } from 'components/themes';
import type { Order as OrderType, PaymentService } from 'models/orders';
import { getSkuName } from 'models/products';
import { formatCurrency } from 'utils';
import { getFormattedAddress } from 'utils/transforms';

export const cardTypeConfig: CardTypeConfig = { card: { hidden: false } };

const paymentMethodMap: { [service in PaymentService]?: { [method: string]: undefined | string } } = {
	stripe: {
		amex_express_checkout: 'AMERICAN EXPRESS',
		apple_pay:             'APPLE PAY',
		google_pay:            'GOOGLE PAY',
		masterpass:            'MASTERPASS',
		samsung_pay:           'SAMSUNG_PAY',
		visa_checkout:         'VISA CHECKOUT',
	},
};

const Container = styled.div`
	${basic}
`;

const Layout = styled.main`
	display: flex;
	flex-direction: column;
	margin: 0 auto;
`;

const LineItem = styled(LineItemUnstyled)`
	margin: 0 0 1rem;
`;

const ShippingSection = styled.div`
	border-top: 1px solid ${({ theme: { dividerColor } }) => dividerColor};
	padding-top: 0;
`;

const SectionHeader = styled.div`
	${sectionHeader}
`;

export interface Props {
	order: Pick<OrderType, 'createdAt' | 'email'| 'id' | 'payments' | 'shippingAddress' | 'transactions'>;
	products: (Pick<Product, '_id'> & LineItemProps['product'])[];
	drop: Pick<Drop, 'header' | 'label'> & {
		account: Pick<Account, 'homepage' | 'supportEmail'>;
	};
}

const Order: FunctionComponent<Props> = ({
	order,
	products,
	drop: {
		label,
		account: { supportEmail },
	},
	order: {
		createdAt,
		email,
		id,
		payments,
		shippingAddress,
		transactions,
		shippingAddress: { recipient },
	},
}) => {
	const {
		lineItems,
		total,
	} = transactions.data[transactions.ids[0]];

	const productById = useMemo(() => keyBy('_id', products), [products]);

	const { value: countryRegionData } = useAsync(async () => (await import('country-region-data')).default, []);

	const formattedAddress = useMemo(() => countryRegionData && getFormattedAddress(shippingAddress, countryRegionData), [countryRegionData, shippingAddress]);

	const paidWith = flow(
		map((id: string): string | undefined => {
			const { method, service } = payments.data[id];

			return method && paymentMethodMap[service]?.[method];
		}),
		compact,
		join(', ')
	)(payments.ids);

	return (
		<Container>
			<Layout>
				<div className={styles.container}>
					<section className={styles.hero}>
						<h1 className={styles.title}>
							Thanks for your order
							{' '}
							{recipient}
							!
						</h1>
						<p className={styles.subtitle}>
							We are currently processing your order! We’ll email
							{' '}
							<strong>
								{email}
							</strong>
							{' '}
							for any updates on your order. This can take up to 5-10 business days.
						</p>
						<h3 className={styles.titleTotal}>
							Total:
							{' '}
							{formatCurrency(total / 100)}
						</h3>
						{paidWith && (
							<p className={styles.paidWith}>
								Paid with
								{' '}
								{paidWith}
							</p>
						)}
						<p className={styles.store}>
							<strong>
								{label}
							</strong>
						</p>
					</section>
					<section className={styles.receipt}>
						<h3 className={styles.receiptTitle}>Your Receipt</h3>
						{createdAt && (
							<p className={styles.receiptSmall}>
								<small>
									<strong>Date:</strong>
									{' '}
									{new Date(createdAt).toLocaleDateString()}
								</small>
							</p>
						)}
						<p className={styles.receiptSmall}>
							<small>
								<strong>Order ID:</strong>
								{' '}
								{id}
							</small>
						</p>
						<ul className={styles.receiptItems}>
							{lineItems.map((lineItem) => (
								<LineItem
									key={getSkuName(lineItem.sku)}
									lineItem={lineItem}
									product={productById[lineItem.sku.product]}
								/>
							))}
						</ul>
					</section>
					<OrderSummary order={order}>
						<ShippingSection>
							<SectionHeader>Shipping</SectionHeader>
							{formattedAddress?.map((parts, i) => {
								const line = parts.join(' ');

								return (
									<Fragment key={line}>
										{line}
										{i < formattedAddress.length - 1 && <br />}
									</Fragment>
								);
							})}
						</ShippingSection>
					</OrderSummary>
					<section className={styles.total}>
						{supportEmail && (
							<a
								className={styles.totalLink}
								href={`mailto:${supportEmail}?subject=Help with Order ${id}&body=Order ID:%0A${id}%0A%0A`}
							>
								Need help?
							</a>
						)}
					</section>
				</div>
			</Layout>
		</Container>
	);
};

export default Order;
