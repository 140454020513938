import type { Blank, Product as ProductType } from '@drop-party/sanity';
import BlockContent from '@sanity/block-content-to-react';
import type { AnalyticsInstance } from 'analytics';
import classnames from 'classnames';
import { flow, isEmpty, keyBy, mapValues, find } from 'lodash/fp';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { FunctionComponent, ReactNode, ReactElement as ReactElementType } from 'react';
import { createPortal } from 'react-dom';
import { Field, Form } from 'react-final-form';
import { useClickAway, useKeyPressEvent, usePageLeave } from 'react-use';
import styled, { keyframes } from 'styled-components';
import type { DefaultTheme } from 'styled-components';

import { serializers } from 'components/BlockContent/utils';
import { button } from 'components/Buttons';
import type { CardTypeConfig } from 'components/Cards';
import { cardInner } from 'components/Cards/styles';
import Collapse from 'components/Collapse';
import InfiniteMarquee from 'components/InfiniteMarquee';
import MediaStory from 'components/MediaStory';
import SKUSelector from 'components/SKUSelector';
import type { Props as SKUSelectorProps } from 'components/SKUSelector';
import { boxShadow, hide, reset, transition } from 'components/styles';
import type { LineItem } from 'models/orders';
import { consolidateLineItems } from 'models/orders/utils';
import type { SKU } from 'models/products';
import { getSkuName } from 'models/products';
import { contrast, formatCurrency } from 'utils';
import { addToCartEvent, viewItemEvent } from 'utils/analytics';
import type { ProductFiltered as ProductAnalytics } from 'utils/analytics';
import { useImageSize } from 'utils/hooks';

const Container = styled.div`
	${cardInner}

	.takeover {
		user-select: none;
	}
`;

const Pagination = styled.div`
	pointer-events: none;
	${hide}
`;

// TODO Product "Card" needs a new name
const Card = styled.div`
	${hide}
	${boxShadow}
	position: absolute;
	right: 1rem;
	bottom: 1rem;
	left: 1rem;
	z-index: 3;
	display: flex;
	flex-direction: column;
	padding: 1rem 0;
	cursor: pointer;
	background-color: ${({ theme: { surfaceBackground } }) => surfaceBackground};
	border: ${({ theme: { surfaceBorder } }) => surfaceBorder};
	border-radius: ${({ theme: { surfaceBorderRadius } }) => surfaceBorderRadius}px;
	overflow: clip;

	&.open,
	&.noOpen {
		cursor: inherit;
	}

	> * { /* stylelint-disable-line selector-max-universal -- Prevent Safari squishing elements */
		flex-shrink: 0;
	}
`;

const PortalWrapper = styled.div``;

const Index = styled.div<{marquee: boolean}>`
	${hide}
	position: absolute;
	top: ${({ marquee }) => (marquee ? '3.25rem' : '1.75rem')};
	left: 1rem;
	line-height: 1;
	color: #ffffff;
	pointer-events: none;
	user-select: none;
`;

const ProductSummary = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	order: 2;
	border-bottom: 0 solid #000000;
	padding: 0 1rem;
`;

const ProductTextContent = styled.div`
	overflow: hidden;
`;

const ProductLabel = styled.div`
	color: ${({ theme: { color } }) => color};
	font-weight: 700;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

const ProductTagline = styled.div`
	font-size: 0.625rem;
	color: ${({ theme: { secondaryColor } }) => secondaryColor};
`;

const ProductAmount = styled.div`
	color: ${({ theme: { color } }) => color};
	font-size: 0.75rem;
`;

const ProductAmountDetails = styled.span`
	color: ${({ theme: { secondaryColor } }) => secondaryColor};
`;

const ProductDescription = styled.div`
	max-height: 40vh;
	overflow-y: auto;
	font-size: 0.75rem;
	padding: 0 1rem;
`;

const ProductDetails = styled(Collapse)`
	order: 1;
`;

const ProductDetailsHeader = styled.div`
	font-weight: 700;
	padding: 0 1rem;
`;

const Divider = styled.div`
	height: 1rem;
	border-top: ${({ theme: { dividerColor, surfaceBorder } }) => (surfaceBorder === 'none' ? `2px solid ${dividerColor}` : surfaceBorder)};
	margin: 0 ${({ theme: { surfaceBorder } }) => (surfaceBorder === 'none' ? '1rem' : 0)};
`;

const PlusButton = styled.button`
	${reset}
	${hide}
	position: relative;
	width: 3.5rem;
	min-width: 3.5rem;
	height: 3.5rem;
	cursor: pointer;
	user-select: none;
	background-color: ${({ theme: { buttonBackground } }) => buttonBackground};
	background-image: url('/plus-${({ theme: { buttonBackground } }) => contrast(buttonBackground)}.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: 1.5rem;
	transition-property: box-shadow, opacity;
	${({ theme: { surfaceBorder } }) => (
		surfaceBorder === 'none'
			? `
				border-radius: 50%;
			`
			: `
				margin: -1rem -1rem;
				height: 5.5625rem;
				border-left: ${surfaceBorder};
			`
	)}

	@media (hover: hover) and (pointer: fine) {
		${Card}:hover & {
			${boxShadow}
			background-color: ${({ theme: { buttonEmphasisBackground } }) => buttonEmphasisBackground};
		}
	}
`;

const TextButton = styled.button`
	${reset}
	${hide}
	position: absolute;
	top: -0.5rem;
	right: -0.5rem;
	padding: 0.5rem 1.5rem;
	font-size: 0.75rem;
	font-weight: 700;
	cursor: pointer;
	color: ${({ theme: { color } }) => color};

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			color: ${({ theme: { emphasisColor } }) => emphasisColor};
		}
	}
`;

// Buy margin-left: -4px and BuyInner padding-left: 4px are for Colorful SKUSelectors to have padding to bleed into

const Buy = styled(Collapse)`
	order: 3;
	margin-left: -4px;
`;

const BuyInner = styled.form`
	padding-top: 0.5rem;
	padding-left: 4px;
	margin-top: 0.5rem;
`;

const SKUSelectorContainer = styled.div`
	padding: 0 1rem 0.75rem;
`;

const animationDuration = 150;
const animationPause = 1000;
const animationTotalDuration = (2 * animationDuration) + animationPause;

const submitSucceededButton = ({
	buttonBackground,
	buttonBorderColor,
	buttonColor,
	buttonEmphasisBackground,
	buttonEmphasisBorderColor,
	buttonEmphasisColor,
	buttonSubmitSucceededBackground,
	buttonSubmitSucceededBorderColor,
	buttonSubmitSucceededColor,
}: DefaultTheme) => keyframes`
	/* stylelint-disable declaration-empty-line-before,declaration-block-semicolon-newline-after,order/order -- Styled-Components interpolation */

	from {
		color: ${buttonEmphasisColor};
		background-color: ${buttonEmphasisBackground};
		border-color: ${buttonEmphasisBorderColor};
	}

	${(animationDuration / animationTotalDuration) * 100}% {
		color: ${buttonSubmitSucceededColor};
		background-color: ${buttonSubmitSucceededBackground};
		border-color: ${buttonSubmitSucceededBorderColor};
	}

	${((animationDuration + animationPause) / animationTotalDuration) * 100}% {
		color: ${buttonSubmitSucceededColor};
		background-color: ${buttonSubmitSucceededBackground};
		border-color: ${buttonSubmitSucceededBorderColor};
	}

	to {
		color: ${buttonColor};
		background-color: ${buttonBackground};
		border-color: ${buttonBorderColor};
	}
	/* stylelint-enable declaration-empty-line-before,declaration-block-semicolon-newline-after,order/order */
`;

const submitSucceededTrack = keyframes`
	/* stylelint-disable declaration-empty-line-before,declaration-block-semicolon-newline-after,order/order -- Styled-Components interpolation */

	from {
		transform: translate(0, 0);
	}

	${(animationDuration / animationTotalDuration) * 100}% {
		transform: translate(0, calc(-100% / 3));
	}

	${((animationDuration + animationPause) / animationTotalDuration) * 100}% {
		transform: translate(0, calc(-100% / 3));
	}

	to {
		transform: translate(0, calc(-200% / 3));
	}
	/* stylelint-enable declaration-empty-line-before,declaration-block-semicolon-newline-after,order/order */
`;

const SubmitButton = styled.button`
	${button}
	height: 2.849375rem;
	overflow: hidden;
	margin: 0 1rem;
	width: calc(100% - 2rem);

	&.submitSucceeded {
		animation: ${({ theme }) => submitSucceededButton(theme)} linear ${animationTotalDuration}ms both;
	}
`;

const SubmitTrack = styled.div`
	${transition}
	display: flex;
	flex-direction: column;
	height: ${3 * 2.849375}rem;
	transition-property: transform;

	/* stylelint-disable declaration-empty-line-before,declaration-block-semicolon-newline-after,rule-empty-line-before -- Styled-Components interpolation */

	${SubmitButton}.submitSucceeded & {
		transform: translate(0, calc(-100% / 3));
		animation: ${submitSucceededTrack} linear ${animationTotalDuration}ms both;
	}
	/* stylelint-enable declaration-empty-line-before,declaration-block-semicolon-newline-after,rule-empty-line-before */
`;

const SubmitMessage = styled.span`
	width: 100%;
	height: 100%;
`;

interface OneLimitCheckProps {
	product: {
		_id: string;
	};
	inspectCart: (
		() => LineItem[]
	);
}

interface MethodElementsProps {
	OneLimitCheck: {
		draw: (props: OneLimitCheckProps) => boolean;
		buy: (props: OneLimitCheckProps) => boolean;
	};
	SelectionSection: {
		draw: (cta: string) => ReactElementType;
		buy: (cta: string) => ReactElementType;
	};
}

/* eslint-disable react/display-name -- display names are not currently being used */
const methodElements: MethodElementsProps = {
	OneLimitCheck: {
		draw: ({ product: { _id }, inspectCart }) => Boolean(find({ sku: { product: _id } }, inspectCart())),
		buy:  () => false,
	},
	SelectionSection: {
		draw: (cta) => (
			<SubmitTrack>
				<SubmitMessage>Enter the Draw</SubmitMessage>
				<SubmitMessage>Added to Bag!</SubmitMessage>
				<SubmitMessage>
					{cta}
				</SubmitMessage>
			</SubmitTrack>
		),
		buy: (cta) => (
			<SubmitTrack>
				<SubmitMessage>Add to Bag</SubmitMessage>
				<SubmitMessage>Added!</SubmitMessage>
				<SubmitMessage>
					{cta}
				</SubmitMessage>
			</SubmitTrack>
		),
	},
};
/* eslint-enable react/display-name -- display names are not currently being used */

// const getMethodElement = (lookupElement: dynamicObject, methodOfPurchase: queryString): returnType => methodElements[lookupElement][methodOfPurchase];

export const cardTypeConfig: CardTypeConfig = {
	card: {
		pagination:     true,
		paginationIcon: true,
	},
};

export interface Props {
	active?: boolean;
	atEnd?: 'advance' | 'restart';
	cardWidthPx?: number;
	cardHeightPx?: number;
	cta?: string;
	index?: string;
	inventory?: { count: number; skuName: string }[];
	onBack?: () => void;
	onCTA?: () => void;
	onChangeLineItems?: (setLineItems: ((lineItems: LineItem[]) => LineItem[]), newLineItem: LineItem) => void;
	onChangeTakeover: (takeover: boolean) => void;
	onForward?: () => void;
	inspectCart: () => LineItem[];
	pagination?: ReactNode;
	scrim?: boolean;
	tagline?: string;
	takeover: boolean;
	product: Pick<ProductType, '_id' | 'amount' | 'amountDetails' | 'description' | 'media' | 'label'> & SKUSelectorProps['product'] & {
		blank?: Pick<Blank, 'variations'>;
	};
	marqueeText?: string;
	method: keyof MethodElementsProps[keyof MethodElementsProps];
}

export const ProductPure: FunctionComponent<Props> = ({
	active,
	index,
	inventory,
	cardWidthPx,
	cardHeightPx,
	onBack,
	onCTA,
	onChangeLineItems,
	inspectCart,
	onChangeTakeover,
	onForward,
	pagination,
	product,
	method = 'buy',
	marqueeText,
	scrim,
	tagline,
	takeover,
	active: buttons,
	active: play,
	active: preload,
	atEnd = 'restart',
	cta = 'Continue',
	product: {
		amount,
		amountDetails,
		description,
		media,
		label,
		_id:   productId,
		blank: { variations = [] } = {},
	},
}) => {
	const [slide, setSlide] = useState(0);
	const [openState, setOpenState] = useState<'details' | 'buy' | false>(false);

	const timeoutRef = useRef<number | undefined>();
	const cardRef = useRef<HTMLDivElement | null>(null);

	const initialValues = useMemo(() => ({
		sku: {
			product:    productId,
			variations: {},
		},
	}), [productId]);

	useEffect(() => {
		if (active) {
			return;
		}

		setOpenState(false);
	}, [active]);

	usePageLeave(() => {
		if (!openState) {
			return;
		}

		setOpenState(false);
	}, [openState] as never[]);

	useClickAway(cardRef, (e) => {
		if (!openState) {
			return;
		}

		setOpenState(false);

		e.preventDefault();
		e.stopPropagation();
		e.stopImmediatePropagation();
	});

	useKeyPressEvent('Escape', (e) => {
		if (!openState) {
			return;
		}

		setOpenState(openState === 'buy' || !onChangeLineItems ? false : 'buy');

		e.preventDefault();
		e.stopPropagation();
		e.stopImmediatePropagation();
	});

	const inventoryBySKU = useMemo(() => inventory && flow(
		keyBy(({ skuName }: { count: number; skuName: string }): string => skuName),
		mapValues(({ count }: { count: number; skuName: string }): number | undefined => count)
	)(inventory), [inventory]);

	const isSKUDisabled = useCallback((newSku): boolean => {
		if (!inventoryBySKU) {
			return false;
		}

		const inventoryCount = inventoryBySKU[getSkuName(newSku)];

		return inventoryCount === undefined || inventoryCount <= 0;
	}, [inventoryBySKU]);

	const imageSize = useImageSize(cardWidthPx, cardHeightPx);

	return (
		<Container
			className={classnames({ takeover })}
			onClick={(e) => {
				if (!openState || cardRef.current?.contains(e.target as Node)) {
					return;
				}

				setOpenState(false);

				e.preventDefault();
				e.stopPropagation();
			}}
		>
			{marqueeText?.trim().length && <InfiniteMarquee text={marqueeText} />}
			<MediaStory
				buttons={!openState && buttons}
				imageSize={imageSize}
				loop={atEnd === 'restart'}
				media={media}
				onBack={onBack}
				onChangeSlide={setSlide}
				onChangeTakeover={onChangeTakeover}
				onForward={onForward}
				pause={Boolean(openState)}
				play={play}
				preload={preload}
				scrim={scrim}
				slide={slide}
				takeover={takeover}
			/>

			<Card
				ref={cardRef}
				onMouseEnter={(): void => clearTimeout(timeoutRef.current)}
				className={classnames({
					hideSlowly: takeover,
					open:       openState,
					noOpen:     !onChangeLineItems && isEmpty(description),
				})}
				onClick={(): void => {
					if (openState) {
						return;
					}

					setOpenState(onChangeLineItems ? 'buy' : !isEmpty(description) ? 'details' : false);
				}}
				onMouseLeave={(): void => {
					clearTimeout(timeoutRef.current);

					timeoutRef.current = setTimeout((): void => setOpenState(false), 5000) as unknown as number;
				}}
			>
				<ProductSummary>
					<ProductTextContent>
						{tagline && (
							<ProductTagline>
								{tagline}
							</ProductTagline>
						)}
						<ProductLabel>
							{label}
						</ProductLabel>
						{onChangeLineItems && (
							<ProductAmount>
								{formatCurrency(amount / 100).replace(/\D00$/u, '')}
								{amountDetails && (
									<ProductAmountDetails>
										{' '}
										{amountDetails}
									</ProductAmountDetails>
								)}
							</ProductAmount>
						)}
					</ProductTextContent>

					<PlusButton className={classnames({ hide: openState })} type="button" />

					{!isEmpty(description) && (
						<TextButton
							className={classnames({ hide: openState === 'details' || !openState || !onChangeLineItems })}
							type="button"
							onClick={(): void => setOpenState('details')}
						>
							Details
						</TextButton>
					)}
				</ProductSummary>

				{!isEmpty(description) && (
					<ProductDetails fixBottom duration={375} open={openState === 'details'}>
						<ProductDetailsHeader>Details</ProductDetailsHeader>
						<ProductDescription>
							<BlockContent
								blocks={description!}
								serializers={serializers}
							/>
						</ProductDescription>

						<Divider />

						<TextButton
							type="button"
							onClick={(): void => setOpenState(onChangeLineItems ? 'buy' : false)}
						>
							Close
						</TextButton>
					</ProductDetails>
				)}

				{onChangeLineItems && (
					<Buy duration={375} open={openState === 'buy'}>
						<Form<{ sku: SKU }>
							initialValues={initialValues}
							onSubmit={({ sku }, form) => {
								if (methodElements.OneLimitCheck[method]({
									product,
									inspectCart,
								})) {
									onCTA?.();

									return;
								}
								const lineItem = {
									sku,
									quantity: 1,
								};
								onChangeLineItems(
									(lineItems) => consolidateLineItems([...lineItems, lineItem]),
									lineItem
								);

								form.change('sku', initialValues.sku);
							}}
							subscription={{
								dirty:               true,
								hasValidationErrors: true,
								submitSucceeded:     true,
								values:              true,
							}}
							render={({
								form,
								dirty,
								handleSubmit,
								hasValidationErrors,
								submitSucceeded,
							}): ReactNode => (
								<BuyInner onSubmit={handleSubmit}>
									<Divider />

									<SKUSelectorContainer>
										<Field<SKU>
											name="sku"
											subscription={{ value: true }}
											validate={({ variations: skuVariations }): string | undefined => (
												Object.keys(skuVariations).length < variations.length
													? `Missing ${variations.length - Object.keys(skuVariations).length} values`
													: undefined
											)}
											render={({ input }): ReactNode => (
												<SKUSelector
													{...input}
													isSKUDisabled={isSKUDisabled}
													product={product}
												/>
											)}
										/>
									</SKUSelectorContainer>

									<SubmitButton
										className={classnames({
											submitSucceeded: methodElements.OneLimitCheck[method]({
												product,
												inspectCart,
											}) || (!dirty && submitSucceeded),
										})}
										disabled={hasValidationErrors && (!onCTA || dirty || !submitSucceeded)}
										type="submit"
										onClick={(e): void => {
											if (!onCTA || dirty || !submitSucceeded) {
												return;
											}

											e.preventDefault();
											onCTA();
											form.reset();
										}}
									>

										{methodElements.SelectionSection[method](cta)}

									</SubmitButton>
								</BuyInner>
							)}
						/>
					</Buy>
				)}
			</Card>

			<PortalWrapper>
				{(pagination && process.browser) && createPortal(
					<Pagination className={classnames({ hideSlowly: takeover || openState })}>
						{pagination}
					</Pagination>
					, document.querySelector('#__next')! || document.querySelector('#root') // eslint-disable-line @typescript-eslint/no-unnecessary-condition -- NEXT does not use #root
				)}
			</PortalWrapper>

			{index && (
				<Index className={classnames({ hideSlowly: takeover })} marquee={Boolean(marqueeText)}>
					{index.padStart(2, '0')}
				</Index>
			)}
		</Container>
	);
};

const Product = memo(ProductPure);

interface ConnectedProps extends Props {
	analytics: AnalyticsInstance;
	product: ProductAnalytics & Props['product'];
}

const ConnectedProductPure: FunctionComponent<ConnectedProps> = ({
	active,
	analytics,
	product,
	onChangeLineItems: onChangeLineItemsRaw,
	...props
}) => {
	useEffect(() => {
		if (!active) {
			return;
		}

		void viewItemEvent(analytics, product);
	}, [active, analytics, product]);

	const onChangeLineItems = useCallback((setLineItems: ((lineItems: LineItem[]) => LineItem[]), lineItem: LineItem) => {
		if (!onChangeLineItemsRaw) {
			return;
		}

		void addToCartEvent(analytics, lineItem, product);

		onChangeLineItemsRaw(setLineItems, lineItem);
	}, [analytics, onChangeLineItemsRaw, product]);

	return (
		<Product
			{...props}
			active={active}
			onChangeLineItems={onChangeLineItemsRaw && onChangeLineItems}
			product={product}
		/>
	);
};

export const ConnectedProduct = memo(ConnectedProductPure);

export default Product;
