import type { Cardset, NoShipping, Shipping, SanityKeyed } from '@drop-party/sanity';
import type { Country } from 'country-region-data';
import { filter, find, findLast, flow, map, union, without } from 'lodash/fp';

type UpcomingCardset = SanityKeyed<Cardset> & Required<Pick<Cardset, 'message' | 'startsAt' | 'subscriberList'>>;

export const getCountryCodes = (
	countries: Country[],
	shipping: SanityKeyed<NoShipping | Shipping>[]
): string[] => {
	const allCountryCodes = map('countryShortCode', countries);

	const { excluded, included } = shipping.reduce(({ excluded, included }: { excluded: string[]; included: string[] }, shippingConfiguration): { excluded: string[]; included: string[] } => (
		shippingConfiguration._type === 'noShipping'
			? !shippingConfiguration.country
				? {
					excluded: allCountryCodes,
					included: [],
				}
				: {
					excluded: union([shippingConfiguration.country], excluded),
					included: without([shippingConfiguration.country], included),
				}
			: !shippingConfiguration.country
				? {
					excluded: [],
					included: allCountryCodes,
				}
				: {
					excluded: without([shippingConfiguration.country], excluded),
					included: union([shippingConfiguration.country], included),
				}
	), {
		excluded: [],
		included: [],
	});

	return !excluded.length ? included : without(excluded, included);
};

export const getCardset = (hasTicket: boolean): ((cardsets: SanityKeyed<Cardset>[]) => Cardset | undefined) => findLast<Cardset>(
	({ requiresRegistration, startsAt }) => (!requiresRegistration || hasTicket)
		&& (!startsAt || new Date(startsAt) <= new Date())
);

export const getUpcomingCardset: (cardsets: SanityKeyed<Cardset>[]) => UpcomingCardset | undefined = flow(
	filter((cardset: SanityKeyed<Cardset>): cardset is UpcomingCardset => cardset.message !== undefined && cardset.startsAt !== undefined && cardset.subscriberList?._ref !== undefined),
	find(({ startsAt }) => new Date() < new Date(startsAt))
);
