import type { ButtonTheme, ImageMeta, ImageMetaWithTheme, SanityBlock, SanityImage, SanityKeyed, VideoMetaWithTheme } from '@drop-party/sanity';
import BlockContent from '@sanity/block-content-to-react';
import classnames from 'classnames';
import { isEmpty } from 'lodash/fp';
import React, { memo, useMemo, useState } from 'react';
import type { FunctionComponent } from 'react';
import styled from 'styled-components';

import { serializers } from 'components/BlockContent/utils';
import { button } from 'components/Buttons';
import type { CardTypeConfig } from 'components/Cards';
import { cardInner } from 'components/Cards/styles';
import { ConnectedCountdown as CountdownUnstyled } from 'components/Countdown';
import MediaStoryUnstyled from 'components/MediaStory';
import { hide, positionAll0 } from 'components/styles';
import { SanityThemeProvider, basic } from 'components/themes';
import { useImageSize } from 'utils/hooks';
import { imageBuilder } from 'vendors/sanity/utils';

const Container = styled.div`
	${basic}
	${cardInner}
	color: ${({ theme: { color } }) => color};

	&.center {
		align-items: center;
	}

	&.left {
		align-items: flex-start;

		&.multiCard {
			padding-left: 3rem;
		}
	}
`;

/* stylelint-disable declaration-empty-line-before,declaration-block-semicolon-newline-after,order/order,rule-empty-line-before -- Styled-Components interpolation */

const Scrim = styled.div`
	${hide}
	${positionAll0}
	position: absolute;
	pointer-events: none;

	${Container}.center & {
		background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0%) 81.77%);
	}

	${Container}.left & {
		background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0%) 81.77%);
	}
`;

const MediaStory = styled(MediaStoryUnstyled)`
	${positionAll0}
	position: absolute;
`;

const ContentContainer = styled.div`
	${hide}
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 1rem;
	height: 100%;
	pointer-events: none;

	> * { /* stylelint-disable-line selector-max-universal -- Prevent Safari squishing elements */
		flex-shrink: 0;
	}
`;

const Upper = styled.div`
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 1;
`;

const Lower = styled.div`
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-end;

	> * { /* stylelint-disable-line selector-max-universal -- Prevent Safari squishing elements */
		flex-shrink: 0;
	}
`;

const Title = styled.div`
	padding: 0 2rem;
	text-align: center;
	font-size: 2rem;
	font-weight: 800;
	line-height: 1;
	margin-bottom: 0.5rem;

	${Container}.multiCard & {
		padding: 0;
	}
`;

const Header = styled.img`
	width: 100%;
`;

const Description = styled.div`
	padding: 0 2rem;
	font-size: 1rem;

	${Container}.multiCard & {
		padding: 0;
	}

	${Container}.center & {
		text-align: center;
	}

	${Container}.left & {
		text-align: left;
	}
`;

const CountdownHeader = styled.div`
	margin-bottom: 0.5rem;
	font-weight: 800;
	text-transform: uppercase;
	font-size: 0.75rem;

	${Container}.center & {
		align-self: center;
	}

	${Container}.left & {
		align-self: flex-start;
	}
`;

const Countdown = styled(CountdownUnstyled)`
	margin-bottom: 2rem;
	width: 100%;
`;

const Button = styled.button`
	${button}
	pointer-events: initial;
	z-index: 2;
	max-width: 100%;

	${Container}.left & {
		margin: 0;
	}

	& ~ & {
		margin-top: 1rem;
	}
`;

/* stylelint-enable declaration-empty-line-before,declaration-block-semicolon-newline-after,order/order,rule-empty-line-before */

export const cardTypeConfig: CardTypeConfig = {
	theme: {
		buttonBackground:  'transparent',
		buttonBorderColor: { hex: '#ffffff' },
		buttonColor:       { hex: '#ffffff' },
		color:             { hex: '#ffffff' },
	},
};

export interface Props {
	active?: boolean;
	align?: 'center' | 'left';
	atEnd?: 'advance' | 'restart';
	baseCardWidthPx: number;
	cardHeightPx?: number;
	cardWidthPx?: number;
	countdownHeader?: string;
	countdownTo?: string;
	description?: SanityKeyed<SanityBlock>[];
	header?: ImageMeta;
	media: SanityKeyed<Omit<ImageMetaWithTheme | VideoMetaWithTheme, 'mobile'> & { mobile?: SanityImage }>[];
	multiCard: boolean;
	onBack?: () => void;
	onChangeCard: (card: string) => void;
	onChangeTakeover: (takeover: boolean) => void;
	onForward?: () => void;
	onShare?: () => void;
	scrim?: boolean;
	shareText?: string;
	takeover: boolean;
	ctas?: SanityKeyed<{
		card?: string;
		href?: string;
		text: string;
		theme?: ButtonTheme;
	}>[];
}

export const ContentPure: FunctionComponent<Props> = ({
	baseCardWidthPx,
	cardHeightPx,
	cardWidthPx,
	countdownHeader,
	countdownTo,
	header,
	multiCard,
	onBack,
	onChangeCard,
	onChangeTakeover,
	onForward,
	onShare,
	shareText,
	scrim,
	takeover,
	active: buttons,
	active: play,
	active: preload,
	media: mediaRaw,
	takeover: hideSlowly,
	align = 'center',
	atEnd = 'restart',
	ctas = [],
	description = [],
}) => {
	const [slide, setSlide] = useState(0);
	const [pause, setPause] = useState(false);

	const media = useMemo(() => mediaRaw.map(({ mobile, ...image }) => ({
		...image,
		...multiCard || !mobile
			? image
			: mobile,
	})), [mediaRaw, multiCard]);

	const imageSize = useImageSize(cardWidthPx, cardHeightPx);

	return (
		<SanityThemeProvider globals theme={media[slide].theme}>
			<Container
				className={classnames({
					multiCard,
					[align]: true,
				})}
			>
				<MediaStory
					buttons={buttons}
					imageSize={imageSize}
					loop={atEnd === 'restart'}
					media={media}
					onBack={onBack}
					onChangeSlide={setSlide}
					onChangeTakeover={onChangeTakeover}
					onForward={onForward}
					pause={pause}
					play={play}
					preload={preload}
					scrim={scrim}
					slide={slide}
					takeover={takeover}
				/>

				{(scrim || (scrim === undefined && (!isEmpty(description) || countdownTo))) && <Scrim className={classnames({ hideSlowly })} />}

				<ContentContainer className={classnames({ hideSlowly })} style={{ maxWidth: `${baseCardWidthPx}px` }}>
					<Upper />
					{header && (
						<Title>
							<Header alt={header.alt} src={imageBuilder.image(header).url()!} />
						</Title>
					)}
					{!isEmpty(description) && (
						<Description>
							<BlockContent
								blocks={description}
								serializers={serializers}
							/>
						</Description>
					)}
					<Lower>
						{countdownTo !== undefined && (
							<>
								{countdownHeader && (
									<CountdownHeader>
										{countdownHeader}
									</CountdownHeader>
								)}
								<Countdown until={countdownTo} />
							</>
						)}
						{!isEmpty(ctas) && ctas.map((cta) => (
							<SanityThemeProvider key={cta._key} theme={cta.theme}>
								{
								cta.card === 'shareDrop'
									? (
										<Button onClick={() => onShare?.()} onMouseEnter={() => setPause(true)} onMouseLeave={() => setPause(false)}>
											{shareText ?? cta.text}
										</Button>
									)
									: 'card' in cta
										? (
											<Button onClick={(): void => onChangeCard(cta.card!)} onMouseEnter={() => setPause(true)} onMouseLeave={() => setPause(false)}>
												{cta.text}
											</Button>
										)
										: 'href' in cta
											? (
												<Button as="a" href={cta.href} target="_blank" rel="noreferrer" onMouseEnter={() => setPause(true)} onMouseLeave={() => setPause(false)}>
													{cta.text}
												</Button>
											)
											: onForward
												? (
													<Button onClick={(): void => onForward()} onMouseEnter={() => setPause(true)} onMouseLeave={() => setPause(false)}>
														{cta.text}
													</Button>
												)
												: null
								}
							</SanityThemeProvider>
						))}
					</Lower>
				</ContentContainer>
			</Container>
		</SanityThemeProvider>
	);
};

const Content = memo(ContentPure);

export default Content;
