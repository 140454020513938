import { css } from 'styled-components';

import { boxShadow, reset, transition } from 'components/styles';
import { contrast } from 'utils';

const borderNotPlaceholderShown = css`
	&:not(&:placeholder-shown) {
		border-color: ${({ theme: { inputDirtyBorderColor } }) => inputDirtyBorderColor};
	}
`;

export const field = css`
	${reset}
	${transition}
	display: inline-block;
	padding: 0.75rem 0.75rem;
	background: ${({ theme: { inputBackground } }) => inputBackground};
	color: ${({ theme: { inputColor } }) => inputColor};
	transition-property: border-color, box-shadow, color;
	min-height: calc(3rem + 2px);

	&::placeholder {
		color: ${({ theme: { inputPlaceholderColor } }) => inputPlaceholderColor};
	}
`;

export const border = css`
	border: 1px solid ${({ theme: { inputBorderColor } }) => inputBorderColor};
	border-radius: 4px;

	&.dirty {
		border-color: ${({ theme: { inputDirtyBorderColor } }) => inputDirtyBorderColor};
	}
`;

export const disabled = css`
	color: #828282;
	cursor: not-allowed;
	user-select: none;
	border-color: #828282;
	box-shadow: none;

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			color: #828282;
			cursor: not-allowed;
			border-color: #828282;
			box-shadow: none;
		}
	}
`;

export const focus = css`
	margin: 1px;
	border-width: 1px;

	&:focus-within {
		margin: 0;
		border-color: ${({ theme: { inputEmphasisBorderColor } }) => inputEmphasisBorderColor};
		border-width: 2px;
		outline: none;
		box-shadow: none;
	}

	&:active {
		border-color: ${({ theme: { inputEmphasisBorderColor } }) => inputEmphasisBorderColor};
		box-shadow: none;
	}
`;

export const hover = css`
	@media (hover: hover) and (pointer: fine) {
		&:hover {
			${boxShadow}
			border-color: ${({ theme: { inputEmphasisBorderColor } }) => inputEmphasisBorderColor};

			&:invalid {
				${boxShadow}
			}
		}
	}
`;

export const invalid = css`
	&:invalid {
		box-shadow: none;
	}

	&:user-invalid {
		border-color: ${({ theme: { errorColor } }) => errorColor};
	}

	&.touched {
		&:invalid {
			&:invalid {
				&:invalid {
					border-color: ${({ theme: { errorColor } }) => errorColor};
				}
			}
		}
	}
`;

export const input = css`
	${field}
	${border}
	${borderNotPlaceholderShown}
	${focus}
	${hover}
	${invalid}

	&:disabled {
		&:disabled {
			${disabled}
		}
	}
`;

export const selectField = css`
	${field}
	padding-right: 2rem;
	cursor: pointer;
	background-image: url('/select-arrow-${({ theme: { inputBackground } }) => contrast(inputBackground)}.svg');
	background-repeat: no-repeat;
	background-position: right 0.5rem center;

	&:disabled {
		background-image: none;
	}

	@-moz-document url-prefix() {
		padding-left: calc(0.5rem);
	}
`;

export const select = css`
	${selectField}
	${border}
	${borderNotPlaceholderShown}
	${focus}
	${hover}
	${invalid}

	&:disabled {
		&:disabled {
			${disabled}
		}
	}
`;

export const withLabelContainer = css`
	position: relative;
`;

export const withLabel = css`
	padding-top: 1.5rem;
	padding-bottom: 0.5rem;
	min-height: calc(3.5rem + 2px);
`;

export const label = css`
	${transition}
	position: absolute;
	top: 0;
	left: 0;
	padding-top: calc(1.25rem + 2px);
	padding-left: calc(0.75rem + 2px);
	line-height: 1;
	color: ${({ theme: { inputPlaceholderColor } }) => inputPlaceholderColor};
	pointer-events: none;
	transition-property: color, font-size, padding-top;

	/* Cant directly select withLabel */

	/* stylelint-disable selector-max-universal -- Styled-Components interpolation */

	*:focus-within + &,
	*:not(*:placeholder-shown) + & {
		padding-top: calc(0.75rem + 2px);
		font-size: 0.75rem;
		color: ${({ theme: { secondaryColor } }) => secondaryColor};
	}

	*:-webkit-autofill + & {
		color: #787878;
	}
	/* stylelint-enable selector-max-universal */
`;
