import type { ButtonTheme, SanityBlock, SanityKeyed, SubscriberList, Theme } from '@drop-party/sanity';
import BlockContent from '@sanity/block-content-to-react';
import type { AnalyticsInstance } from 'analytics';
import classnames from 'classnames';
import type { Decorator, SubmissionErrors } from 'final-form';
import createFocusDecorator from 'final-form-focus';
import firebase from 'firebase/app';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import { isEmpty, partition } from 'lodash/fp';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import type { FunctionComponent, ReactNode } from 'react';
import type { FieldRenderProps } from 'react-final-form';
import { Form } from 'react-final-form';
import { Field } from 'react-final-form-html5-validation';
import { SiTwitter, SiInstagram, SiDiscord, SiYoutube } from 'react-icons/si';
import styled, { css } from 'styled-components';

import { serializers } from 'components/BlockContent/utils';
import { button } from 'components/Buttons';
import { input } from 'components/Fields';
import TelephoneInputUnstyled from 'components/TelephoneInput';
import { reset, transition } from 'components/styles';
import { SanityThemeProvider, basic } from 'components/themes';
import type { Intent } from 'models/intents';
import type { CreateIntentBody } from 'pages/api/v1/subscriber-lists/[subscriberList]/intents';
import type { CreateMultiFactorAuthenticationResponse } from 'pages/api/v1/subscriber-lists/[subscriberList]/intents/[intent]/mfa';
import { contrast } from 'utils';
import { fetchJSON } from 'utils/fetch';
import { reportValidity, reportValiditySubscription } from 'utils/forms';
import { useSentry } from 'vendors/sentry/hooks';

export const verificationCodeLength = 6;

const Container = styled.div`
	${basic};
	background: ${({ theme: { surfaceBackground } }) => surfaceBackground};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 1.5rem 0;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	width: 100%;

	> * { /* stylelint-disable-line selector-max-universal -- Prevent Safari squishing elements */
		flex-shrink: 0;
	}
`;

const Track = styled.div`
	${transition}
	display: flex;
	align-items: flex-end;
	flex-grow: 1;
	width: 300%;
	transition-property: transform;
	transition-duration: 500ms;

	&.phoneNumber {
		transform: translate(calc(-100% / 3), 0);
	}

	&.verified {
		transform: translate(calc(-200% / 3), 0);
	}

	> * { /* stylelint-disable-line selector-max-universal -- Prevent Safari squishing elements */
		width: calc(100% / 3);
		padding: 0 1.5rem;
	}
`;

const screen = css`
	display: flex;
	flex-direction: column;

	> * { /* stylelint-disable-line selector-max-universal -- Prevent Safari squishing elements */
		flex-shrink: 0;
	}
`;

const Screen = styled.fieldset`
	${screen}
	align-items: stretch;
`;

const ScreenDescription = styled.div`
	font-size: 0.875rem;
	color: ${({ theme: { secondaryColor } }) => secondaryColor};
`;

const Strong = styled.strong`
	color: ${({ theme: { color } }) => color};
`;

const inputMarginBottom = css`
	margin-bottom: 1rem;

	&:focus-within {
		margin-bottom: calc(1rem - 1px);
	}
`;

const TelephoneInput = styled(TelephoneInputUnstyled)`
	${inputMarginBottom}
`;

const ResendButton = styled.button`
	${reset}
	font-weight: 700;
	cursor: pointer;

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			color: ${({ theme: { color } }) => color};
		}
	}
`;

const Input = styled.input`
	${input}
	${inputMarginBottom}
	text-align: center;
`;

const SubmitButton = styled.button`
	${button}
`;

const VerifiedScreen = styled.div`
	${screen}
	align-self: stretch;
	align-items: center;
	text-align: center;

	&::before {
		flex-basis: 0;
		flex-grow: 1;
		flex-shrink: 0;
		content: '';
	}

	> * { /* stylelint-disable-line selector-max-universal -- Prevent Safari squishing elements */
		flex-shrink: 0;
	}
`;

const VerifiedScreenBottom = styled.div`
	display: flex;
	flex-direction: column;
	align-self: stretch;
	justify-content: flex-end;
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 0;
`;

const Button = styled.button`
	${button}
	margin-top: 1rem;
`;

const DropFireImage = styled.img`
	width: 40%;
	margin-bottom: 2rem;
	content: url('/drop-fire-${({ theme: { bodyBackground } }) => contrast(bodyBackground)}.svg');
`;

const HorizontalRuleContainer = styled.div`
	width: 100%;
	display: inline-block;
	font-size: 0;
	vertical-align: top;
	height: 1rem;
	margin-top: 0.5rem;
`;

const HorizontalRule = styled.div`
	width: 25%;
	height: 25%;
	border-top: 2px #000000 solid;
	display: inline-block;
`;

const TextInHorizontalRule = styled.p`
	margin: 0;
	height: 1rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
	width: 50%;
	pointer-events: none;
	display: inline-block;
`;

const SocialMediaContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 1.5rem;
`;

const socialMedia = css`
	transform: scale(1.4);
	margin: 0 0.875rem;
`;

const SocialLink = styled.a`
	color: #000000;
`;

const Instagram = styled(SiInstagram)`
	${socialMedia}
`;

const Twitter = styled(SiTwitter)`
	${socialMedia}
`;

const Discord = styled(SiDiscord)`
	${socialMedia}
`;

const Youtube = styled(SiYoutube)`
	${socialMedia}
`;

const defaultDescription: SanityKeyed<SanityBlock>[] = [
	{
		_key:     'h3key',
		_type:    'block',
		markDefs: [],
		style:    'h3',
		children: [
			{
				_key:  'registerwithkey',
				_type: 'span',
				marks: [],
				text:  'Register with your digits',
			},
		],
	},
	{
		_key:     'normalkey',
		_type:    'block',
		markDefs: [],
		style:    'normal',
		children: [
			{
				_key:  'normalspankey',
				_type: 'span',
				marks: [],
				text:  'We’ll send you quick and simple updates via text. ',
			},
			{
				_key:  'normalstrongkey',
				_type: 'span',
				marks: ['strong'],
				text:  'Standard messaging rates apply.',
			},
		],
	},
];

const defaultVerifyDescription: SanityKeyed<SanityBlock>[] = [
	{
		_key:     'h3key',
		_type:    'block',
		markDefs: [],
		style:    'h3',
		children: [
			{
				_key:  'registerwithkey',
				_type: 'span',
				marks: [],
				text:  'Verify your number',
			},
		],
	},
	{
		_key:     'normalkey',
		_type:    'block',
		markDefs: [],
		style:    'normal',
		children: [
			{
				_key:  'normalspankey',
				_type: 'span',
				marks: [],
				text:  'Enter the verification code we texted to ',
			},
			{
				_key:  'normalstrongkey2',
				_type: 'span',
				marks: ['code'],
				text:  '{PHONE}',
			},
			{
				_key:  'normalspankey3',
				_type: 'span',
				marks: [],
				text:  '.',
			},
		],
	},
];

const defaultVerifiedDescription: SanityKeyed<SanityBlock>[] = [
	{
		_key:     'h3key',
		_type:    'block',
		markDefs: [],
		style:    'h3',
		children: [
			{
				_key:  'registerwithkey',
				_type: 'span',
				marks: [],
				text:  'You’re in!',
			},
		],
	},
	{
		_key:     'normalkey',
		_type:    'block',
		markDefs: [],
		style:    'normal',
		children: [
			{
				_key:  'normalspankey',
				_type: 'span',
				marks: [],
				text:  'Updates will be sent to ',
			},
			{
				_key:  'normalstrongkey2',
				_type: 'span',
				marks: ['code'],
				text:  '{PHONE}',
			},
			{
				_key:  'normalspankey3',
				_type: 'span',
				marks: [],
				text:  '. Don’t worry. We only send stuff you’ll want to know.',
			},
		],
	},
];

interface SlideProps {
	description?: SanityKeyed<SanityBlock>[];
	theme?: Theme;
}

interface CommonProps {
	title?: string;
	onChangeCard: (card: string) => void;
	onForward?: () => void;
	phoneNumberSlide?: SlideProps;
	verifySlide?: SlideProps;
	verifiedSlide?: SlideProps & {
		ctas?: SanityKeyed<{
			card?: string;
			href?: string;
			text: string;
			theme?: ButtonTheme;
		}>[];
	};
}

export interface Props extends CommonProps {
	onResendCode: () => any;
	onSubmitCode: (code: string) => SubmissionErrors | Promise<SubmissionErrors | undefined> | undefined;
	onSubmitPhoneNumber: (phoneNumber: string) => void;
	phoneNumber?: string;
	verified?: boolean;
}

export const RegisterPure: FunctionComponent<Props> = ({
	onChangeCard,
	onForward,
	onResendCode,
	onSubmitCode,
	onSubmitPhoneNumber,
	phoneNumber,
	verified = false,
	phoneNumberSlide: {
		description = defaultDescription,
		theme: phoneNumberTheme = undefined,
	} = {},
	verifySlide: {
		description: verifyDescription = defaultVerifyDescription,
		theme: verifyTheme = undefined,
	} = {},
	verifiedSlide: {
		ctas = [],
		description: verifiedDescription = defaultVerifiedDescription,
		theme: verifiedTheme = undefined,
	} = {},
}) => {
	const slideTheme = !phoneNumber ? phoneNumberTheme : !verified ? verifyTheme : verifiedTheme;

	const phoneNumberDecorators = useMemo((): Decorator<{ phoneNumber: string }>[] => [createFocusDecorator()], []);
	const codeDecorators = useMemo((): Decorator<{ code: string }>[] => [createFocusDecorator()], []);

	const parsedPhoneNumber = useMemo(() => (!phoneNumber ? undefined : parsePhoneNumber(phoneNumber)), [phoneNumber]);

	const TelephoneNumber = useCallback(() => (
		<Strong>
			{parsedPhoneNumber?.formatNational()}
		</Strong>
	), [parsedPhoneNumber]);

	const [showResend, setShowResend] = useState<boolean>(false);

	const [nonSocialCTAs, socialCTAs] = useMemo(() => partition(({ href }) => !href?.includes('instagram.com') && !href?.includes('twitter.com') && !href?.includes('discord.gg') && !href?.includes('youtube.com'), ctas), [ctas]);

	useEffect(() => {
		if (showResend || !phoneNumber || verified) {
			return;
		}

		const timeout = setTimeout(() => setShowResend(true), 5000);

		return (): void => clearTimeout(timeout);
	}, [verified, phoneNumber, showResend]);

	return (
		<SanityThemeProvider globals theme={slideTheme}>
			<Container>
				<Track
					className={classnames({
						phoneNumber,
						verified,
					})}
				>
					<Form<{ phoneNumber: string }>
						decorators={phoneNumberDecorators}
						onSubmit={({ phoneNumber }) => onSubmitPhoneNumber(phoneNumber.replace(/\s+/gu, ''))}
						subscription={{
							dirtySinceLastSubmit: true,
							hasValidationErrors:  true,
							submitFailed:         true,
							submitSucceeded:      true,
							submitting:           true,
							values:               true,
						}}
						render={({
							dirtySinceLastSubmit,
							handleSubmit,
							hasValidationErrors,
							submitFailed,
							submitSucceeded,
							submitting,
						}): ReactNode => (
							<form onSubmit={handleSubmit}>
								<Screen disabled={Boolean(phoneNumber) || submitSucceeded || submitting}>
									<ScreenDescription>
										{!isEmpty(description) && (
											<BlockContent
												blocks={description}
												serializers={serializers}
											/>
										)}
									</ScreenDescription>

									<TelephoneInput name="phoneNumber" />

									<SubmitButton
										className={classnames({ submitting })}
										disabled={hasValidationErrors || submitting || (!dirtySinceLastSubmit && submitFailed)}
										type="submit"
									>
										Verify your number
									</SubmitButton>
								</Screen>
							</form>
						)}
					/>

					<Form<{ code: string }>
						decorators={codeDecorators}
						onSubmit={({ code }) => onSubmitCode(code)}
						subscription={{
							dirtySinceLastSubmit: true,
							hasValidationErrors:  true,
							submitFailed:         true,
							submitSucceeded:      true,
							submitting:           true,
							values:               true,
						}}
						render={({
							form,
							dirtySinceLastSubmit,
							handleSubmit,
							hasValidationErrors,
							submitFailed,
							submitSucceeded,
							submitting,
						}): ReactNode => (
							<form onSubmit={handleSubmit}>
								<Screen disabled={!phoneNumber || submitSucceeded || submitting}>
									<ScreenDescription>
										{!isEmpty(verifyDescription) && (
											<BlockContent
												blocks={verifyDescription}
												serializers={{
													...serializers,
													marks: {
														...serializers.marks,
														code: TelephoneNumber,
													},
												}}
											/>
										)}

										{showResend && (
											<p>
												Didn’t get a code?
												{' '}
												<ResendButton
													type="button"
													onClick={(e): void => {
														e.preventDefault();
														setShowResend(false);
														onResendCode();
													}}
												>
													Resend
												</ResendButton>
											</p>
										)}
									</ScreenDescription>

									<Field<string>
										required
										maxLength={verificationCodeLength}
										minLength={verificationCodeLength}
										name="code"
										pattern="[0-9]*"
										type="text"
										subscription={{
											...reportValiditySubscription,
											touched: true,
											value:   true,
										}}
										render={({
											meta,
											input: { onFocus, ...input },
											meta: { touched },
											...props
										}: FieldRenderProps<string>): ReactNode => (
											<Input
												{...input}
												{...props}
												autoComplete="one-time-code"
												className={classnames({ touched })}
												inputMode="numeric"
												onFocus={reportValidity(onFocus, meta)}
												placeholder="Verification Code"
												onPaste={(): void => {
													setTimeout((): void => {
														void form.submit();
													});
												}}
											/>
										)}
									/>

									<SubmitButton
										className={classnames({ submitting })}
										disabled={hasValidationErrors || submitting || (!dirtySinceLastSubmit && submitFailed)}
										type="submit"
									>
										Let’s Go!
									</SubmitButton>
								</Screen>
							</form>
						)}
					/>

					<VerifiedScreen>
						<DropFireImage alt="Drop Party" />

						<ScreenDescription>
							{!isEmpty(verifiedDescription) && (
								<BlockContent
									blocks={verifiedDescription}
									serializers={{
										...serializers,
										marks: {
											...serializers.marks,
											code: TelephoneNumber,
										},
									}}
								/>
							)}
						</ScreenDescription>

						<VerifiedScreenBottom>
							{!isEmpty(nonSocialCTAs) && nonSocialCTAs.map((cta) => (
								<SanityThemeProvider key={cta._key} theme={cta.theme}>
									{
										'href' in cta
											? (
												<Button as="a" href={cta.href} target="_blank" rel="noreferrer">
													{cta.text}
												</Button>
											)
											: 'card' in cta
												? (
													<Button onClick={(): void => onChangeCard(cta.card!)}>
														{cta.text}
													</Button>
												)
												: onForward
													? (
														<Button onClick={(): void => onForward()}>
															{cta.text}
														</Button>
													)
													: null
									}
								</SanityThemeProvider>
							))}
							{!isEmpty(socialCTAs) && (
								<>
									<HorizontalRuleContainer>
										<HorizontalRule />
										<TextInHorizontalRule>
											Join us on
										</TextInHorizontalRule>
										<HorizontalRule />
									</HorizontalRuleContainer>
									<SocialMediaContainer>
										{socialCTAs.map((cta) => (
											<SocialLink key={cta._key} href={cta.href} target="_blank" rel="noreferrer">
												{
												cta.href!.includes('instagram.com')
													? <Instagram />
													: cta.href!.includes('twitter.com')
														? <Twitter />
														: cta.href!.includes('discord.gg')
															? <Discord />
															: <Youtube />
												}
											</SocialLink>
										))}
									</SocialMediaContainer>
								</>
							)}
						</VerifiedScreenBottom>
					</VerifiedScreen>
				</Track>
			</Container>
		</SanityThemeProvider>
	);
};

const Register = memo(RegisterPure);

export interface ConnectedProps extends CommonProps {
	analytics: AnalyticsInstance;
	drop: string;
	subscriberList: Pick<SubscriberList, '_id'>;
}

const ConnectedRegisterPure: FunctionComponent<ConnectedProps> = ({
	analytics,
	drop,
	subscriberList: { _id: subscriberList },
	...props
}) => {
	const [verified, setVerified] = useState<boolean>(false);

	const Sentry = useSentry();

	const sendCode = useCallback(async (phoneNumber?: string) => (
		!phoneNumber
			? undefined
			: fetchJSON<CreateMultiFactorAuthenticationResponse>(`/api/v1/subscriber-lists/${subscriberList}/intents/${phoneNumber}/mfa`, { method: 'POST' })
	), [subscriberList]);

	const [phoneNumber, setPhoneNumber] = useState<string>();
	const onResendCode = useCallback(async (): Promise<any> => sendCode(phoneNumber), [phoneNumber, sendCode]);

	const onSubmitCode = useCallback(async (code: string) => {
		try {
			await fetchJSON<Intent, CreateIntentBody>(`/api/v1/subscriber-lists/${subscriberList}/intents`, {
				method: 'POST',
				body:   {
					code,
					drop,
					id: phoneNumber!,
				},
			});
		} catch (err) {
			Sentry.captureException(err, { level: Sentry.Severity.Warning });

			return {
				code: !(err instanceof Error)
					? err as string
					: err.message,
			};
		}

		void analytics.track(firebase.analytics.EventName.SIGN_UP, { drop });

		setVerified(true);
	}, [
		Sentry,
		analytics,
		drop,
		phoneNumber,
		subscriberList,
	]);

	const onSubmitPhoneNumber = useCallback((phoneNumber: string) => {
		void analytics.identify(phoneNumber, {
			drop,
			displayName: phoneNumber,
		});

		void analytics.track('verify_number', {
			drop,
			phoneNumber,
		});

		void sendCode(phoneNumber);

		setPhoneNumber(phoneNumber);
	}, [analytics, drop, sendCode]);

	return (
		<Register
			{...props}
			onResendCode={onResendCode}
			onSubmitCode={onSubmitCode}
			onSubmitPhoneNumber={onSubmitPhoneNumber}
			phoneNumber={phoneNumber}
			verified={verified}
		/>
	);
};

export const ConnectedRegister = memo(ConnectedRegisterPure);

export default Register;
