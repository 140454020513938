import BlockContent from '@sanity/block-content-to-react';
import type { BlockRendererProps, Serializers } from '@sanity/block-content-to-react';
import React from 'react';
import type { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

import type { DeepPartial } from 'utils';

const header = css`
	color: ${({ theme: { color } }) => color};
	line-height: 1;
	margin-top: 0;
	margin-bottom: 0.75rem;
`;

const H1 = styled.h1`
	${header}
	font-size: 3.5rem;
`;

const H2 = styled.h2`
	${header}
	font-size: 3rem;
`;

const H3 = styled.h3`
	${header}
	font-size: 2.5rem;
`;

const H4 = styled.h4`
	${header}
	font-size: 2rem;
`;

const H5 = styled.h5`
	${header}
	font-size: 1.5rem;
`;

const H6 = styled.h6`
	${header}
	font-size: 1.25rem;
`;

const styles: { [style: string]: FunctionComponent<BlockRendererProps> | undefined } = {
	h1: H1,
	h2: H2,
	h3: H3,
	h4: H4,
	h5: H5,
	h6: H6,
};

const Block: FunctionComponent<BlockRendererProps> = (props) => {
	const { node: { style } } = props;

	const Component = styles[style] ?? BlockContent.defaultSerializers.types.block;

	return <Component {...props} />;
};

const Strong = styled.strong`
	color: ${({ theme: { color } }) => color};
`;

export const serializers: DeepPartial<Serializers> = {
	marks: { strong: Strong },
	types: { block: Block },
};
