import type { Blank, Product } from '@drop-party/sanity';
import React, { memo } from 'react';
import type { FunctionComponent } from 'react';
import styled, { useTheme } from 'styled-components';

import type { LineItem as LineItemType } from 'models/orders';
import { getVariationName } from 'models/products';
import { formatCurrency } from 'utils';
import { imageBuilder } from 'vendors/sanity/utils';

const Container = styled.li`
	display: flex;
	align-items: flex-start;
	list-style: none;
`;

const IconContainer = styled.div`
	position: relative;
`;

const Icon = styled.img`
	width: 4rem;
	min-width: 4rem;
	max-width: 4rem;
	height: 4rem;
	min-height: 4rem;
	max-height: 4rem;
	margin-right: 1rem;
	border-radius: 4px;
	object-fit: cover;
	overflow: hidden;
	font-size: ${({ theme: { imageFontSize } }) => imageFontSize}px;
`;

const Quantity = styled.div`
	position: absolute;
	top: -0.75rem;
	left: -0.75rem;
	height: 1.5rem;
	width: 1.5rem;
	padding-top: 0.25rem;
	background-color: ${({ theme: { color } }) => color};
	border-radius: 50%;
	border: 2px solid ${({ theme: { surfaceBackground } }) => surfaceBackground};
	color: ${({ theme: { surfaceBackground } }) => surfaceBackground};
	font-size: 0.75rem;
	line-height: 1;
	font-weight: 700;
	text-align: center;
`;

const Details = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	align-items: flex-start;
	width: 100%;
	margin-bottom: 0;
`;

const Label = styled.span`
	font-weight: 800;
`;

const Actions = styled.div`
	line-height: 1.25;
`;

const ActionButton = styled.button`
	padding: 0;
	font-size: 0.75rem;
	color: ${({ theme: { removeLineItemColor } }) => removeLineItemColor};
	cursor: pointer;
	background: none;
	border: none;
	appearance: none;

	&:not(&:first-child) {
		padding-left: 0.5rem;
	}
	
	@media (hover: hover) and (pointer: fine) {
		&:hover {
			text-decoration: underline;
		}
	}
`;

const Amount = styled.span`
	font-size: 0.875rem;
`;

export interface Props {
	className?: string;
	lineItem: LineItemType;
	onAddLineItem?: (newLineItem: LineItemType) => void;
	product: Pick<Product, 'amount' | 'icon' | 'label'> & { blank?: Pick<Blank, 'variations'> };
}

export const LineItemPure: FunctionComponent<Props> = ({
	className,
	onAddLineItem,
	lineItem: {
		quantity,
		sku,
		sku: { variations: skuVariations },
	},
	product: {
		amount,
		icon,
		label,
		blank: { variations } = {},
	},
}) => {
	const { fontSize } = useTheme();

	return (
		<Container className={className}>
			<IconContainer>
				{quantity > 1 && (
					<Quantity>
						{quantity}
					</Quantity>
				)}
				<Icon
					alt={label}
					height={4 * fontSize}
					width={4 * fontSize}
					src={
						imageBuilder.image(icon)
							.size(8 * fontSize, 8 * fontSize)
							.url()!
					}
				/>
			</IconContainer>
			<Details>
				<Label>
					{label}
				</Label>
				{variations && (
					<span>
						{getVariationName(variations, skuVariations)}
					</span>
				)}
				{onAddLineItem && (
					<Actions>
						<ActionButton
							type="button"
							onClick={(): void => onAddLineItem({
								sku,
								quantity: -1,
							})}
						>
							Remove
						</ActionButton>
					</Actions>
				)}
			</Details>
			<Amount>
				{formatCurrency((quantity * amount) / 100)}
			</Amount>
		</Container>
	);
};

const LineItem = memo(LineItemPure);

export default LineItem;
