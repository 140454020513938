import { filter, flatMap, flow, groupBy, map, mapValues, sumBy, times, uniqBy } from 'lodash/fp';

import type { LineItem } from 'models/orders';
import { getSkuName } from 'models/products';

export const breakupLineItems = (lineItems: LineItem[]): LineItem[] => flatMap<LineItem, LineItem>(({ quantity, ...lineItem }) => times(() => ({
	...lineItem,
	quantity: 1,
}), quantity))(lineItems);

export const consolidateLineItems = (lineItems: LineItem[]): LineItem[] => {
	const quantities = flow(
		groupBy<LineItem>(({ sku }) => getSkuName(sku)),
		mapValues(sumBy<LineItem>(({ quantity }) => quantity))
	)(lineItems);

	return flow(
		uniqBy<LineItem>(({ sku }) => getSkuName(sku)),
		map<LineItem, LineItem>(({ sku }) => ({
			sku,
			quantity: quantities[getSkuName(sku)],
		})),
		filter<LineItem>(({ quantity }) => quantity > 0)
	)(lineItems);
};
