import { CardElement, useElements, Elements } from '@stripe/react-stripe-js';
import type { CreatePaymentMethodCardData, Stripe, StripeCardElementChangeEvent } from '@stripe/stripe-js';
import classnames from 'classnames';
import type { SubmissionErrors } from 'final-form';
import { FORM_ERROR } from 'final-form';
import React, { Fragment, useCallback, useContext, useMemo } from 'react';
import type { ReactElement, ReactNode } from 'react';
import { Form } from 'react-final-form';
import { Field } from 'react-final-form-html5-validation';
import { useAsync } from 'react-use';
import styled, { ThemeContext, css } from 'styled-components';

import { button } from 'components/Buttons';
import type { CardTypeConfig } from 'components/Cards';
import { back, cardInner, content, header, headerContext, headerText, sectionHeader } from 'components/Cards/styles';
import { input } from 'components/Fields';
import OrderSummary from 'components/OrderSummary';
import Value from 'components/Value';
import { reset } from 'components/styles';
import type { PotentialOrder } from 'models/orders';
import { contrast, isShippingAddress } from 'utils';
import { getFormattedAddress } from 'utils/transforms';
import { useSentry } from 'vendors/sentry/hooks';

const Container = styled.form`
	${cardInner}

	&.submitting {
		cursor: wait;
	}
`;

const Header = styled.div`
	${header}
`;

const HeaderText = styled.span`
	${headerText}
`;

const HeaderIndex = styled.span`
	${headerContext}
`;

const CardIcon = styled.span`
	display: inline-block;
	height: 1rem;
	max-height: 1rem;
	width: 1.3333333333rem;
	max-width: 1.3333333333rem;
	vertical-align: -0.1111111111rem;
	background-image: url('/card-${({ theme: { surfaceBackground } }) => contrast(surfaceBackground)}.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: 1.3333333333rem;
`;

const BackButton = styled.button`
	${back}
`;

const Content = styled.fieldset`
	${content}
`;

const SectionHeader = styled.div`
	${sectionHeader}
`;

const CardInput = styled(CardElement)`
	${input}
	margin-bottom: 1rem;

	&:focus-within {
		margin-bottom: calc(1rem - 1px);
	}

	/* stylelint-disable declaration-block-semicolon-newline-after,order/order,rule-empty-line-before -- Styled-Components interpolation */
	${Container}.submitting &:disabled {
		cursor: wait;
	}
	/* stylelint-enable declaration-block-semicolon-newline-after,order/order,rule-empty-line-before */
`;

const Secure = styled.div`
	padding-left: 2rem;
	margin: 0 0 1rem;
	font-size: 0.75rem;
	line-height: 1.25;
	background-image: url('/secure-${({ theme: { surfaceBackground } }) => contrast(surfaceBackground)}.svg');
	background-repeat: no-repeat;
	background-position: 0 0.1rem;
	background-size: 1.5rem;
	min-height: 1.8125rem;
	border-radius: 4px;
	color: ${({ theme: { secondaryColor } }) => secondaryColor};
`;

const Spacer = styled.div`
	flex-grow: 1;
	min-height: 1rem;
`;

const summarySection = css`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: stretch;

	&:first-child {
		padding-top: 0;
	}

	&:last-child {
		border-bottom: none;
	}
`;

const ShippingSection = styled.div`
	padding-top: 0;
	border-bottom: 1px solid ${({ theme: { dividerColor } }) => dividerColor};
	order: -1;
`;

const TextButton = styled.button`
	${reset}
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
	padding: 0.5rem;
	font-weight: 600;
	cursor: pointer;
	color: ${({ theme: { purchaseTextLinkColor } }) => purchaseTextLinkColor};

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			text-decoration: underline;
		}
	}
`;

const CartButton = styled.button`
	${reset}
	${summarySection}
	padding: 0.75rem 1rem;
	font-weight: 700;
	cursor: pointer;
	color: ${({ theme: { purchaseTextLinkColor } }) => purchaseTextLinkColor};
	border-top: 1px solid ${({ theme: { dividerColor } }) => dividerColor};

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			text-decoration: underline;
		}
	}
`;

const SubmitError = styled.div`
	margin-bottom: 1rem;
	font-weight: 700;
	color: #ff0000;
`;

const SubmitButton = styled.button`
	${button}
	margin-bottom: 0;

	/* stylelint-disable declaration-block-semicolon-newline-after,rule-empty-line-before -- Styled-Components interpolation */
	${Container}.submitting &:disabled {
		cursor: wait;
	}
	/* stylelint-enable declaration-block-semicolon-newline-after,rule-empty-line-before */
`;

export const cardTypeConfig: CardTypeConfig = { card: { hidden: true } };

interface Values {
	changeEvent: StripeCardElementChangeEvent;
	potentialOrder: PotentialOrder;
}

export interface Props<T extends { [field: string]: any } = { [field: string]: any }> {
	children?: ReactNode;
	cta?: string;
	onBack?: () => void;
	onCart: () => void;
	onShipping: () => void;
	onSubmit: (values: { paymentMethod: CreatePaymentMethodCardData } & Omit<Values & T, 'changeEvent'>) => SubmissionErrors | Promise<SubmissionErrors | undefined> | undefined;
	potentialOrder?: PotentialOrder;
}

const Purchase = <T extends { [field: string]: any }>({
	children,
	onBack,
	onCart,
	onShipping,
	onSubmit,
	potentialOrder,
	cta = !potentialOrder?.transactions.data[potentialOrder.transactions.ids[0]].total ? 'Place Free Order!' : 'Pay Now',
}: Props<T>): ReactElement => {
	const {
		color,
		errorColor,
		fontFamily,
		fontSize,
		inputPlaceholderColor,
		lineHeight,
		surfaceBackground,
		color: iconColor,
	} = useContext(ThemeContext);

	const elements = useElements();

	const { value: countryRegionData } = useAsync(async () => (await import('country-region-data')).default, []);

	const formattedAddress = useMemo(() => (
		!countryRegionData || !potentialOrder?.shippingAddress || !isShippingAddress(potentialOrder.shippingAddress)
			? undefined
			: getFormattedAddress(potentialOrder.shippingAddress, countryRegionData)
	), [countryRegionData, potentialOrder?.shippingAddress]);

	return (
		<Form<Values & T>
			onSubmit={({ changeEvent, ...props }) => onSubmit({
				...props,
				paymentMethod: {
					card: elements!.getElement(CardElement)!,
					type: 'card',
				},
			})}
			subscription={{
				dirtySinceLastSubmit: true,
				hasValidationErrors:  true,
				submitError:          true,
				submitFailed:         true,
				submitSucceeded:      true,
				submitting:           true,
				values:               true,
			}}
			render={({
				dirtySinceLastSubmit,
				handleSubmit,
				hasValidationErrors,
				submitError,
				submitFailed,
				submitSucceeded,
				submitting,
			}): ReactNode => (
				<Container
					className={classnames({ submitting })}
					onSubmit={handleSubmit}
				>
					<Header>
						{onBack && (
							<BackButton tabIndex={-1} type="button" onClick={() => onBack()}>
								<img alt="Back" src={`/arrow-left-${contrast(surfaceBackground)}.svg`} />
							</BackButton>
						)}
						<HeaderText>
							Pay with
							{' '}
							<CardIcon />
						</HeaderText>
						<HeaderIndex>2 of 2</HeaderIndex>
					</Header>

					<Content disabled={submitting || submitSucceeded}>
						{Boolean(potentialOrder?.transactions.data[potentialOrder.transactions.ids[0]].total) && (
							<>
								<SectionHeader>Credit Card</SectionHeader>

								<Field<StripeCardElementChangeEvent | undefined>
									name="changeEvent"
									validate={(event): string | undefined => event?.error?.message ?? (event?.complete ? undefined : 'Required')}
									render={({ input: { name, value, ...input } }): ReactNode => (
										<CardInput
											{...input}
											options={{
												disabled:  submitting || submitSucceeded,
												iconStyle: 'solid',
												style:     {
													base: {
														color,
														fontFamily,
														iconColor,
														'fontSize':      `${fontSize}px`,
														'fontSmoothing': 'antialiased',
														'lineHeight':    `${fontSize * lineHeight}px`,
														'::placeholder': { color: inputPlaceholderColor },
													},
													invalid: {
														color:     errorColor,
														iconColor: errorColor,
													},
												},
											}}
										/>
									)}
								/>

								<Secure>We protect your payment information using encryption to provide bank-level security.</Secure>
							</>
						)}

						{!submitting && submitError && (
							<SubmitError>
								{submitError}
							</SubmitError>
						)}

						{children}

						<Value name="potentialOrder" value={potentialOrder} validate={(potentialOrder?: PotentialOrder): string | undefined => (potentialOrder ? undefined : 'Required')} />

						<Spacer />

						{potentialOrder && (
							<OrderSummary order={potentialOrder}>
								<ShippingSection>
									<SectionHeader>Shipping</SectionHeader>
									{formattedAddress?.map((parts, i) => {
										const line = parts.join(' ');

										return (
											<Fragment key={line}>
												{line}
												{i < formattedAddress.length - 1 && <br />}
											</Fragment>
										);
									})}
									<TextButton type="button" onClick={() => onShipping()}>
										Edit
									</TextButton>
								</ShippingSection>
								<CartButton type="button" onClick={() => onCart()}>
									View Order
								</CartButton>
							</OrderSummary>
						)}

						<SubmitButton
							className={classnames({ submitting })}
							disabled={!elements || hasValidationErrors || submitting || submitSucceeded || (submitFailed && !dirtySinceLastSubmit)}
							type="submit"
						>
							{submitSucceeded ? 'Done!' : cta}
						</SubmitButton>
					</Content>
				</Container>
			)}
		/>
	);
};

interface ConnectedProps<T extends { [field: string]: any } = { [field: string]: any }> extends Props<T> {
	stripe: Stripe | null;
}

export const ConnectedPurchase = <T extends { [field: string]: any }>({
	stripe,
	onSubmit: onSubmitRaw,
	...props
}: ConnectedProps<T>): ReactElement => {
	const Sentry = useSentry();

	const onSubmit = useCallback(async (values) => {
		try {
			await onSubmitRaw(values);
		} catch (err) {
			Sentry.captureException(err, { level: Sentry.Severity.Warning });

			return { [FORM_ERROR]: !(err instanceof Error) ? err as string : err.message };
		}
	}, [Sentry, onSubmitRaw]);

	return (
		<Elements stripe={stripe}>
			<Purchase<T>
				{...props}
				onSubmit={onSubmit}
			/>
		</Elements>
	);
};

export default Purchase;
