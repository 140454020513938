import type { Blank, Drop, DrawCard, Product, ImageMeta } from '@drop-party/sanity';
import BlockContent from '@sanity/block-content-to-react';
import { map } from 'lodash/fp';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import type { FunctionComponent } from 'react';
import Confetti from 'react-confetti';
import { useEvent } from 'react-use';
import styled, { css } from 'styled-components';

import type { CardTypeConfig } from 'components/Cards';
import { cardInner } from 'components/Cards/styles';
import { ConnectedCountdown } from 'components/Countdown';
import { horizontalRuleContainer, horizontalRule, textInHorizontalRule } from 'components/styles';
import type { Order } from 'models/orders';
import { useOrderDetailsData } from 'utils/hooks';
import type { OrderDetailsData } from 'utils/hooks';
import { ReplaceTextRenderer } from 'utils/serializers';
import type { SocialMediaData } from 'utils/socialMedia';
import { getSocialMediaData } from 'utils/socialMedia';
import { imageBuilder } from 'vendors/sanity/utils';

// TODO: use NEXT image components for responsiveness

// TODO: polish up blurry background

const btnHover = css`
	transition: transform 0.3s ease-in-out;

	&:hover {
		transform: scale(1.02);
	}
`;

const Container = styled.div`
	${cardInner}
	position: relative;
	padding: 1.5rem;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: center;
	overflow-y: auto;
	overflow-x: clip;
	filter:
		drop-shadow(0 -5mm 5mm rgb(132, 247, 246, 20%))
		drop-shadow(15mm -45mm 10mm rgb(0, 20, 201, 20%));

	&::before {
		content: '';
	}
`;

const Button = styled.button`
	all: unset;
	border-radius: 3rem;
	padding: 0.875rem 0;
	width: 100%;
	font-weight: 700;
	font-size: 0.875rem;
	line-height: 1.25rem;
	text-align: center;

	&:hover {
		cursor: pointer;
	}
`;

const TextContainer = styled.div`
	width: 100%;
`;

const ShareDrop = styled(Button)`
	${btnHover}
	background-color: #000000;
	color: #ffffff;
`;

const BackToDrop = styled(Button)`
	${btnHover}
	background-color: #ffffff;
	color: #000000;
	border: 1px solid #000000;
	margin-top: 0.5rem;
`;

const Image = styled.img`
	width: 90%;
	align-self: center;
	filter: drop-shadow(0 6mm 6mm rgb(0, 0, 0, 20%));
`;

const MappedImageWrapper = styled.div`
	flex-wrap: wrap;
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: min-content;
`;

const MappedImage = styled.img<{ imagesLength: number; index: number }>`
	margin: 5px;
	flex-basis: 45%;
	min-width: 45%;
	max-height: 100%;
	filter: drop-shadow(0 6mm 6mm rgb(0, 0, 0, 20%));
	flex-grow:
		${({ imagesLength }) => (
		(imagesLength % 2 === 1) && (imagesLength >= 3)
			? '0'
			: '0.5'
	)};
	padding:
		${({ imagesLength, index }) => (
		imagesLength === 2
			? !index
				? '0 0 3rem 0'
				: '3rem 0 0 0'
			: '0 0 0 0'
	)};
`;

type DrawResult = 'lost' | 'waiting' | 'won';

const drawResults: { [paymentStatus: string]: DrawResult | undefined } = {
	canceled:         'lost',
	requires_capture: 'waiting',
	succeeded:        'won',
};

export const cardTypeConfig: CardTypeConfig = {};

export interface Props {
	countdownStartsAt?: string;
	waitingText: DrawCard['waitingText'];
	wonText: DrawCard['wonText'];
	lostImage: ImageMeta;
	lostText: DrawCard['lostText'];
	onBackToDrop?: () => void;
	products: (Pick<Product, '_id' | 'amount' | 'icon' | 'label'> & { blank?: Pick<Blank, 'variations'> })[];
	order: Order;
	confettiColors: {
		hex: string;
	}[];
	shareDrop?: () => void;
	shareText?: string;
	socialMedia: Drop['socialMedia'];
}

/* eslint-disable @typescript-eslint/no-use-before-define -- hoisted */
const Draw: FunctionComponent<Props> = ({
	countdownStartsAt,
	waitingText,
	wonText,
	lostImage,
	lostText,
	onBackToDrop,
	products,
	order: {
		payments,
		transactions,
	},
	confettiColors,
	socialMedia,
	shareDrop,
	shareText = 'SHARE THIS DROP',
}) => {
	const {
		replaceWith,
		images,
		paymentStatus,
	} = useOrderDetailsData(transactions, payments, products);

	const { icons, links } = getSocialMediaData(socialMedia, socialMediaIcon);
	const drawResult = drawResults[paymentStatus] ?? 'waiting';

	const containerRef = useRef<null | HTMLDivElement>(null);
	const [confettiDimensions, setConfettiDimensions] = useState<{width: number; height: number}>({
		width:  0,
		height: 0,
	});

	// TODO: make confetti fall to bottom of scroll height
	const updateConfettiCanvas = useCallback(() => {
		if (process.browser && containerRef.current) {
			const { width, height } = containerRef.current.getBoundingClientRect();
			setConfettiDimensions({
				width,
				height,
			});
		}
	}, []);

	useEvent('resize', updateConfettiCanvas);
	useEvent('DOMContentLoaded', updateConfettiCanvas);
	useEffect(() => {
		requestAnimationFrame(updateConfettiCanvas);
	}, [updateConfettiCanvas]);

	// TODO: make these confetti shapes dynamic
	const confettiShapes = process.browser
		? {
			left:   new Path2D('M0 0C3.31187 0 6 2.68727 6 6C6 9.31273 3.31187 12 0 12'),
			middle: new Path2D('M6 12C9.31187 12 12 9.31203 12 5.99843C12 2.68797 9.31187 0 6 0C2.68813 0 0 2.68797 0 6.00157C0 9.31517 2.68813 12 6 12Z'),
			right:  new Path2D('M5.99683 0C2.68496 0 0 2.68727 0 6C0 9.31273 2.68813 12 6 12'),
		}
		: {
			left:   undefined,
			middle: undefined,
			right:  undefined,
		};

	return (
		<Container ref={containerRef}>
			{(drawResult === 'won') && (
				<Confetti
					width={confettiDimensions.width}
					height={confettiDimensions.height}
					colors={map('hex', confettiColors)}
					numberOfPieces={100}
					opacity={0.9}
					drawShape={(ctx) => {
						ctx.translate(-12, -6);
						ctx.fill(confettiShapes.left!);
						ctx.translate(6, 0);
						ctx.fill(confettiShapes.middle!);
						ctx.translate(12, 0);
						ctx.fill(confettiShapes.right!);
					}}
				/>
			)}
			{drawResult === 'waiting' && (
				<Waiting
					images={images}
					toReplace={`{{${drawResult.toUpperCase()}}}`}
					replaceWith={replaceWith}
					shareText={shareText}
					shareDrop={shareDrop!}
					waitingText={waitingText}
					countdownStartsAt={countdownStartsAt}
					onBackToDrop={onBackToDrop}
				/>
			)}
			{drawResult === 'won' && (
				<Won
					images={images}
					toReplace={`{{${drawResult.toUpperCase()}}}`}
					replaceWith={replaceWith}
					shareText={shareText}
					shareDrop={shareDrop!}
					wonText={wonText}
					onBackToDrop={onBackToDrop}
				/>
			)}
			{drawResult === 'lost' && (
				<Lost
					toReplace={`{{${drawResult.toUpperCase()}}}`}
					replaceWith={replaceWith}
					links={links}
					icons={icons}
					shareText={shareText}
					shareDrop={shareDrop!}
					lostImage={lostImage}
					lostText={lostText}
				/>
			)}
		</Container>
	);
};
/* eslint-enable @typescript-eslint/no-use-before-define -- hoisted */

// Fan Waiting Components

const CountdownHeader = styled.h1`
	all: unset;
	font-weight: 700;
	font-size: 0.875rem;
	line-height: 1.125rem;
	width: 100%;
	text-align: center;
`;

const CountdownContainer = styled.div`
	margin: 1vh 0 2vh 0;
`;

type WaitingProps = Pick<Props, 'waitingText' | 'countdownStartsAt' | 'onBackToDrop'> & {
	toReplace: string;
	replaceWith: string;
	images: OrderDetailsData['images'];
	shareText: string;
	shareDrop: () => void;
};

const Waiting: FunctionComponent<WaitingProps> = ({ images, toReplace, replaceWith, shareText, shareDrop, waitingText, countdownStartsAt, onBackToDrop }) => (
	<>
		<MappedImageWrapper>
			{images?.map(({ icon, alt }, index) => (
				<MappedImage
					src={imageBuilder.image(icon)
						.height(300)
						.width(300)
						.format('png')
						.url()!}
					alt={alt}
					key={index}
					imagesLength={images.length}
					index={index}
				/>
			))}
		</MappedImageWrapper>
		<TextContainer>
			<BlockContent
				blocks={waitingText}
				serializers={{
					types: {
						// @ts-expect-error -- correct type returned from ()=>
						block: (props) => ReplaceTextRenderer({
							...props,
							toReplace,
							replaceWith,
						}),
					},
				}}
			/>
			<CountdownHeader>
				DRAW CLOSES IN
			</CountdownHeader>
			<CountdownContainer>
				<ConnectedCountdown
					until={countdownStartsAt!}
				/>
			</CountdownContainer>
			<ShareDrop onClick={() => shareDrop()}>
				{shareText}
			</ShareDrop>
			<BackToDrop onClick={() => onBackToDrop?.()}>
				BACK TO DROP
			</BackToDrop>
		</TextContainer>
	</>
);

// Fan Won Components

type WonProps = Pick<Props, 'wonText' | 'onBackToDrop'> & {
	toReplace: string;
	replaceWith: string;
	images: OrderDetailsData['images'];
	shareText: string;
	shareDrop: () => void;
};

const Won: FunctionComponent<WonProps> = ({ images, toReplace, replaceWith, shareText, shareDrop, wonText, onBackToDrop }) => (
	<>
		<MappedImageWrapper>
			{images?.map(({ icon, alt }, index) => (
				<MappedImage
					src={imageBuilder.image(icon)
						.height(300)
						.width(300)
						.format('png')
						.url()!}
					alt={alt}
					key={index}
					imagesLength={images.length}
					index={index}
				/>
			))}
		</MappedImageWrapper>
		<TextContainer>
			<BlockContent
				blocks={wonText}
				serializers={{
					types: {
						// @ts-expect-error -- correct type returned from ()=>
						block: (props) => ReplaceTextRenderer({
							...props,
							toReplace,
							replaceWith,
						}),
					},
				}}
			/>
			<ShareDrop onClick={() => shareDrop()}>
				{shareText}
			</ShareDrop>
			<BackToDrop onClick={() => onBackToDrop?.()}>
				BACK TO DROP
			</BackToDrop>
		</TextContainer>
	</>
);

// Fan Lost Components

const HorizontalRuleContainer = styled.div`
	${horizontalRuleContainer}
`;

const HorizontalRule = styled.div`
	${horizontalRule}
	background-color: #000000;
`;

const TextInHorizontalRule = styled.p`
	${textInHorizontalRule}
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
`;

const SocialMediaContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
`;

const socialMediaIcon = css`
	transform: scale(1.4);
	margin: 0 0.875rem 0.2rem 0.875rem;

	&:hover {
		cursor: pointer;
	}
`;

const SocialMediaIcon = styled.a`
	all: unset;
`;

type LostProps = Pick<Props, 'lostImage' | 'lostText' | 'onBackToDrop'> & {
	toReplace: string;
	replaceWith: string;
	links: SocialMediaData['links'];
	icons: SocialMediaData['icons'];
	shareText: string;
	shareDrop: () => void;
};

const Lost: FunctionComponent<LostProps> = ({ toReplace, replaceWith, shareText, shareDrop, lostImage, lostText, links, icons }) => (
	<>
		<Image src={imageBuilder.image(lostImage).url()!} alt={lostImage.alt} />
		<TextContainer>
			<BlockContent
				blocks={lostText}
				serializers={{
					types: {
						// @ts-expect-error -- correct type returned from ()=>
						block: (props) => ReplaceTextRenderer({
							...props,
							toReplace,
							replaceWith,
						}),
					},
				}}
			/>
			<ShareDrop onClick={() => shareDrop()}>
				{shareText}
			</ShareDrop>
			{links.length !== 0
				&& (
					<>
						<HorizontalRuleContainer>
							<HorizontalRule />
							<TextInHorizontalRule>
								Join us on
							</TextInHorizontalRule>
							<HorizontalRule />
						</HorizontalRuleContainer>
						<SocialMediaContainer>
							{icons.map((Icon, index) => (
								<SocialMediaIcon key={index} href={links[index]} target="_blank">
									<Icon />
								</SocialMediaIcon>
							))}
						</SocialMediaContainer>
					</>
				)}
		</TextContainer>
	</>
);

export default Draw;
