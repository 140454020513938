import type { Drop, Product } from '@drop-party/sanity';
import BlockContent from '@sanity/block-content-to-react';
import type { AnalyticsInstance } from 'analytics';
import { fromPairs, keyBy, sumBy } from 'lodash/fp';
import React, { Fragment, memo, useCallback, useEffect, useContext, useMemo, useState } from 'react';
import type { FunctionComponent } from 'react';
import { useAsync } from 'react-use';
import styled, { ThemeContext, css } from 'styled-components';

import { button } from 'components/Buttons';
import type { CardTypeConfig } from 'components/Cards';
import { back, cardInner, content, header, headerText } from 'components/Cards/styles';
import { input, label, withLabel, withLabelContainer } from 'components/Fields';
import LineItemUnstyled from 'components/LineItem';
import type { Props as LineItemProps } from 'components/LineItem';
import type { LineItem as LineItemType } from 'models/orders';
import { consolidateLineItems } from 'models/orders/utils';
import { getSkuName } from 'models/products';
import { contrast, formatCurrency } from 'utils';
import type { ProductFiltered as ProductAnalytics } from 'utils/analytics';
import { beginCheckoutEvent, removeFromCartEvent } from 'utils/analytics';

const Container = styled.div`
	${cardInner}
`;

const Header = styled.div`
	${header}
	border-bottom: none;
`;

const HeaderText = styled.div`
	${headerText}
`;

const BackButton = styled.button`
	${back}
`;

const Content = styled.div`
	${content}
	padding-top: 1rem;
`;

const ShipsTo = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 0.5rem;
	font-size: 0.75rem;
	font-weight: 600;
	line-height: 1.4;
	background: ${({ theme: { bannerBackground } }) => bannerBackground};
	text-transform: uppercase;
`;

const ShipsToIcon = styled.img`
	height: 1rem;
	max-height: 1rem;
	width: 1rem;
	max-width: 1rem;
	margin-right: 0.5rem;
	object-fit: contain;
	overflow: hidden;
`;

const LineItems = styled.ul`
	flex-grow: 1;
	padding: 0;
	margin: 0;
`;

const LineItem = styled(LineItemUnstyled)`
	margin: 0 0 1rem;
`;

const SubtotalContainer = styled.div`
	display: flex;
	align-items: flex-start;
	margin: 0 0 1rem;
	font-weight: 800;
	font-size: 1.125rem;
`;

const Title = styled.div`
	flex-grow: 1;
	font-size: 1.125rem;
	font-weight: 800;
`;

const Details = styled.div`
	font-weight: ${({ theme: { fontWeight } }) => fontWeight};
	font-size: 0.75rem;
	color: ${({ theme: { secondaryColor } }) => secondaryColor};
`;

const Notice = styled.div`
	display: flex;
	flex-direction: row;
	padding-right: 1rem;
	font-size: 0.75rem;
	line-height: 1.25;
	margin: 0 0 1rem;
	background-color: ${({ theme: { noticeBackground } }) => noticeBackground};
`;

const Acknowledgement = styled.input`
	min-width: 3rem;
	width: 3rem;
	margin-top: 0.9rem;
`;

const NoticeIcon = styled.div`
	min-width: 3rem;
	width: 3rem;
	background-repeat: no-repeat;
	background-position: center 0.9rem;
	background-size: 1rem;
	background-image: url('/warning.svg');
`;

const Policies = styled.div`
	padding: 0 0 1rem;
	font-size: 0.75rem;
`;

const PolicyLink = styled.a`
	color: inherit;
	text-decoration: underline;
	font-weight: 700;
	
	@media (hover: hover) and (pointer: fine) {
		&:hover {
			text-decoration: none;
		}
	}
`;

const Input = styled.input`
	${input}
	${withLabel}
	width: 100%;
	margin-bottom: 1rem;

	&:focus-within {
		width: calc(100% + 2px);
		margin-bottom: calc(1rem - 1px);
	}
`;

const Label = styled.span`
	${label}
`;

const FieldContainer = styled.div`
	${withLabelContainer}
	margin: 0.5rem 0;
`;

const PayButtons = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	justify-content: space-between;
	margin-right: -1rem;
`;

const payButton = css`
	${button}
	flex-basis: 0;
	flex-grow: 1;
	min-width: calc(50% - 1rem);
	max-width: calc(100%);
	margin-right: 1rem;
`;

const PayButton = styled.button`
	${payButton}
`;

const CardIcon = styled.span`
	display: inline-block;
	height: 1rem;
	max-height: 1rem;
	width: 1.3333333333rem;
	max-width: 1.3333333333rem;
	vertical-align: -0.1666666667rem;
	background-image: url('/card-${({ theme: { buttonBackground } }) => contrast(buttonBackground)}.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: 1.3333333333rem;

	/* stylelint-disable selector-max-type,selector-type-no-unknown -- Styled-Components interpolation */

	@media (hover: hover) and (pointer: fine) {
		${PayButton}:hover & {
			background-image: url('/card-${({ theme: { buttonEmphasisBackground } }) => contrast(buttonEmphasisBackground)}.svg');
		}
	}

	${PayButton}:active & {
		background-image: url('/card-${({ theme: { buttonEmphasisBackground } }) => contrast(buttonEmphasisBackground)}.svg');
	}
	/* stylelint-enable selector-max-type,selector-type-no-unknown -- Styled-Components interpolation */
`;

const ExternalPayButton = styled.button`
	${payButton}
	color: #2e2e2e;
	background-color: #ffffff;
	border-color: #2e2e2e;

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			color: #000000;
			background-color: #ffffff;
			border-color: #000000;
		}
	}

	&:active {
		border-color: #000000;
		color: #000000;
	}

	&.submitting {
		&:disabled {
			background-color: #ffffff;
			background-image: url('/submitting-dark.svg');
		}
	}
`;

const EmptyCart = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: center;
	align-items: center;
	text-align: center;
`;

const EmptyCartImage = styled.img`
	margin-bottom: 1.5rem;
	width: 40%;
	object-fit: cover;
`;

const EmptyCartHeader = styled.div`
	margin-bottom: 0.5rem;
	font-size: 1.5rem;
	font-weight: 800;
	text-transform: uppercase;
`;

const EmptyCardDescription = styled.div`
	color: ${({ theme: { secondaryColor } }) => secondaryColor};
`;

const EmptyCartButton = styled.button`
	${button}
`;

export const cardTypeConfig: CardTypeConfig = { card: { paginationIcon: '/cart-light.svg' } };

interface CommonProps {
	countries?: string[];
	cta?: string;
	lineItems: LineItemType[];
	onApplePay?: (args?: { custom?: Record<string, string> }) => void;
	onBack?: () => void;
	onCheckout?: (args?: { custom?: Record<string, string> }) => void;
	onGooglePay?: (args?: { custom?: Record<string, string> }) => void;
	onHideCard: (card: string) => void; // FIXME When I group this into onEmptyCart, I get an infite loop
	onStartOver: () => void;
}

type ProductFiltered = Pick<Product, '_id' | 'amount'> & LineItemProps['product'];

export interface Props extends CommonProps {
	custom?: Drop['custom'];
	notices?: Drop['notices'];
	onChangeLineItems?: (lineItems: LineItemType[], newLineItem: LineItemType) => void;
	products: ProductFiltered[];
}

export const CartPure: FunctionComponent<Props> = ({
	countries,
	lineItems,
	onApplePay,
	onBack,
	onChangeLineItems,
	onCheckout,
	onGooglePay,
	onHideCard,
	onStartOver,
	products,
	cta = 'Pay with',
	custom = [],
	notices = [],
}) => {
	const { bannerBackground, surfaceBackground } = useContext(ThemeContext);
	const productsById = useMemo(() => keyBy('_id', products), [products]);

	useEffect(() => {
		if (lineItems.length) {
			return;
		}

		onHideCard('shipping');
		onHideCard('purchase');
	}, [lineItems, onHideCard]);

	const { value: firstCountryLabel } = useAsync(async () => (
		countries?.length !== 1
			? undefined
			: (await import('country-region-data')).default
				.find(({ countryShortCode }) => countryShortCode === countries[0])
				?.countryName
	), [countries]);

	const [acknowledgements, setAcknowledgements] = useState<{ [key: string]: boolean }>(() => fromPairs(notices.filter(({ requiresAcknowledgement }) => requiresAcknowledgement).map(({ _key }) => [_key, false])));
	const acknowledged = useMemo(() => Object.values(acknowledgements).every((ack) => ack), [acknowledgements]);

	const [customValues, setCustomValues] = useState<Record<string, string>>({});

	return (
		<Container>
			<Header>
				{onBack && (
					<BackButton tabIndex={-1} type="button" onClick={() => onBack()}>
						<img alt="Back" src={`/arrow-left-${contrast(surfaceBackground)}.svg`} />
					</BackButton>
				)}
				<HeaderText>
					Your Bag
					{!lineItems.length ? '' : ` (${lineItems.length})`}
				</HeaderText>
			</Header>

			<ShipsTo>
				{
					countries?.length === 1
						? (
							<>
								<ShipsToIcon alt={`Only ships to ${firstCountryLabel ?? countries[0]}`} src={`https://cdn.jsdelivr.net/gh/madebybowtie/FlagKit@2.2/Assets/SVG/${countries[0]}.svg`} />
								Only ships to
								{' '}
								{firstCountryLabel ?? countries[0]}
							</>
						)
						: (
							<>
								<ShipsToIcon alt="Ships Internationally" src={`/globe-${contrast(bannerBackground)}.svg`} />
								Ships Internationally
							</>
						)
				}
			</ShipsTo>

			<Content>
				{
					!lineItems.length
						? (
							<>
								<EmptyCart>
									<EmptyCartImage alt="Upside down umbrella" src={`/umbrella-${contrast(surfaceBackground)}.svg`} />
									<EmptyCartHeader>Your Bag is Empty</EmptyCartHeader>
									<EmptyCardDescription>
										Fill it up by adding products.
										<br />
										They’ll end up here.
									</EmptyCardDescription>
								</EmptyCart>
								<EmptyCartButton onClick={(): void => onStartOver()} type="button">
									Start Over
								</EmptyCartButton>
							</>
						)
						: (
							<>
								<LineItems>
									{lineItems.map((lineItem) => (
										<LineItem
											key={getSkuName(lineItem.sku)}
											lineItem={lineItem}
											product={productsById[lineItem.sku.product]}
											onAddLineItem={onChangeLineItems && ((lineItem) => onChangeLineItems(
												consolidateLineItems([...lineItems, lineItem]),
												lineItem
											))}
										/>
									))}
								</LineItems>

								<SubtotalContainer>
									<Title>
										Subtotal
										<Details>Shipping calculated in checkout</Details>
									</Title>
									<span>
										{formatCurrency(sumBy(({ quantity, sku: { product } }) => quantity * productsById[product].amount, lineItems) / 100)}
									</span>
								</SubtotalContainer>

								{notices.map(({
									_key,
									requiresAcknowledgement,
									description = [],
								}) => (
									<Notice key={_key}>
										{
											!requiresAcknowledgement
												? <NoticeIcon />
												: (
													<Acknowledgement
														type="checkbox"
														onChange={({ target: { checked } }): void => setAcknowledgements((acknowledgements) => ({
															...acknowledgements,
															[_key]: checked,
														}))}
													/>
												)
										}
										<div>
											<BlockContent blocks={description} />
										</div>
									</Notice>
								))}

								{custom.map(({
									_key,
									placeholder,
									title,
									description = [],
									slug: { current: slug },
								}) => (
									<Fragment key={_key}>
										<Title>
											{title}
											<Details>
												<BlockContent blocks={description} />
											</Details>
										</Title>
										<FieldContainer>
											<Input
												placeholder=" "
												onChange={({ target: { value } }): void => setCustomValues((customValues) => ({
													...customValues,
													[slug]: value,
												}))}
											/>
											<Label>
												{placeholder}
											</Label>
										</FieldContainer>
									</Fragment>
								))}

								<Policies>
									By checking out, I agree to the
									{' '}
									<PolicyLink href="/terms-and-conditions.html" target="_blank">Terms and Conditions</PolicyLink>
									{' '}
									and acknowledge that I have read the
									{' '}
									<PolicyLink href="/privacy.html" target="_blank">Privacy Policy</PolicyLink>
									.
								</Policies>

								<PayButtons>
									{onCheckout && (
										<PayButton disabled={!acknowledged} onClick={(): void => onCheckout({ custom: customValues })} type="button">
											{cta}
											{' '}
											<CardIcon />
										</PayButton>
									)}
									{onApplePay && (
										<ExternalPayButton disabled={!acknowledged} onClick={(): void => onApplePay({ custom: customValues })} type="button">
											<img alt="Apple Pay" src="/apple-pay.svg" />
										</ExternalPayButton>
									)}
									{onGooglePay && (
										<ExternalPayButton disabled={!acknowledged} onClick={(): void => onGooglePay({ custom: customValues })} type="button">
											<img alt="Google Pay" src="/google-pay.svg" />
										</ExternalPayButton>
									)}
								</PayButtons>
							</>
						)
				}
			</Content>
		</Container>
	);
};

const Cart = memo(CartPure);

interface ConnectedProps extends CommonProps {
	analytics: AnalyticsInstance;
	onChangeLineItems?: (lineItems: LineItemType[]) => void;
	products: (ProductFiltered & ProductAnalytics)[];
}

const ConnectedCartPure: FunctionComponent<ConnectedProps> = ({
	analytics,
	lineItems,
	products,
	onApplePay: onApplePayRaw,
	onChangeLineItems: onChangeLineItemsRaw,
	onCheckout: onCheckoutRaw,
	onGooglePay: onGooglePayRaw,
	...props
}) => {
	const onChangeLineItems = useCallback((lineItems: LineItemType[], newLineItem: LineItemType) => {
		if (!onChangeLineItemsRaw) {
			return;
		}

		void removeFromCartEvent(analytics, newLineItem, products);

		onChangeLineItemsRaw(lineItems);
	}, [analytics, onChangeLineItemsRaw, products]);

	const onCheckout = useCallback((args?: { custom?: Record<string, string> }) => {
		if (!onCheckoutRaw) {
			return;
		}

		onCheckoutRaw(args);

		void beginCheckoutEvent(analytics, lineItems, products);
	}, [
		analytics,
		lineItems,
		onCheckoutRaw,
		products,
	]);

	const onApplePay = useCallback((args?: { custom?: Record<string, string> }) => {
		if (!onApplePayRaw) {
			return;
		}

		onApplePayRaw(args);

		void beginCheckoutEvent(analytics, lineItems, products);
	}, [
		analytics,
		lineItems,
		onApplePayRaw,
		products,
	]);

	const onGooglePay = useCallback((args?: { custom?: Record<string, string> }) => {
		if (!onGooglePayRaw) {
			return;
		}

		onGooglePayRaw(args);

		void beginCheckoutEvent(analytics, lineItems, products);
	}, [
		analytics,
		lineItems,
		onGooglePayRaw,
		products,
	]);

	return (
		<Cart
			{...props}
			lineItems={lineItems}
			onApplePay={onApplePayRaw && onApplePay}
			onChangeLineItems={onChangeLineItemsRaw && onChangeLineItems}
			onCheckout={onCheckoutRaw && onCheckout}
			onGooglePay={onGooglePayRaw && onGooglePay}
			products={products}
		/>
	);
};

export const ConnectedCart = memo(ConnectedCartPure);

export default Cart;
