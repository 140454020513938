import classnames from 'classnames';
import React, { memo, useEffect, useMemo, useState } from 'react';
import type { FunctionComponent } from 'react';
import styled from 'styled-components';

const numberHeight = 1.5;

const DigitTrack = styled.div`
	display: inline-block;
	font-size: ${numberHeight}rem;
	font-weight: ${({ theme: { fontWeight } }) => fontWeight - 100};
	line-height: 1;
	height: ${numberHeight}rem;
	overflow-y: hidden;
`;

const DigitInnerTrack = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	transition-property: transform;
	transition-timing-function: cubic-bezier(1, 0, 1, 0);
`;

interface DigitProps {
	className?: string;
	duration?: number;
	lookahead: number;
	num: number;
	pad?: number;
}

const UnitPure: FunctionComponent<DigitProps> = ({
	className,
	lookahead,
	num,
	duration = 0,
	pad = 2,
}) => {
	const [animate, setAnimate] = useState(() => num === lookahead);

	useEffect(() => {
		if (num !== lookahead) {
			return;
		}

		setTimeout(() => setAnimate(true), 50);
	}, [lookahead, num]);

	// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/log10#polyfill
	const digits = Math.max(pad, Math.floor((Math.log(Math.max(num, 1)) * Math.LOG10E) + 1));

	return (
		<>
			{
				new Array(digits)
					.fill(0)
					.map((foo, i) => (
						<DigitTrack key={i} className={classnames(className, 'chromatic-ignore')}>
							<DigitInnerTrack
								style={{
									transform:          `translateY(-${(9 - (Math.floor((animate ? lookahead : num) / (10 ** (digits - i - 1))) % 10)) * numberHeight}rem)`,
									transitionDuration: `${!animate ? 0 : duration}ms`,
								}}
							>
								{new Array(10).fill(0)
									.map((foo, digit) => (
										<span key={digit}>
											{9 - digit}
										</span>
									))}
							</DigitInnerTrack>
						</DigitTrack>
					))
			}
		</>
	);
};

const Unit = memo(UnitPure);

const Container = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
`;

const LabeledUnit = styled.div`
	flex-basis: 0;
	flex-shrink: 0;
	flex-grow: 1;
	border-right: 1px solid ${({ theme: { dividerColor } }) => dividerColor};
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	max-width: 25%;
	transition-timing-function: ease-out;
	transition-property: max-width, border-right-width;
	transition-duration: 1s;
	transition-delay: 1s;

	&:last-child {
		border-right-width: 0;
	}

	&.collapse {
		max-width: 0;
		border-right-width: 0;
	}
`;

const Label = styled.div`
	font-size: 0.5rem;
	font-weight: 600;
	text-transform: uppercase;
`;

const getCountdown = (diffMilli: number): [number, number, number, number] => {
	const diff = Math.floor(diffMilli / 1000);

	return [
		Math.floor(diff / 86400),
		Math.floor(diff / 3600) % 24,
		Math.floor(diff / 60) % 60,
		Math.floor(diff) % 60,
	];
};

export const getCountdownString = (diffMilli: number): string => {
	const [
		days,
		hours,
		minutes,
		seconds,
	] = getCountdown(diffMilli);

	return days
		? days === 1
			? '1 Day'
			: `${days} Days`
		: hours
			? hours === 1
				? '1 Hour'
				: `${hours} Hours`
			: minutes
				? minutes === 1
					? '1 Minute'
					: `${minutes} Minutes`
				: seconds === 1
					? '1 Second'
					: `${seconds} Seconds`;
};

const unitToMillis = {
	day:    24 * 60 * 60 * 1000,
	hour:   60 * 60 * 1000,
	minute: 60 * 1000,
	second: 1000,
};

export const useTimeUntil = (until?: number | string, updateEvery: number | 'biggest' = 1000): number | undefined => {
	const [now, setNow] = useState<number>();
	useEffect(() => setNow(Date.now()), []);

	const val = (now && until && Math.max(0, new Date(until).valueOf() - now)) || undefined;

	useEffect(() => {
		if (!val) {
			return;
		}

		const updateAfter = Math.min(
			2147483647,
			updateEvery !== 'biggest'
				? val % updateEvery
				: (Math.max(0, val - unitToMillis.day) % unitToMillis.day)
					|| (Math.max(0, val - unitToMillis.hour) % unitToMillis.hour)
					|| (Math.max(0, val - unitToMillis.minute) % unitToMillis.minute)
					|| (val % unitToMillis.second)
		);

		const timeout = setTimeout(() => setNow(Date.now()), updateAfter);

		return () => clearTimeout(timeout);
	}, [updateEvery, val]);

	return val;
};

const lookaheadDuration = 4800;
const secondsOnesLookaheadDuration = 800;

export interface Props {
	lookahead?: boolean;
	className?: string;
	time?: number;
}

export const CountdownPure: FunctionComponent<Props> = ({ className, lookahead, time }) => {
	const values = useMemo((): [[number, number, number, number, number], [number, number, number, number, number]] | undefined => {
		if (time === undefined) {
			return undefined;
		}

		const countdown = getCountdown(time);
		const actual: [number, number, number, number, number] = [...countdown.slice(0, 3) as [number, number, number], Math.floor(countdown[3] / 10), countdown[3] % 10];

		if (!lookahead) {
			return [actual, actual];
		}

		const [
			daysLookahead,
			hoursLookahead,
			minutesLookahead,
			secondsLookahead,
		] = getCountdown(time - lookaheadDuration);

		return [
			actual,
			[
				daysLookahead,
				hoursLookahead,
				minutesLookahead,
				Math.floor(secondsLookahead / 10),
				getCountdown(time - secondsOnesLookaheadDuration)[3] % 10,
			],
		];
	}, [lookahead, time]);

	return (
		<Container className={className}>
			{values && (
				<>
					<LabeledUnit className={classnames({ collapse: !values[0][0] })}>
						<div>
							<Unit num={values[0][0]} lookahead={values[1][0]} duration={lookaheadDuration} />
						</div>
						<Label>Days</Label>
					</LabeledUnit>
					<LabeledUnit className={classnames({ collapse: !values[0][0] && !values[0][1] })}>
						<div>
							<Unit num={values[0][1]} lookahead={values[1][1]} duration={lookaheadDuration} />
						</div>
						<Label>Hours</Label>
					</LabeledUnit>
					<LabeledUnit className={classnames({ collapse: !values[0][0] && !values[0][1] && !values[0][2] })}>
						<div>
							<Unit num={values[0][2]} lookahead={values[1][2]} duration={lookaheadDuration} />
						</div>
						<Label>Minutes</Label>
					</LabeledUnit>
					<LabeledUnit>
						<div>
							<Unit num={values[0][3]} lookahead={values[1][3]} pad={1} duration={lookaheadDuration} />
							<Unit num={values[0][4]} lookahead={values[1][4]} pad={1} duration={secondsOnesLookaheadDuration} />
						</div>
						<Label>Seconds</Label>
					</LabeledUnit>
				</>
			)}
		</Container>
	);
};

const Countdown = memo(CountdownPure);

export interface ConnectedProps extends Omit<Props, 'time'> {
	until: number | string;
}

export const ConnectedCountdownPure: FunctionComponent<ConnectedProps> = ({
	until,
	...props
}) => {
	const time = useTimeUntil(until);

	return (
		<Countdown
			{...props}
			lookahead
			time={time}
		/>
	);
};

export const ConnectedCountdown = memo(ConnectedCountdownPure);
