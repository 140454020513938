import type { ImageMeta, Drop } from '@drop-party/sanity';
import classnames from 'classnames';
import type { FunctionComponent, ReactNode } from 'react';
import React, { memo, useContext, useState } from 'react';
import { IoIosMenu } from 'react-icons/io';
import styled, { css, ThemeContext } from 'styled-components';

import Navigation from 'components/Navigation';
import { reset } from 'components/styles';
import { contrast } from 'utils';
import { imageBuilder } from 'vendors/sanity/utils';

export const headerContentHeight = 2;
const headerPaddingBottom = 0;
const headerPaddingTop = 1.5;

const fullscreen = css`
	transition: transform 0.5s cubic-bezier(0.65, 0, 0.35, 1);

	&.fullscreen {
		transform: translateY(1rem);
	}
`;

const useHeaderImageHeightPx = (): number => {
	const { fontSize } = useContext(ThemeContext);

	return fontSize * headerContentHeight;
};

export const useHeaderHeightPx = (): number => {
	const { fontSize } = useContext(ThemeContext);
	const headerImageHeightPx = useHeaderImageHeightPx();

	return headerImageHeightPx + (fontSize * (headerPaddingTop + headerPaddingBottom));
};

const Container = styled.header<{multiCard: boolean; spaceAroundCardDesktopVerticalPx: number}>`
	display: flex;
	z-index: 2;
	flex-direction: row;
	align-items: center;
	padding: ${({ multiCard, spaceAroundCardDesktopVerticalPx }) => (multiCard ? `${headerPaddingTop}rem 0 ${headerPaddingBottom}rem` : `${spaceAroundCardDesktopVerticalPx / 3}px`)};
`;

const ImageContainer = styled.div`
	flex-shrink: 1;
	height: ${headerContentHeight}rem;
	z-index: 2;
`;

const Image = styled.img<{multiCard: boolean}>`
	${reset}
	${fullscreen}
	max-width: ${({ multiCard }) => (multiCard ? '17rem' : '6rem')};
	height: ${headerContentHeight}rem;
	object-fit: contain;
`;

const Left = styled.div`
	flex-basis: 0;
	flex-grow: 1;
	padding-right: 1rem;
	display: relative;
`;

const HamburgerMenuWrapper = styled.div<{multiCard: boolean}>`
	${fullscreen}
	padding: 0.95rem 1.05rem 0.65rem 1.05rem;
	position: absolute;
	left: 1rem;
	top: ${({ multiCard }) => (multiCard ? '0.8rem' : '0.45rem')};
	z-index: 2;
	transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

	&.fullscreen {
		background-color: ${({ theme: { bodyBackground } }) => bodyBackground};
		transform: translateY(1rem);
		border-radius: 0.5rem;
	}

	&:hover {
		cursor: pointer;
	}
`;

const HamburgerMenu = styled(IoIosMenu)`
	transform-origin: center;
	transform: scale(1.5);
	${({ theme: { bodyBackground } }) => (contrast(bodyBackground) === 'light' ? 'filter: brightness(0) saturate(100%) invert(100%)' : '')};
`;

const Right = styled.div`
	flex-basis: 0;
	flex-grow: 1;
	padding-left: 1rem;
`;

export interface Props {
	about: Drop['about'];
	children?: ReactNode;
	className?: string;
	exposeMenu: boolean;
	fullscreen: boolean;
	href?: string;
	image: ImageMeta;
	multiCard?: boolean;
	socialMedia: Drop['socialMedia'];
	spaceAroundCardDesktopVerticalPx: number;
	screenHeightPx: number;
	termsConditions: Drop['termsConditions'];
}

export const HeaderPure: FunctionComponent<Props> = ({
	about,
	multiCard,
	children,
	className,
	exposeMenu,
	fullscreen,
	socialMedia,
	spaceAroundCardDesktopVerticalPx,
	termsConditions,
	screenHeightPx,
	href,
	image,
	image: { alt },
}) => {
	const headerImageHeightPx = useHeaderImageHeightPx();
	const [open, setOpen] = useState<boolean>(false);
	const isFullScreenHeader = fullscreen && multiCard && screenHeightPx + 5 >= document.documentElement.scrollHeight;

	return (
		<>
			{exposeMenu && (
				<Navigation
					open={open}
					setOpen={setOpen}
					about={about}
					socialMedia={socialMedia}
					termsConditions={termsConditions}
				/>
			)}
			<Container multiCard={multiCard!} className={className} spaceAroundCardDesktopVerticalPx={spaceAroundCardDesktopVerticalPx}>
				<Left className={classnames({ fullscreen: isFullScreenHeader })}>
					{exposeMenu
						? (
							<HamburgerMenuWrapper
								onClick={() => setOpen(!open)}
								multiCard={multiCard!}
								className={classnames({ fullscreen: isFullScreenHeader })}
							>
								<HamburgerMenu />
							</HamburgerMenuWrapper>
						)
						: children}
				</Left>
				<ImageContainer
					{...href && {
						href,
						as:     'a',
						target: '_blank',
						rel:    'noreferrer',
					}}
				>
					<Image
						alt={alt}
						src={
							imageBuilder.image(image)
								.height(2 * headerImageHeightPx)
								.url()!
						}
						multiCard={multiCard!}
						className={classnames({ fullscreen: isFullScreenHeader })}
					/>
				</ImageContainer>
				<Right />
			</Container>
		</>
	);
};

const Header = memo(HeaderPure);

export default Header;
