import type { SanityFileSource } from '@sanity/asset-utils';
import Head from 'next/head';
import React from 'react';
import type { FunctionComponent } from 'react';

import { getFileUrl } from 'vendors/sanity/utils';

interface Props {
	favicon?: SanityFileSource;
}

const Favicon: FunctionComponent<Props> = ({ favicon }) => (
	<Head>
		<link rel="icon" href={!favicon ? '/favicon.ico' : getFileUrl(favicon)} />
		<link rel="shortcut icon" href={!favicon ? '/favicon.ico' : getFileUrl(favicon)} />
	</Head>
);

export default Favicon;
